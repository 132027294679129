import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { cardContainer, extraPadding, noShadow, pageContainer } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  stepperContainer: {
    ...pageContainer,
    marginBottom: theme.units.margin * 6,
    marginTop: theme.units.margin * 10,
  },
  stepperWrapper: {
    ...cardContainer,
    ...noShadow,
    ...extraPadding,
    display: 'grid',
    gridGap: theme.units.margin * 4,
    gridAutoFlow: 'column',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      paddingLeft: theme.units.padding * 4,
      paddingRight: theme.units.padding * 4,
      // width: '100%',
      gridGap: theme.units.margin * 2,
    },
  },
  step: {},
  stepTitle: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.grey,
    marginBottom: theme.units.margin * 2,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.xs,
    },
  },
  stepTitleActive: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.buttonPrimary,
    marginBottom: theme.units.margin * 2,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.xs,
    },
  },
  stepBar: {
    height: 8,
    width: '100%',
    backgroundColor: theme.colors.disabled,
  },
  stepBarActive: {
    height: 8,
    width: '100%',
    backgroundColor: theme.colors.buttonPrimary,
  },
});
