/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import { GuestTokenResponse, RegisterClientResponse } from '../../models/auth/RegisterToken';
import tokenService from '../../services/token/TokenService';
import { TokenAction, TokenActions, TokenActionTypes } from '../actions/TokenActions';

function* registerClient(action: TokenAction) {
  try {
    const response: RegisterClientResponse = yield tokenService.registerClient();
    yield put(TokenActions.registerClientSuccess(response.token));
  } catch (error) {
    yield put(TokenActions.registerClientError(error as Error));
  }
}

function* getGuestToken(action: TokenAction) {
  try {
    const clientId = action.data as string;
    const response: GuestTokenResponse = yield tokenService.getGuestToken(clientId);

    yield put(TokenActions.getGuestTokenSuccess({ ...response }));
  } catch (error) {
    yield put(TokenActions.getGuestTokenError(error as Error));
  }
}

function* tokenWatcher(): IterableIterator<any> {
  yield takeEvery(TokenActionTypes.REGISTER_CLIENT_REQUEST, (action: TokenAction) =>
    registerClient(action),
  );
  yield takeEvery(TokenActionTypes.GET_GUEST_TOKEN_REQUEST, (action: TokenAction) =>
    getGuestToken(action),
  );
}

export default tokenWatcher;
