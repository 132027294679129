import { MiddysSelectOption } from './components/middysFields/middysSelect/MiddysSelect';

export {};
export const SIZES = {
  sm: 560,
  md: 780,
  lg: 1024,
  lgMax: 1366,
};

export const AUSTRALIAN_STATES = [
  'New South Wales',
  'Queensland',
  'South Australia',
  'Tasmania',
  'Victoria',
  'Western Australia',
  'Australian Capital Territory',
  'Northern Territory',
];

export const STATE_OPTIONS: MiddysSelectOption[] = AUSTRALIAN_STATES.map((s) => ({
  label: s,
  value: s,
}));
