import React, { FC } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { useStyles } from './SelectComponentStyles';
import { SelectOptions } from './SelectComponentConstants';

interface SelectComponentProps {
  value: string;
  setValue: (value: string) => void;
  options: SelectOptions[];
  error?: string;
}

const SelectComponent: FC<SelectComponentProps> = ({ value, setValue, options, error }) => {
  const styles = useStyles();
  return (
    <div>
      <Select
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        className={styles.selectInputStyle}
        classes={{
          root: error ? styles.errorSelectInput : styles.selectInput,
          select: styles.selectComStyle,
          icon: styles.iconStyle,
        }}
        disableUnderline
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem disabled={option.disabled} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <div className={styles.errorTextStyle}>{error}</div>}
    </div>
  );
};
export default SelectComponent;
