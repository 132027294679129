import React, { FC } from 'react';
import { useStyles } from '../BundleDetailsStyles';

interface LoadingHeroProps {}

const LoadingHero: FC<LoadingHeroProps> = ({}) => {
  const styles = useStyles();
  return (
    <div className={styles.loadingHeroContainer}>
      <div className={styles.arrowsContainer}>
        <div className={styles.loadingArrow} />
        <div className={styles.loadingArrow} />
      </div>
    </div>
  );
};

export default LoadingHero;
