import { theme } from '../../theme/Theme';
import { makeStyles, withStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer, loadingAnimation } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';
import { ToggleButtonGroup } from '@material-ui/lab';

export const useStyles = makeStyles({
  modal: {},
  modalImageContainer: {
    width: '100%',
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImage: {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  },
  modalImageContain: {
    objectFit: 'contain',
    objectPosition: 'center',
    width: '80%',
    height: '80%',
  },
  modalContent: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginBottom: theme.units.margin * 6,
    textAlign: 'center',
  },
  formStyle: {
    flex: 1,
    width: 'calc(100% - 48px)',
    marginBottom: theme.units.margin * 4,
  },
  iconStyle: {
    width: theme.units.iconWidth * 2.5,
    height: theme.units.iconHeight * 2.5,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.margin,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    marginLeft: theme.units.margin,
  },
  addressButton: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 140,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
