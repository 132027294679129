import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';
import { pageContainer } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  addressFooter: {
    width: '100%',
    backgroundColor: theme.colors.secondary,
    paddingTop: theme.units.padding * 4,
    paddingBottom: theme.units.padding * 4,
    marginTop: theme.units.margin * 8,
  },
  addressFooterContent: {
    ...pageContainer,
    // width: '100%',
  },
  addressRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addressRowIcon: {
    height: 30,
    marginRight: theme.units.margin * 4,
  },
  addressRowTitle: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.l,
    color: theme.colors.light,
    marginRight: 'auto',
    paddingRight: theme.units.padding * 4,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: theme.font.m,
    },
  },
  backButton: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.darkGrey,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 140,
    width: 'max-content',
    border: `${theme.colors.borderColor} 4px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addressButton: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 140,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  disabledButton: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.disabledText,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 140,
    width: 'max-content',
    backgroundColor: theme.colors.disabled,
    border: `${theme.colors.disabled} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  boxShadow: {
    boxShadow: '0px 15px 20px #888',
  },
  footerContainer: {
    backgroundColor: theme.colors.light,
    paddingTop: theme.units.padding * 8,
    paddingBottom: theme.units.padding * 8,
  },
  footerContentWrapper: {
    ...pageContainer,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  footerPriceContainer: {},
  priceLabel: {
    fontFamily: theme.font.family,
    color: theme.colors.label,
    fontSize: theme.font.s,
  },
  priceGST: {
    fontSize: theme.font.s,
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 2,
  },
  footerPrice: {
    fontFamily: theme.font.family2,
    color: theme.colors.secondary,
    fontSize: theme.font.xxl,
  },
});
