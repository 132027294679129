import { theme } from '../../../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  smartUpgradeContainer: {
    marginTop: theme.units.margin * 6,
    border: `${theme.colors.borderColor} 2px solid`,
    cursor: 'pointer',
  },
  imageContainer: {
    height: 150,
    width: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  upgradeRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.units.padding * 4,
    backgroundColor: theme.colors.light,
  },
  upgradeTitle: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.darkGrey,
  },
  upgradePrice: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginLeft: 'auto',
  },
  upgradeAction: {
    height: 22,
    marginLeft: theme.units.margin * 4,
    cursor: 'pointer',
  },
});
