import { getSteps } from '../../helpers/StepHelper';
import { UserDetails } from '../../models/auth/User';
import { BundlePackage } from '../../models/bundles/Bundles';
import { JobDetails } from '../../models/common/JobDetails';
import { OfferType } from '../../models/common/OfferType';
import { Property } from '../../models/common/Property';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { BundlesAction, BundlesActionTypes } from '../actions/BundlesActions';
import { BundlesState } from '../state/BundlesState';

const initialState: BundlesState = {
  bundles: undefined,
  selectedBundle: undefined,
  registeredMailingListSuccess: false,
};

export const bundlesReducer = (state = initialState, action: BundlesAction) => {
  switch (action.type) {
    case BundlesActionTypes.GET_BUNDLES_SUCCESS:
      return {
        ...state,
        bundles: action.data as BundlePackage[],
      };
    case BundlesActionTypes.UPDATE_SELECTED_BUNDLE:
      return {
        ...state,
        selectedBundle: action.data as BundlePackage | undefined,
      };
    case BundlesActionTypes.POST_MAIL_SIGNUP_SUCCESS:
      return {
        ...state,
        registeredMailingListSuccess: true,
      };
    default:
      return state;
  }
};
