import { store } from '../store/Store';
import { TokenActions } from '../store/actions/TokenActions';
import { History } from 'history';

export interface AuthDetails {
  email: string;
  accessToken: string;
  refreshToken: string;
}

export interface AuthDetail {
  data: string;
}

export const saveAuthDetails = async (messageData: string) => {
  try {
    if (messageData && typeof messageData === 'string') {
      const authData = JSON.parse(messageData);
      if (authData.authDetails) {
        await store.dispatch(TokenActions.setUserId(authData.userId));
        store.dispatch(
          TokenActions.setAccessToken((authData.authDetails as AuthDetails).accessToken),
        );
        store.dispatch(
          TokenActions.setRefreshToken((authData.authDetails as AuthDetails).refreshToken),
        );
        store.dispatch(TokenActions.setAppName(authData.appName));
        localStorage.setItem('email', (authData.authDetails as AuthDetails).email);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

//checks if the use has updated the app to ver2
export const hasUpdatedApp = (history: History) => {
  return history.location.search.includes('ver-2') || true;
};
