import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import circleLeftArrow from '../../assets/circle-left-arrow.png';
import circleRightArrow from '../../assets/circle-right-arrow.png';
import homeIcon from '../../assets/home.png';
import bookOffer from '../../assets/middysAssets/howEst.jpg';
import Header from '../../components/header/Header';
import ContactUs from '../../assets/contact-us-cropped.png';
import { ApplicationState } from '../../store/RootReducer';
import { StyledToggleButtonGroup, useStyles } from './BundleDetailsStyles';
import { ToggleButton } from '@material-ui/lab';
import { Bundle, BundlePackage, Inclusion } from '../../models/bundles/Bundles';
import { LABELS, STEPS, WHY_CHOOSE } from './BundleDetailsConstants';
import { Dialog } from '@material-ui/core';
import { routes } from '../../Routes';
import { OfferType } from '../../models/common/OfferType';
import { BookingActions } from '../../store/actions/BookingActions';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import SectionContent from '../../components/sectionContent/SectionContent';
import AddressCheckFooter from '../../components/addressCheckFooter/AddressCheckFooter';
import { getQueryParams } from '../../helpers/QueryHelper';
import { BundlesActions, BundlesActionTypes } from '../../store/actions/BundlesActions';
import LoadingHero from './components/LoadingHero';
import LoadingIntro from './components/LoadingIntro';
import LoadingInclusions from './components/LoadingInclusions';
import LoadingExtraContent from './components/LoadingExtraContent';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { getSteps } from '../../helpers/StepHelper';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import { replaceYoutubeLink } from './BundleDetailsUtil';
import rightArrow from '../../assets/right-carousel.png';
import leftArrow from '../../assets/left-carousel.png';
import Carousel from 'react-elastic-carousel';
import { SIZES } from '../../Constants';
import { ListBox } from '../../components/listBox/ListBox';
import ActionButton from '../../components/actionButton/ActionButton';
import ChatWithProfessionalBanner from '../../components/chatWithProfessional/ChatWithProfessionalBanner';
import MiddysLink from '../../components/link/MiddysLink';

interface BundleDetailsProps extends RouteComponentProps {
  loadingBundles: boolean;
  bundles: BundlePackage[] | undefined;
  selectedBundle: BundlePackage | undefined;
  accessToken: string | boolean | null;
  getBundlesRequest: () => void;
  updateOfferType: (data: UpdateOfferType) => void;
  updateSelectedBundle: (data: BundlePackage) => void;
  updateSkills: (data: string[]) => void;
}

const BundleDetails: FC<BundleDetailsProps> = ({
  selectedBundle,
  history,
  location,
  loadingBundles,
  bundles,
  accessToken,
  getBundlesRequest,
  updateOfferType,
  updateSelectedBundle,
  updateSkills,
}) => {
  const upgradedURL = getQueryParams(location, 'upgraded');
  const bundleIdURL = getQueryParams(location, 'bundleId');
  const [upgraded, setUpgraded] = useState<boolean>(false);
  const [activeImage, setActiveImage] = useState<number>(0);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showInclusion, setShowInclusion] = useState<boolean | Inclusion>(false);
  const [inclusionIndex, setInclusionIndex] = useState<number>(0);
  const [inclusionsToShow, setInclusionsToShow] = useState<number>(4);

  const images = useMemo<string[]>(() => {
    setActiveImage(0);
    if (selectedBundle === undefined) return [];
    return upgraded
      ? [selectedBundle.upgraded!.heroImage, ...selectedBundle.upgraded!.additionalImages]
      : [selectedBundle.standard.heroImage, ...selectedBundle.standard.additionalImages];
  }, [upgraded, selectedBundle]);

  const content = useMemo<Bundle | undefined>(() => {
    if (selectedBundle === undefined) return undefined;

    return upgraded ? selectedBundle.upgraded : selectedBundle.standard;
  }, [upgraded, selectedBundle]);

  useEffect(() => {
    if (upgradedURL) {
      setUpgraded(upgradedURL === 'true');
    }
  }, [upgradedURL]);

  useEffect(() => {
    if (selectedBundle && selectedBundle.productId === Number(bundleIdURL)) return;
    if (bundleIdURL && accessToken) {
      if (bundles === undefined) {
        getBundlesRequest();
      } else {
        updateSelectedBundle({
          ...bundles.find((b) => b.productId === Number(bundleIdURL))!,
          selectedUpgraded: upgraded,
        });
      }
    }
  }, [bundleIdURL, bundles, accessToken]);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth <= SIZES.sm) {
        setInclusionsToShow(1);
      } else if (window.innerWidth <= SIZES.md) {
        setInclusionsToShow(2);
      } else if (window.innerWidth <= SIZES.lg) {
        setInclusionsToShow(3);
      } else {
        setInclusionsToShow(4);
      }
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const onNextImage = () => {
    setActiveImage(activeImage === images.length - 1 ? 0 : activeImage + 1);
  };

  const onPreviousImage = () => {
    setActiveImage(activeImage === 0 ? images.length - 1 : activeImage - 1);
  };

  const styles = useStyles();

  return (
    <div>
      <Header />
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.navigationContainer}>
            <img
              src={homeIcon}
              onClick={() => history.push(routes.packages)}
              className={styles.homeIcon}
              alt="home icon"
            />
            <div className={styles.navArrow}>{`>`}</div>
            <div className={styles.navTitle}>{content ? content.title : 'Loading package...'}</div>
          </div>
          {!selectedBundle || loadingBundles ? (
            <>
              <LoadingHero />
              <LoadingIntro />
              <LoadingInclusions />
              <LoadingExtraContent />
              <HowItWorksSection steps={STEPS} title={LABELS.HOW_IT_WORKS} loading />
              <SectionContent title={LABELS.WHY_CHOOSE} image={ContactUs} reversed loading>
                {WHY_CHOOSE.map((why) => (
                  <>
                    <div className={styles.stepTitle}>{why.title}</div>
                    <div className={styles.stepText}>{why.text}</div>
                  </>
                ))}
              </SectionContent>
            </>
          ) : (
            <>
              <div className={styles.heroImageContainer}>
                <div className={styles.heroImageWrapper}>
                  <img src={images[activeImage]} className={styles.heroImage} alt="hero" />
                </div>
                <div className={styles.arrowsContainer}>
                  <img
                    src={circleLeftArrow}
                    className={styles.arrow}
                    onClick={() => onPreviousImage()}
                    alt="arrow"
                  />
                  <img
                    src={circleRightArrow}
                    className={styles.arrow}
                    onClick={() => onNextImage()}
                    alt="arrow"
                  />
                </div>
              </div>
              <div className={styles.bundleRow}>
                <div className={styles.bundleContent}>
                  <div className={styles.title}>{content!.title}</div>
                  <div className={styles.description}>{content!.description}</div>
                  <ListBox mt={2} spacing={3} direction="row">
                    <ActionButton
                      onClick={() =>
                        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                      }
                    >
                      {LABELS.GET_FREE_QUOTES}
                    </ActionButton>

                    <ActionButton
                      onClick={() => {
                        history.push(routes.registerInterest);
                      }}
                    >
                      {LABELS.GET_INSPIRED}
                    </ActionButton>
                    {/* <div className={styles.bundleAction}>
                      <img className={styles.actionIcon} src={file} alt="file" />
                      <div className={styles.actionContent}>
                        <div className={styles.actionSubtitle}>{LABELS.FULL_PACKAGE}</div>
                        <div className={styles.actionTitleContainer}>
                          <div className={styles.actionTitle}>{LABELS.DOWNLOAD_PDF}</div>
                          <img className={styles.actionTitleIcon} src={cloud} alt="download" />
                        </div>
                      </div>
                    </div> */}
                  </ListBox>
                </div>
                <div className={styles.priceContent}>
                  <StyledToggleButtonGroup
                    value={upgraded}
                    exclusive
                    onChange={(_, value) =>
                      setUpgraded(
                        selectedBundle.upgraded === undefined ? false : (value as boolean),
                      )
                    }
                    aria-label="text alignment"
                  >
                    <ToggleButton value={false}>Standard</ToggleButton>
                    {!!selectedBundle.upgraded && (
                      <ToggleButton value={true}>Upgraded</ToggleButton>
                    )}
                  </StyledToggleButtonGroup>
                  <div className={styles.priceContainer}>
                    <div className={styles.from}>{LABELS.PRICE_FROM}</div>
                    <div className={styles.price}>
                      ${content!.price.toFixed(2)}
                      <span className={styles.priceGST}> (exc GST)</span>
                    </div>
                  </div>
                  <div className={styles.howDoEstimates} onClick={() => setShowPopup(true)}>
                    {LABELS.HOW_DO_EST}
                  </div>
                </div>
              </div>
              {!!content!.inclusions.length && (
                <div className={styles.inclusionsContainer}>
                  <div className={styles.subheading}>
                    {upgraded ? LABELS.UPGRADED_PACKAGE : LABELS.STANDARD_PACKAGE}
                  </div>
                  <div className={styles.inclusionsRow}>
                    <Carousel
                      isRTL={false}
                      pagination={false}
                      itemsToShow={inclusionsToShow}
                      itemsToScroll={1}
                      itemPosition={'START'}
                      itemPadding={[0, 12]}
                      initialActiveIndex={inclusionIndex}
                      onChange={(currentItemObject: any) => {
                        setInclusionIndex(currentItemObject.index);
                      }}
                      showArrows={content!.inclusions.length !== 1}
                      renderArrow={({ type, onClick }) => {
                        const pointer = type === 'PREV' ? leftArrow : rightArrow;
                        return (
                          <div
                            onClick={onClick}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img src={pointer} className={styles.iconStyle} alt="pointer" />
                          </div>
                        );
                      }}
                    >
                      {content!.inclusions.map((inclusion) => (
                        <div className={styles.inclusionContainer}>
                          <div className={styles.inclusionImageContainer}>
                            <img
                              className={styles.inclusionImage}
                              src={inclusion.image}
                              alt="inclusion"
                            />
                          </div>
                          <div className={styles.inclusionContentContainer}>
                            <div className={styles.inclusionTitle}>
                              {inclusion.name} x{inclusion.quantity}
                            </div>
                          </div>
                          <div
                            className={styles.inclusionButton}
                            onClick={() => setShowInclusion(inclusion)}
                          >
                            {LABELS.VIEW}
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              )}

              {<ChatWithProfessionalBanner />}

              {content!.extraContent.map((c) => (
                <div className={styles.bundleRow}>
                  <div className={styles.bundleContent}>
                    <div className={styles.subheading}>{c.title}</div>
                    <div className={styles.title}>{c.subtitle}</div>
                    <div className={styles.description}>{c.description}</div>
                    {!!c.specifications && !!c.specifications.length && (
                      <>
                        {c.specifications.map((s, i) => (
                          <div className={styles.specRow}>
                            <div className={styles.specKey}>{s.key}:</div>
                            <div className={styles.specValue}>{s.value}</div>
                          </div>
                        ))}
                      </>
                    )}
                    {!!c.ctaLink && (
                      <div
                        className={styles.actionButton}
                        onClick={() => window.open(c.ctaLink, '_blank')}
                      >
                        {c.ctaText}
                      </div>
                    )}
                  </div>
                  <div className={styles.contentImageContainer}>
                    <img className={styles.contentImage} src={c.image} alt="image" />
                  </div>
                </div>
              ))}
              {!!content!.video && (
                <iframe
                  src={replaceYoutubeLink(content!.video)}
                  className={styles.iframe}
                  frameBorder="0"
                />
              )}
              <HowItWorksSection steps={STEPS} title={LABELS.HOW_IT_WORKS} />
              <SectionContent title={LABELS.WHY_CHOOSE} image={ContactUs} reversed>
                {WHY_CHOOSE.map((why) => (
                  <>
                    <div className={styles.stepTitle}>{why.title}</div>
                    <div className={styles.stepText}>{why.text}</div>
                  </>
                ))}
              </SectionContent>
            </>
          )}
        </div>
      </div>

      <AddressCheckFooter
        onNextText={LABELS.GET_FREE_QUOTES}
        onNext={() => {
          updateOfferType({ offerType: OfferType.BUNDLE, steps: getSteps(OfferType.BUNDLE) });
          updateSelectedBundle({ ...selectedBundle!, selectedUpgraded: upgraded });
          updateSkills(selectedBundle ? selectedBundle.skills : []);
          history.push(getSteps(OfferType.BUNDLE)[0].route);
        }}
        price={!!content ? content.price : 0}
      />
      <Dialog
        open={showPopup}
        maxWidth="sm"
        fullWidth
        style={{
          zIndex: 999999,
        }}
        onClose={() => setShowPopup(false)}
      >
        <div className={styles.modal}>
          <div className={styles.modalImageContainer}>
            <img className={styles.modalImage} src={bookOffer} alt="image" />
          </div>
          <div className={styles.modalContent}>
            <div className={styles.modalTitle}>{LABELS.MODAL_TITLE}</div>
            <div className={styles.modalText}>
              This price (exclusive of GST) includes the cost of the listed hardware inclusions and
              installation. Submit your request and our accredited trades will contact you for
              additional information and provide the best solution to meet your individual needs.
              Price may vary pending site inspection.
            </div>
            <div className={styles.addressButton} onClick={() => setShowPopup(false)}>
              {LABELS.MODAL_BUTTON}
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={!!showInclusion}
        maxWidth="sm"
        fullWidth
        style={{
          zIndex: 999999,
        }}
        onClose={() => setShowInclusion(false)}
      >
        <div className={styles.modal}>
          <div className={styles.modalImageContainer}>
            <img
              className={styles.modalImageContain}
              src={!!showInclusion ? (showInclusion as Inclusion).image : ''}
              alt="image"
            />
          </div>
          <div className={styles.modalContent}>
            <div className={styles.modalTitle}>
              {!!showInclusion && (showInclusion as Inclusion).name}
            </div>
            <div className={styles.modalText}>
              {!!showInclusion && (showInclusion as Inclusion).description}
            </div>
            <div className={styles.addressButton} onClick={() => setShowInclusion(false)}>
              {LABELS.MODAL_BUTTON}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const loading = loadingSelector([BundlesActionTypes.GET_BUNDLES]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingBundles: loading(state),
  selectedBundle: state.bundlesState.selectedBundle,
  bundles: state.bundlesState.bundles,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBundlesRequest: () => dispatch(BundlesActions.getBundlesRequest()),
  updateOfferType: (data: UpdateOfferType) => dispatch(BookingActions.updateOfferType(data)),
  updateSelectedBundle: (data: BundlePackage) =>
    dispatch(BundlesActions.updateSelectedBundle(data)),
  updateSkills: (data: string[]) => dispatch(BookingActions.updateSkills(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BundleDetails));
