import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    ...root,
  },
  container: {
    ...pageContainer,
    paddingBottom: 120,
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
  },
  leftCard: {
    ...cardContainer,
    flex: 2,
  },
  iconStyle: {
    width: theme.units.iconWidth,
    height: theme.units.iconHeight,
  },
  dateValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  datetext: {
    fontSize: theme.font.l,
  },
  dayCard: {
    width: 64,
    height: 64,
    margin: '16px 20px 17px',
    // padding: '8px 0 0',
    borderRadius: 7,
    border: 'solid 2px #f4f5f6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // backgroundColor: 'black'
  },
  selectedDayCard: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
  },
  carousel: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeSlotStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 64,
    paddingLeft: theme.units.padding * 1.33,
    paddingRight: theme.units.padding * 1.33,
    marginTop: theme.units.margin * 1.33,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.borderColor,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.light,
      backgroundColor: theme.colors.secondary,
    },
  },
  disabledTimeSlotStyle: {
    backgroundColor: theme.colors.grey,
    '&:hover': {
      cursor: 'not-allowed',
      color: theme.colors.black,
      backgroundColor: theme.colors.grey,
    },
  },
  chipWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.units.margin * 1.33,
  },
  chipStyle: {
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.units.padding * 2,
    paddingRight: theme.units.padding * 2,
    borderRadius: theme.units.borderRadius * 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.buttonPrimary,
    backgroundColor: theme.colors.lightFaded,
    marginRight: theme.units.margin * 1.33,
    marginTop: theme.units.margin * 1.33,
  },
  cancelIconStyle: {
    width: theme.units.iconWidth / 2.5,
    height: theme.units.iconHeight / 2.5,
    cursor: 'pointer',
    marginLeft: theme.units.margin * 2,
  },
  calendarStyle: {
    display: 'flex',
    flex: 1,
    borderBottom: '0px',
  },
});
