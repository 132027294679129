import React, { FC } from 'react';
import { useStyles } from './SearchBarStyles';

interface SearchBarLoadingProps {}

const SearchBarLoading: FC<SearchBarLoadingProps> = ({}) => {
  const styles = useStyles();
  return (
    <div className={styles.searchContainer}>
      <div className={styles.loadingInfoText} />
      <div className={styles.searchBar}>
        <div className={styles.loadingIcon} />
        <div className={styles.loadingInput} />
        <div className={styles.loadingButton} />
      </div>
    </div>
  );
};

export default SearchBarLoading;
