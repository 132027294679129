import { Theme, makeStyles } from '@material-ui/core';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../Constants';
import { ActionButtonProps } from './ActionButton';

export const useStyles = makeStyles<Theme, ActionButtonProps>({
  actionButton: ({ variant, disabled }) => ({
    height: 40,
    minWidth: 150,
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // important is used to persist color in disabled state
    color: variant
      ? `${theme.colors.buttonPrimary} !important`
      : `${theme.colors.light} !important`,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    paddingLeft: theme.units.padding * 6,
    paddingRight: theme.units.padding * 6,
    backgroundColor: variant === 'outlined' ? 'white' : theme.colors.buttonPrimary,
    borderRadius: 0,
    textTransform: theme.font.buttonCase as any,
    border: variant === 'outlined' ? `2px solid ${theme.colors.buttonPrimary}` : undefined,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      minWidth: 120,
    },
    '&:hover': {
      color: `${theme.colors.light} !important`,
      backgroundColor: theme.colors.buttonPrimary,
    },
    opacity: disabled ? 0.7 : 1,
  }),
});
