import React, { FC, useRef } from 'react';
import { useStyles } from './ImageUploadStyles';
import imageIcon from '../../assets/image.png';
import addIcon from '../../assets/plus.png';
import deleteIcon from '../../assets/delete.png';

interface ImageUploadProps {
  files: File[];
  title: string;
  setFiles: (files: File[]) => void;
  subtitle?: string;
  errorMessage?: string;
  acceptType?: string;
  multiple?: Boolean;
  onBlur?: () => void;
  touched?: boolean;
}

const ImageUpload: FC<ImageUploadProps> = ({
  files,
  title,
  setFiles,
  subtitle,
  errorMessage,
  acceptType,
  multiple,
  onBlur,
  touched,
}) => {
  const styles = useStyles();
  const inputRef = useRef(null);
  return (
    <div>
      <div
        className={`${styles.fileMainContainer} ${
          touched && errorMessage ? styles.errorContainer : ''
        }`}
      >
        <div className={styles.fileContainer}>
          {title && <div className={styles.heading}>{title}</div>}
          {subtitle && <div className={styles.subHeading}>{subtitle}</div>}
          {files.map((attachment, index) => (
            <div
              style={{
                padding: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid',
                borderBottomColor: '#666666',
              }}
              key={`${attachment.name}-${index}`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={URL.createObjectURL(attachment)}
                  style={{ width: 48, height: 48, marginRight: 24 }}
                  alt="upload"
                />
                <div>{attachment.name}</div>
              </div>
              <img
                src={deleteIcon}
                className={styles.addIconStyle}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const updatedFiles = [...files];
                  updatedFiles.splice(index, 1);
                  setFiles(updatedFiles);
                }}
                alt="delete"
              />
            </div>
          ))}
          <div
            style={{
              padding: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (inputRef.current) {
                (inputRef.current as any).click();
              }
            }}
          >
            <div style={{ display: 'flex' }}>
              <img src={imageIcon} className={styles.imageIconStyle} alt="add" />
              <div>Add photo</div>
            </div>
            <img src={addIcon} className={styles.addIconStyle} alt="add" />
            <input
              accept={acceptType || 'image/*'}
              ref={inputRef}
              type="file"
              onChange={(e) => {
                const uploadedData = e.target.files as any;
                const uploadedFiles: File[] = uploadedData ? [...uploadedData] : [];
                setFiles([...files, ...uploadedFiles]);
                e.target.value = '';
              }}
              style={{ display: 'none' }}
              multiple={!!multiple}
              onBlur={onBlur}
            />
          </div>
        </div>
      </div>
      {touched && errorMessage && <div className={styles.errorTextStyle}>{errorMessage}</div>}
    </div>
  );
};
export default ImageUpload;
