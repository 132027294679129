import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ListBox } from '../listBox/ListBox';
import { ForumOutlined } from '@material-ui/icons';
import { LABELS } from './ChatWithProfessionalBannerConstants';
import ActionButton from '../actionButton/ActionButton';
import { theme } from '../../theme/Theme';
import Chat from '../../assets/chat-bubble-group.png';
import { useHistory } from 'react-router';
import { routes } from '../../Routes';

interface ChatWithProfessionalBannerProps {}

const ChatWithProfessionalBanner: FC<ChatWithProfessionalBannerProps> = () => {
  const history = useHistory();
  return (
    <Box
      width="100%"
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      p={2}
      bgcolor={theme.colors.primary}
      gridRowGap="1em"
    >
      <ListBox alignItems="center" color="white" spacing={6} direction="row">
        <img src={Chat} />
        <Typography className="title" variant="h3">
          {LABELS.text}
        </Typography>
      </ListBox>
      <ActionButton
        onClick={() => {
          history.push(routes.contactUs);
        }}
      >
        {LABELS.buttonLabel}
      </ActionButton>
    </Box>
  );
};

export default ChatWithProfessionalBanner;
