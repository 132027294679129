import { UserDetails } from '../../models/auth/User';
import { JobDetails } from '../../models/common/JobDetails';
import { Property } from '../../models/common/Property';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { BookingAction, BookingActionTypes } from '../actions/BookingActions';
import { BookingState } from '../state/BookingState';

const initialState: BookingState = {
  offerType: undefined,
  steps: undefined,
  jobDetails: undefined,
  userId: undefined,
  userDetails: undefined,
  propertyList: [],
  selectedPostcode: undefined,
  selectedProperty: undefined,
  preferredTime: undefined,
  tradeList: [],
  selectedTrade: [],
  tradeComment: undefined,
  skills: [],
  photos: [],
  ageOfHouse: undefined,
  numberOfStories: undefined,
  typeOfWalls: undefined,
  isParkingAvailable: undefined,
  hasPets: undefined,
  typeOfRoof: undefined,
};

export const bookingReducer = (state = initialState, action: BookingAction) => {
  switch (action.type) {
    case BookingActionTypes.UPDATE_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.data as Property,
      };
    case BookingActionTypes.UPDATE_SELECTED_TRADE:
      return {
        ...state,
        selectedTrade: action.data as TradeResponse[],
      };
    case BookingActionTypes.SET_OFFER_TYPE:
      return {
        ...state,
        offerType: (action.data as UpdateOfferType).offerType,
        steps: (action.data as UpdateOfferType).steps,
      };
    case BookingActionTypes.UPDATE_SELECTED_TIME_SLOT:
      return {
        ...state,
        preferredTime: action.data as string,
      };
    case BookingActionTypes.UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: action.data as UserDetails,
      };
    case BookingActionTypes.UPDATE_USER_ID:
      return {
        ...state,
        userId: action.data as number,
      };
    case BookingActionTypes.UPDATE_JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.data as JobDetails,
      };
    case BookingActionTypes.GET_PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        propertyList: action.data as Property[],
      };
    case BookingActionTypes.GET_TRADES_LIST_SUCCESS:
      return {
        ...state,
        tradeList: action.data as TradeResponse[],
      };
    case BookingActionTypes.UPDATE_TRADE_COMMENT:
      return {
        ...state,
        tradeComment: action.data as string,
      };
    case BookingActionTypes.UPDATE_POSTCODE:
      return {
        ...state,
        selectedPostcode: action.data as string,
      };
    case BookingActionTypes.UPDATE_SKILLS:
      return {
        ...state,
        skills: action.data as string[],
      };
    case BookingActionTypes.UPDATE_PHOTOS:
      return {
        ...state,
        photos: action.data as File[],
      };
    case BookingActionTypes.GET_PROPERTY_SUCCESS:
      return {
        ...state,
        selectedProperty: action.data as Property,
      };

    case BookingActionTypes.UPDATE_HOUSE_AGE:
      return {
        ...state,
        ageOfHouse: action.data as string,
      };
    case BookingActionTypes.UPDATE_NUMBER_OF_STORIES:
      return {
        ...state,
        numberOfStories: action.data as string,
      };
    case BookingActionTypes.UPDATE_WALL_TYPE:
      return {
        ...state,
        typeOfWalls: action.data as string,
      };
    case BookingActionTypes.UPDATE_ROOF_TYPE:
      return {
        ...state,
        typeOfRoof: action.data as string,
      };
    case BookingActionTypes.UPDATE_PARKING_AVAILABLE:
      return {
        ...state,
        isParkingAvailable: action.data as boolean,
      };
    case BookingActionTypes.UPDATE_HAS_PETS:
      return {
        ...state,
        hasPets: action.data as boolean,
      };
    case BookingActionTypes.RESET_DATA:
      return {
        ...state,
        steps: undefined,
        jobDetails: undefined,
        userDetails: undefined,
        propertyList: [],
        selectedPostcode: undefined,
        selectedProperty: undefined,
        preferredTime: undefined,
        tradeList: [],
        selectedTrade: [],
        tradeComment: undefined,
        skills: [],
        photos: [],
        ageOfHouse: undefined,
        numberOfStories: undefined,
        typeOfWalls: undefined,
        isParkingAvailable: undefined,
        hasPets: undefined,
        typeOfRoof: undefined,
      };
    default:
      return state;
  }
};
