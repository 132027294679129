import { OfferCategory } from '../../models/bundles/Bundles';

export const LABELS = {
  FROM: 'from',
  VIEW_DETAILS: 'View Details',
};

export interface BundleCardType {
  image: string;
  title: string;
  category?: OfferCategory;
  description: string;
  price?: number;
  quote?: string;
  location?: string;
  skills: string[];
  id: number;
  postcodes: string[];
  actionLabel?: string;
}
