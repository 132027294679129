import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './ElectricianQuoteStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import ElectricianBG from '../../assets/electrician/hero.jpg';
import Clip from '../../assets/electrical-quote.png';
import ContactUs from '../../assets/contact-us-cropped.png';
import { LABELS, LIST, STEPS, WHY_CHOOSE } from './ElectricianQuoteConstants';
import { routes } from '../../Routes';
import { BookingActions } from '../../store/actions/BookingActions';
import { OfferType } from '../../models/common/OfferType';
import SectionContent from '../../components/sectionContent/SectionContent';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import { getSteps } from '../../helpers/StepHelper';
import { mockDataServices } from '../../mock/BundleMock';
import AddressCheckFooter from '../../components/addressCheckFooter/AddressCheckFooter';

interface ElectricianQuoteProps extends RouteComponentProps {
  updateOfferType: (data: UpdateOfferType) => void;
  updateSkills: (data: string[]) => void;
}

const ElectricianQuote: FC<ElectricianQuoteProps> = ({
  history,
  updateOfferType,
  location,
  updateSkills,
}) => {
  const styles = useStyles();

  const setSkills = () => {
    const pathName = location.pathname;
    const currentService = mockDataServices.find((data) => data.location === pathName);
    const skills = currentService ? currentService.skills : [];
    updateSkills(skills);
  };
  const onSubmit = () => {
    updateOfferType({
      offerType: OfferType.ELECTRICIAN_FLOW_1,
      steps: getSteps(OfferType.ELECTRICIAN_FLOW_1),
    });
    setSkills();
    history.push(routes.jobDetails);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Header />
      <BackgroundCover darken backgroundImage={ElectricianBG}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.headerTitle}>{LABELS.HERO_TITLE}</div>
            <div className={styles.headerSubtitle}>{LABELS.HERO_TEXT}</div>
            <div
              className={styles.headerButton}
              onClick={() =>
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
              }
            >
              {LABELS.GET_QUOTE}
            </div>
          </div>
        </div>
      </BackgroundCover>
      <div className={styles.container}>
        <SectionContent
          title={LABELS.TITLE}
          list={LIST}
          image={Clip}
          actionText={LABELS.GET_QUOTE}
          onAction={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}
        />
        <HowItWorksSection steps={STEPS} title={LABELS.HOW_IT_WORKS} />
        <SectionContent title={LABELS.MANAGE_EVERYTHING_TITLE} image={ContactUs} reversed>
          {WHY_CHOOSE.map((why) => (
            <>
              <div className={styles.stepTitle}>{why.title}</div>
              <div className={styles.stepText}>{why.text}</div>
            </>
          ))}
        </SectionContent>
      </div>
      {/* <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.GET_QUOTE}
        primaryAction={() => onSubmit()}
      /> */}
      <AddressCheckFooter onNextText={LABELS.GET_QUOTE} onNext={() => onSubmit()} />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateOfferType: (data: UpdateOfferType) => dispatch(BookingActions.updateOfferType(data)),
  updateSkills: (data: string[]) => dispatch(BookingActions.updateSkills(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ElectricianQuote));
