import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ListBox } from '../../components/listBox/ListBox';
import { LABELS } from './SuccessDialogConstants';
import { RegisterFormType } from '../../services/booking/BookingService';
import FormattedText from '../../components/formattedText/FormattedText';
import successCheck from '../../assets/check-circle.png';
import ActionButton from '../../components/actionButton/ActionButton';
import { useHistory } from 'react-router';
import { routes } from '../../Routes';

interface SuccessDialogProps {
  type: RegisterFormType;
  onDone: () => void;
}

const SuccessDialog: FC<SuccessDialogProps> = ({ type, onDone }) => {
  const history = useHistory();
  return (
    <Dialog open>
      <DialogContent>
        <Box py={3} px={5}>
          <ListBox alignItems="center" spacing={8}>
            <img style={{ height: 90, width: 90 }} src={successCheck} />

            <Typography variant="h2" color="secondary" className="title">
              {type === 'registerInterest' ? LABELS.registerHeading : LABELS.contactHeading}
            </Typography>
            <Typography align="center">
              <FormattedText>
                {type === 'registerInterest' ? LABELS.registerContent : LABELS.contactUsContent}
              </FormattedText>
            </Typography>

            <ListBox spacing={4} direction="row">
              <ActionButton
                variant="outlined"
                onClick={() => {
                  history.push(routes.home);
                }}
              >
                {LABELS.homeButton}
              </ActionButton>
              <ActionButton
                onClick={() => {
                  onDone();
                }}
              >
                {LABELS.doneButton}
              </ActionButton>
            </ListBox>
          </ListBox>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessDialog;
