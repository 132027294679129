import { theme } from '../../../theme/Theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  inputStyle: {
    border: 'none',
    borderRadius: theme.units.borderRadius,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.l,
      fontFamily: theme.font.family,
      fontWeight: 300,
    },
    fontSize: theme.font.l,
    fontFamily: theme.font.family,
    color: theme.colors.inputText,
    backgroundColor: theme.colors.inputBackground,
    height: 52,
    flex: 1,
    padding: theme.units.padding * 3,
  },
  errorInput: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.error,
  },
  errorTextStyle: {
    fontSize: theme.font.xs,
    fontFamily: theme.font.family,
    color: theme.colors.error,
    marginTop: theme.units.margin,
  },
  inputCard: {
    marginBottom: theme.units.margin * 8,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.margin,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    marginLeft: theme.units.margin,
  },
});
