import smartHome from '../../../assets/smart-home.svg';
import electrical from '../../../assets/electrical.svg';
import lights from '../../../assets/lights.svg';
import fans from '../../../assets/fans.svg';
import tools from '../../../assets/tools.svg';

export const LABELS = {
  close: 'Close menu',
  packages: 'Packages',
  services: 'Services',
  electrician: 'Electrician',
  solar: 'Solar',
  viewAll: 'View all',
};

export const PRIORITY_ORDER = ['Home Automation', 'Fans', 'Lights', 'EV Charging'];

export const getIcon = (type: string) => {
  switch (type) {
    case 'Home Automation':
      return smartHome;

    case 'EV Charging':
      return electrical;

    case 'Lights':
      return lights;

    case 'Fans':
      return fans;

    case 'Services':
      return tools;

    default:
      return '';
  }
};
