import * as Yup from 'yup';
import { titleCaseLabels } from '../../helpers/helperFunctions';

export const LABELS = titleCaseLabels({
  HEADING: 'Your details',
  HEADER_ARROW_TEXT: 'Step 1 of 4',
  HEADER_TITLE: 'Confirm your details',
  HEADER_SUBTITLE: 'Step 1 of 4',
  NAME: 'First name',
  EMAIL: 'Email',
  MOBILE: 'Mobile',
  BACK: 'Back',
  NEXT: 'Next',
  LAST_NAME: 'Last name',
  ENTER_NAME: 'Enter name',
  BILL_HEADING: 'Add a current bill',
});

export const ErrorMessages = {
  REQUIRED: 'Required field',
  EMAIL: 'Enter a valid email',
  ATTACHMENTS: 'Please upload at least two photos',
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(ErrorMessages.REQUIRED)
    .matches(/^[a-z ,.'-]+$/i, "Only Albhabets and [,.'-] allowed"),
  lastName: Yup.string()
    .required(ErrorMessages.REQUIRED)
    .matches(/^[a-z ,.'-]+$/i, "Only Albhabets and [,.'-] allowed"),
  mobile: Yup.string()
    .required(ErrorMessages.REQUIRED)
    .matches(/^(\+61|0)\d{9}$/, 'Phone format should be +61XXXXXXXXX or 0XXXXXXXXX'),
  email: Yup.string().required(ErrorMessages.EMAIL).email(ErrorMessages.EMAIL),
});
