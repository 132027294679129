/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { put, takeEvery } from 'redux-saga/effects';
import {
  BundlePackage,
  BundleResponse,
  CustomerInterestsRequest,
} from '../../models/bundles/Bundles';
import BundlesService from '../../services/bundles/BundlesService';
import { BundlesAction, BundlesActions, BundlesActionTypes } from '../actions/BundlesActions';

function* getBundles(action: BundlesAction) {
  try {
    const response: BundleResponse = yield BundlesService.getBundles();
    yield put(BundlesActions.getBundlesSuccess(response.packages));
  } catch (error) {
    console.error(error);
    yield put(BundlesActions.getBundlesError(error as Error));
  }
}

function* postMailSignup(action: BundlesAction) {
  try {
    yield BundlesService.postMailSignup(action.data as CustomerInterestsRequest);
    yield put(BundlesActions.postMailSignupSuccess());
  } catch (error) {
    console.error(error);
    yield put(BundlesActions.postMailSignupError(error as Error));
  }
}

function* bundlesWatcher(): IterableIterator<any> {
  yield takeEvery(BundlesActionTypes.GET_BUNDLES_REQUEST, (action: BundlesAction) =>
    getBundles(action),
  );
  yield takeEvery(BundlesActionTypes.POST_MAIL_SIGNUP_REQUEST, (action: BundlesAction) =>
    postMailSignup(action),
  );
}

export default bundlesWatcher;
