import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import alert from '../../assets/alert.png';
import circleLeftArrow from '../../assets/circle-left-arrow.png';
import circleRightArrow from '../../assets/circle-right-arrow.png';
import cloud from '../../assets/cloud.png';
import dropDown from '../../assets/dropdown.png';
import file from '../../assets/file.png';
import homeIcon from '../../assets/home.png';
import location from '../../assets/location.png';
import bookOffer from '../../assets/middysAssets/howEst.jpg';
import Header from '../../components/header/Header';
import Find from '../../assets/electrician/find.jpg';
import Electrician2 from '../../assets/middysAssets/electrician2.png';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './CategoryScreenStyles';
import { ToggleButton } from '@material-ui/lab';
import { Bundle, BundlePackage, Inclusion } from '../../models/bundles/Bundles';
import { Dialog } from '@material-ui/core';
import { routes } from '../../Routes';
import { OfferType } from '../../models/common/OfferType';
import { BookingActions } from '../../store/actions/BookingActions';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import SectionContent from '../../components/sectionContent/SectionContent';
import AddressCheckFooter from '../../components/addressCheckFooter/AddressCheckFooter';
import { getQueryParams } from '../../helpers/QueryHelper';
import { BundlesActions, BundlesActionTypes } from '../../store/actions/BundlesActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { getSteps } from '../../helpers/StepHelper';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import rightArrow from '../../assets/right-carousel.png';
import leftArrow from '../../assets/left-carousel.png';
import Carousel from 'react-elastic-carousel';
import { SIZES } from '../../Constants';
import BundleCardLoading from '../../components/bundleCard/BundleCardLoading';
import LoadingHero from '../bundleDetails/components/LoadingHero';
import BundleCard from '../../components/bundleCard/BundleCard';

interface CategoryScreenProps extends RouteComponentProps {
  loadingBundles: boolean;
  bundles: BundlePackage[] | undefined;
  accessToken: string | boolean | null;
  getBundlesRequest: () => void;
  updateSelectedBundle: (data: BundlePackage) => void;
}

const CategoryScreen: FC<CategoryScreenProps> = ({
  history,
  location,
  loadingBundles,
  bundles,
  accessToken,
  getBundlesRequest,
  updateSelectedBundle,
}) => {
  const categoryURL = getQueryParams(location, 'category');

  useEffect(() => {
    if (categoryURL && accessToken) {
      if (bundles === undefined) {
        getBundlesRequest();
      }
    }
  }, [categoryURL, bundles, accessToken]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const filteredBundles = useMemo<BundlePackage[] | undefined>(() => {
    if (!bundles) return undefined;
    return bundles.filter((b) => !!b.category && b.category.title === categoryURL);
  }, [bundles, categoryURL]);

  const styles = useStyles();
  console.log('filterd', filteredBundles);

  return (
    <div>
      <Header />
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.navigationContainer}>
            <img
              src={homeIcon}
              onClick={() => history.push(routes.packages)}
              className={styles.homeIcon}
              alt="home icon"
            />
            <div className={styles.navArrow}>{`>`}</div>
            <div className={styles.navTitle}>
              {categoryURL ? categoryURL : 'Loading packages...'}
            </div>
          </div>
          {!categoryURL || loadingBundles ? (
            <>
              <LoadingHero />
              <br />
              <div className={styles.offersGrid}>
                {[...Array(12).fill(null)].map((_, idx) => (
                  <BundleCardLoading key={idx} />
                ))}
              </div>
            </>
          ) : (
            <>
              {filteredBundles && !!filteredBundles.length && (
                <>
                  <div className={styles.heroImageContainer}>
                    <img src={filteredBundles![0]!.category!.image} className={styles.heroImage} />
                  </div>
                  <div className={styles.title}>{filteredBundles![0]!.category!.title}</div>
                  <div className={styles.offersGrid}>
                    {filteredBundles.map((p, idx) => (
                      <BundleCard
                        key={idx}
                        bundleCard={{
                          title: p.standard.title,
                          image: p.standard.heroImage,
                          description: p.standard.description,
                          price: p.standard.price,
                          skills: p.skills,
                          id: p.productId,
                          postcodes: p.postcodes,
                        }}
                        onClick={() => {
                          updateSelectedBundle(p);
                          history.push(`${routes.bundleDetails}?bundleId=${p.productId}`);
                        }}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([BundlesActionTypes.GET_BUNDLES]);

const mapStateToProps = (state: ApplicationState) => ({
  loadingBundles: loading(state),
  selectedBundle: state.bundlesState.selectedBundle,
  bundles: state.bundlesState.bundles,
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBundlesRequest: () => dispatch(BundlesActions.getBundlesRequest()),
  updateSelectedBundle: (data: BundlePackage) =>
    dispatch(BundlesActions.updateSelectedBundle(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryScreen));
