import React, { FC } from 'react';
import { useStyles } from './BundleCardStyles';
import { LABELS, BundleCardType } from './BundleCardConstants';

interface BundleCardProps {
  bundleCard: BundleCardType;
  onClick: () => void;
}

const BundleCard: FC<BundleCardProps> = ({ bundleCard, onClick }) => {
  const styles = useStyles();
  return (
    <div className={styles.bundleContainer} onClick={onClick}>
      <div className={styles.imageContainer}>
        <img src={bundleCard.image} className={styles.image} alt="bundle" />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.title}>{bundleCard.title}</div>
        <div className={styles.description}>
          {bundleCard.description.length > 100
            ? `${bundleCard.description.substring(0, 100)}...`
            : bundleCard.description}
        </div>
        {bundleCard.price && (
          <div className={styles.priceRowContainer}>
            <div className={styles.priceContainer}>
              <div className={styles.from}>{LABELS.FROM}</div>
              <div className={styles.price}>${bundleCard.price}</div>
            </div>

            <div className={styles.actionButton}>{LABELS.VIEW_DETAILS}</div>
          </div>
        )}
        {bundleCard.quote && <div className={styles.actionButton}>{bundleCard.quote}</div>}
      </div>
    </div>
  );
};

export default BundleCard;
