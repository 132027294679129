/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../../theme/Theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  faqContainer: {},
  faqRow: {
    marginBottom: theme.units.margin * 4,
    paddingBottom: theme.units.margin * 4,
    borderBottom: `${theme.colors.borderColor} 2px solid`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  actionIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
    objectPosition: 'center',
    transition: 'all 0.5s ease 0s',
  },
  actionIconActive: {
    transform: 'rotate(180deg)',
    transition: 'all 0.5s ease 0s',
  },
  content: {
    transition: 'all 0.5s ease 0s',
    overflow: 'hidden',
    height: '100%',
  },
  question: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.l,
    fontWeight: 500,
    color: theme.colors.darkGrey,
    marginBottom: theme.units.margin * 2,
    marginTop: theme.units.margin * 6,
  },
  description: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.6,
    paddingBottom: theme.units.margin * 4,
  },
});
