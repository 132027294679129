import React, { FC, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { Box, Dialog, Grid, Typography } from '@material-ui/core';
import { useStyles } from './MarketplaceStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getQueryParams } from '../../helpers/QueryHelper';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import Header from '../../components/header/Header';
import { initialFilters, LABELS } from './MarketplaceConstants';
import FilterBar from './components/filterBar/FilterBar';
import { FilterItem } from './components/filterBar/FilterBarConstants';
import { areAllFiltersOff, isFilterActive } from './components/filterBar/FilterBarUtils';
import PostcodeSearch from './components/postcodeSearch/PostcodeSearch';
import Button from '../../components/button/Button';
import { mockDataServices } from '../../mock/BundleMock';
import BundleCard from '../../components/bundleCard/BundleCard';
import { BundlePackage } from '../../models/bundles/Bundles';
import { BookingActions } from '../../store/actions/BookingActions';
import { routes } from '../../Routes';
import Hero from '../../assets/marketplace.jpg';
import { getOffersForPostcode, getServicesForPostcode } from '../../helpers/PostcodeHelper';
import BundleCardLoading from '../../components/bundleCard/BundleCardLoading';
import { BundlesActions } from '../../store/actions/BundlesActions';
import { BundleCardType } from '../../components/bundleCard/BundleCardConstants';
import MailingListModal from '../../components/mailingListModal/MailingListModal';
import { ListBox } from '../../components/listBox/ListBox';
import ActionButton from '../../components/actionButton/ActionButton';
import mapImage from '../../assets/Map.jpg';

interface MarketplaceProps extends RouteComponentProps {
  selectedPostcode: string | undefined;
  bundles: BundlePackage[] | undefined;
  accessToken: string | boolean | null;
  getBundlesRequest: () => void;
  updateSelectedBundle: (data: BundlePackage) => void;
  updatePostcode: (data: string) => void;
  updateSkills: (data: string[]) => void;
}

const Marketplace: FC<MarketplaceProps> = ({
  location,
  history,
  selectedPostcode,
  bundles,
  accessToken,
  getBundlesRequest,
  updatePostcode,
  updateSelectedBundle,
  updateSkills,
}) => {
  const postcodeURL = getQueryParams(location, 'postcode');
  const [filters, setFilters] = useState<FilterItem[]>(initialFilters());
  const [postcode, setPostcode] = useState<string>(selectedPostcode || postcodeURL);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const styles = useStyles();

  useEffect(() => {
    if (bundles === undefined) {
      getBundlesRequest();
    }
  }, [accessToken, bundles]);

  const bundlesToLoop = useMemo<BundlePackage[]>(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    if (postcode.length !== 4 || bundles === undefined) {
      return [];
    }
    return getOffersForPostcode(postcode, bundles);
  }, [bundles, postcode]);

  const servicesToLoop = useMemo<BundleCardType[]>(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    if (postcode.length !== 4) {
      return [];
    }
    return getServicesForPostcode(postcode, mockDataServices);
  }, [mockDataServices, postcode]);

  const allFiltersOff = useMemo<boolean>(() => {
    return areAllFiltersOff(filters);
  }, [filters]);

  return (
    <div>
      <Header />
      <BackgroundCover darken backgroundImage={Hero} loading={isLoading}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.headerTitle}>
              {!isLoading && servicesToLoop.length === 0
                ? LABELS.HERO_TITLE_NOT_AVAILABLE
                : LABELS.HERO_TITLE}
            </div>
            <div className={styles.headerSubtitle}>{LABELS.HERO_TEXT}</div>
          </div>
        </div>
      </BackgroundCover>
      <div className={styles.container}>
        {!isLoading && servicesToLoop.length > 0 && (
          <>
            <div className={styles.allOffersText}>{LABELS.OFFERS_FOR_YOU}</div>
            <div className={styles.filterRow}>
              <FilterBar filters={filters} handlePress={setFilters} togglable singleToggleable />
              {/* <PostcodeSearch
                location={postcode}
                onLocationChange={(postcode: string) => setPostcode(postcode)}
              /> */}
            </div>
            <div className={styles.offersGrid}>
              {isLoading ? (
                <>
                  {[...Array(4).fill(null)].map((_, idx) => (
                    <BundleCardLoading key={idx} />
                  ))}
                </>
              ) : (
                <>
                  {(allFiltersOff || isFilterActive(filters, 'Packages')) && (
                    <>
                      {bundlesToLoop.map((bundle, idx) => (
                        <BundleCard
                          key={idx}
                          bundleCard={{
                            title: bundle.standard.title,
                            image: bundle.standard.heroImage,
                            description: bundle.standard.description,
                            price: bundle.standard.price,
                            skills: bundle.skills,
                            id: bundle.productId,
                            postcodes: bundle.postcodes,
                          }}
                          onClick={() => {
                            updatePostcode(postcode);
                            updateSelectedBundle(bundle);
                            history.push(routes.bundleDetails);
                          }}
                        />
                      ))}
                    </>
                  )}
                  {(allFiltersOff || isFilterActive(filters, 'Services')) && (
                    <>
                      {servicesToLoop.map((bundleCard, idx) => (
                        <BundleCard
                          key={idx}
                          bundleCard={bundleCard}
                          onClick={() => {
                            updateSkills(bundleCard.skills);
                            history.push(bundleCard.location!);
                          }}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
            <div className={styles.signupContainer}>
              <div className={styles.signupText}>{LABELS.ADDING_MORE}</div>
              <Button
                secondary
                handlePress={() => {
                  history.push(routes.registerInterest);
                }}
              >
                {LABELS.SIGN_UP}
              </Button>
            </div>
          </>
        )}

        {!isLoading && servicesToLoop.length === 0 && (
          <Box mt={6} mb={3} display="flex" justifyContent="center">
            <Grid spacing={6} container>
              <Grid item md={6}>
                <ListBox maxWidth="650px" spacing={8} alignItems="center">
                  <Typography align="center" variant="h1" color="secondary" className="title">
                    {LABELS.NOT_AVAILABLE_HEADING}
                  </Typography>
                  <Typography style={{ textAlign: 'center' }} color="textSecondary" variant="h4">
                    {LABELS.NOT_AVAILABLE_DESCRIPTION}
                  </Typography>
                  <ListBox direction="row" spacing={5}>
                    <ActionButton
                      onClick={() => {
                        history.push(routes.packages);
                      }}
                      variant="outlined"
                    >
                      {LABELS.BACK_TO_SEARCH}
                    </ActionButton>
                    <ActionButton
                      onClick={() => {
                        history.push(routes.registerInterest);
                      }}
                    >
                      {LABELS.REGISTER_INTEREST}
                    </ActionButton>
                  </ListBox>
                </ListBox>
              </Grid>
              <Grid item md={6}>
                <img src={mapImage} />
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
      <Dialog
        open={showPopup}
        maxWidth="sm"
        fullWidth
        style={{
          zIndex: 999999,
        }}
        onClose={() => setShowPopup(false)}
      >
        <MailingListModal postcode={postcode} closeModal={() => setShowPopup(false)} />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  accessToken: state.token.accessToken,
  bundles: state.bundlesState.bundles,
  selectedPostcode: state.bookingState.selectedPostcode,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBundlesRequest: () => dispatch(BundlesActions.getBundlesRequest()),
  updateSelectedBundle: (data: BundlePackage) =>
    dispatch(BundlesActions.updateSelectedBundle(data)),
  updatePostcode: (data: string) => dispatch(BookingActions.updatePostcode(data)),
  updateSkills: (data: string[]) => dispatch(BookingActions.updateSkills(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Marketplace));
