import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import { MultipartFormData } from '../MultipartFormData.data';
import { TradeRequest } from '../../models/tradeRequest/TradeRequest';
import { PropertyRequest } from '../../models/propertyRequest/PropertyRequest';
import { Property } from '../../models/common/Property';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { RegisterInteresFormValues } from '../../containers/registerInterestForm/RegisterInterestFormConstants';
import { useMutationQuery } from '../reactQuery/reactQuery';

export type RegisterFormType = 'registerInterest' | 'contactUs';

const bookingService = () => {
  const service: ApiService = new ApiService(ServiceType.booking);
  const propertyService: ApiService = new ApiService(ServiceType.property);
  function createMaintenance(data: MultipartFormData[]): Promise<any> | any {
    return service.postMultipart({}, data);
  }

  function getTrades(data: TradeRequest): Promise<TradeResponse[]> {
    return service.get({
      query: {
        postcode: data.postcode,
        categories: data.categories.join(','),
        privateName: data.privateName,
        propertyAddress: data.propertyAddress,
      },
      route: ['offices'],
    });
  }

  function getProperties(data: PropertyRequest): Promise<Property[]> {
    return propertyService.get({
      query: {
        email: data.email,
      },
      route: ['properties'],
    });
  }

  function getProperty(propertyId: string): Promise<Property> {
    return propertyService.get({
      query: {},
      route: ['properties', propertyId],
    });
  }

  function useRegisterInterest(type: RegisterFormType) {
    const { mutate, isLoading, isSuccess } = useMutationQuery<void, RegisterInteresFormValues>({
      key: ['register-interest'],
      func: ({ otherRoomPreference, ...data }) => {
        return service.post(
          { route: ['form-submission'] },
          {
            privateName: 'MIDDYS',
            email: data.email,
            formName: type,
            formData: { ...data, otherRoomPreference: otherRoomPreference || undefined },
          },
        );
      },
    });

    return { registerInterest: mutate, isLoading, isSuccess };
  }

  return {
    createMaintenance,
    getTrades,
    getProperties,
    getProperty,
    useRegisterInterest,
  };
};

export default bookingService();
