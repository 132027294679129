import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';
import { pageContainer } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  footerContainer: {},
  footerContent: {
    ...pageContainer,
  },
  topFooter: {
    backgroundColor: theme.colors.darkGrey,
    width: '100%',
    paddingTop: theme.units.padding * 8,
    paddingBottom: theme.units.padding * 16,
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  poweredBy: {
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.margin * 8,
    },
    height: 18,
    width: 'fit-content',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  middysLogoImg: {
    height: 45,
    width: 'fit-content',
    objectFit: 'contain',
  },
  interestedContainer: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.margin * 8,
    },
  },
  interestedTitle: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.light,
    fontWeight: 500,
  },
  interestedText: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.light,
    fontWeight: 400,
    maxWidth: 300,
    marginTop: theme.units.margin * 3,
  },
  span: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.light,
    fontWeight: 400,
    maxWidth: 300,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  socialsContainer: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.margin * 8,
    },
  },
  socialsTitle: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.light,
    fontWeight: 400,
  },
  socialIcons: {
    display: 'flex',
    marginTop: theme.units.margin * 3,
  },
  socialIcon: {
    height: 22,
    width: 22,
    objectFit: 'contain',
    marginRight: theme.units.margin * 4,
    cursor: 'pointer',
  },
  bottomFooter: {
    paddingTop: theme.units.padding * 4,
    paddingBottom: theme.units.padding * 4,
    backgroundColor: theme.colors.light,
  },
  flexRowCentered: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  sponsors: {
    display: 'flex',
  },
  sponsor: {
    height: 40,
    objectFit: 'contain',
    marginRight: theme.units.margin * 4,
    cursor: 'pointer',
  },
  cc: {
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
    color: theme.colors.black87,
    fontWeight: 400,
    textAlign: 'right',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: theme.units.margin * 4,
    },
  },
  registerInterestBanner: {
    background: theme.colors.navyBlue,
    color: 'white',
    padding: `${theme.units.padding * 4}px 0`,
  },
  bannerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '1em',
  },
});
