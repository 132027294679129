import { makeStyles } from '@material-ui/core';
import { pageContainer } from '../../theme/GlobalStyles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  formContainer: {
    boxShadow: '0px 4px 19px 0px #0000002B',
    marginTop: theme.units.margin * 20,
    padding: `${theme.units.padding * 10}px ${theme.units.padding * 15}px`,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      padding: `${theme.units.padding * 10}px ${theme.units.padding * 10}px`,
    },
    background: 'white',
  },
  formBackgroundWrapper: {
    background: 'linear-gradient(to bottom, #ffffff 150px, #fafafa 150px)',
    paddingBottom: theme.units.padding * 20,
  },
});
