import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    ...root,
  },
  container: {
    ...pageContainer,
    paddingBottom: 120,
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
  },
  termsContainer: {
    border: `${theme.colors.borderColor} 2px solid`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    marginTop: 12,
  },
  termsCopyStyle: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    fontWeight: 300,
    color: theme.colors.black,
  },
  linkStyle: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    fontWeight: 300,
    color: theme.colors.secondary,
    cursor: 'pointer',
  },
});
