import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './SearchBarStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LABELS } from './SearchBarConstants';
import searchIcon from '../../../../assets/search.png';
import { routes } from '../../../../Routes';
import { BookingActions } from '../../../../store/actions/BookingActions';
import SearchBarLoading from './SearchBarLoading';
import { Theme, useMediaQuery } from '@material-ui/core';

interface SearchBarProps extends RouteComponentProps {
  loading?: boolean;
  updatePostcode: (data: string) => void;
}

const SearchBar: FC<SearchBarProps> = ({ history, loading, updatePostcode }) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const styles = useStyles();

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down(720));

  const onSubmit = () => {
    updatePostcode(searchInput);
    history.push(`${routes.marketplace}?postcode=${searchInput}`);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <>
      {loading ? (
        <SearchBarLoading />
      ) : (
        <div className={styles.searchContainer}>
          <div className={styles.searchBar}>
            <img className={styles.icon} src={searchIcon} alt="search" />
            <input
              type="number"
              className={styles.input}
              placeholder={isSmallScreen ? LABELS.PLACEHOLDER_SMALL : LABELS.PLACEHOLDER}
              onChange={(event) => setSearchInput(event.target.value)}
              value={searchInput}
              onKeyPress={handleKeyPress}
            />
            <div className={styles.actionButton} onClick={() => onSubmit()}>
              {LABELS.SEE_PACKAGES}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePostcode: (data: string) => dispatch(BookingActions.updatePostcode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchBar));
