import React, { FC } from 'react';
import { useStyles } from '../BundleDetailsStyles';

interface LoadingExtraContentProps {}

const LoadingExtraContent: FC<LoadingExtraContentProps> = ({}) => {
  const styles = useStyles();
  return (
    <>
      {[...Array(1).fill(null)].map(() => (
        <div className={styles.bundleRow}>
          <div className={styles.bundleContent}>
            <div className={styles.loadingSubheading} />
            <div className={styles.loadingTitle} />
            <div className={styles.loadingDescription} style={{ height: 240 }} />
            <div className={styles.loadingButton} />
          </div>
          <div className={styles.loadingImage} />
        </div>
      ))}
    </>
  );
};

export default LoadingExtraContent;
