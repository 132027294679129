import { SIZES } from '../Constants';
import { theme } from './Theme';

export const root = {
  backgroundColor: '#fafafa',
} as const;

export const footerPadding = {
  height: 'auto',
  paddingBottom: 120,
} as const;

export const pageContainer = {
  position: 'relative',
  margin: 'auto',
  maxWidth: '1200px',
  borderRadius: '6px',
  [`@media (max-width: 1200px)`]: {
    paddingLeft: 12,
    paddingRight: 12,
  },
} as const;

export const cardContainer = {
  position: 'relative',
  borderRadius: theme.units.borderRadius,
  boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
  padding: theme.units.padding * 6,
  backgroundColor: theme.colors.light,
  overflow: 'hidden',
} as const;

export const noShadow = {
  boxShadow: 'unset',
  border: `${theme.colors.borderColor} 2px solid`,
};

export const extraPadding = {
  paddingLeft: theme.units.padding * 12,
  paddingRight: theme.units.padding * 12,
  [`@media (max-width: ${SIZES.lg}px)`]: {
    paddingLeft: theme.units.padding * 8,
    paddingRight: theme.units.padding * 8,
  },
  [`@media (max-width: ${SIZES.sm}px)`]: {
    paddingLeft: theme.units.padding * 4,
    paddingRight: theme.units.padding * 4,
  },
};

export const loadingAnimation = {
  background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
  backgroundSize: '200% 100%',
  animation: '1.5s shine linear infinite',
  backgroundColor: theme.colors.offerOverlay,
};

export const mobilePaddingCounter = {
  width: 'calc(100% - 68px)',
  [`@media (max-width: ${SIZES.sm}px)`]: {
    width: 'calc(100% - 36px)',
  },
};

export const classNameGenerator = (classes: (string | undefined | null | boolean)[]) => {
  return classes.join(' ');
};
