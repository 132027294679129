import { UserDetails } from '../../models/auth/User';
import { BundlePackage } from '../../models/bundles/Bundles';
import { DayAndDate } from '../../models/common/DateAndTime';
import { JobDetails } from '../../models/common/JobDetails';
import { OfferType } from '../../models/common/OfferType';
import { Property } from '../../models/common/Property';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import {
  MaintenanceRequest,
  MaintenanceRequestApi,
} from '../../models/maintenanceRequest/MaintenanceRequest';
import { PropertyRequest } from '../../models/propertyRequest/PropertyRequest';
import { TradeRequest } from '../../models/tradeRequest/TradeRequest';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { StoreAction } from '../StoreHelper';

export enum BookingActionTypes {
  UPDATE_SELECTED_PROPERTY = 'UPDATE_SELECTED_PROPERTY',
  UPDATE_SELECTED_TRADE = 'UPDATE_SELECTED_TRADE',
  UPDATE_SELECTED_TIME_SLOT = 'UPDATE_SELECTED_TIME_SLOT',

  SET_OFFER_TYPE = 'SET_OFFER_TYPE',
  // user
  UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS',
  UPDATE_USER_ID = 'UPDATE_USER_ID',
  SUBMIT_MAINTENANCE_REQUEST = 'SUBMIT_MAINTENANCE_REQUEST',
  UPDATE_JOB_DETAILS = 'UPDATE_JOB_DETAILS',
  GET_TRADES_LIST = 'GET_TRADES_LIST',
  GET_TRADES_LIST_REQUEST = 'GET_TRADES_LIST_REQUEST',
  GET_TRADES_LIST_SUCCESS = 'GET_TRADES_LIST_SUCCESS',
  GET_PROPERTY_LIST = 'GET_PROPERTY_LIST',
  GET_PROPERTY_LIST_SUCCESS = 'GET_PROPERTY_LIST_SUCCESS',
  UPDATE_TRADE_COMMENT = 'UPDATE_TRADE_COMMENT',
  UPDATE_POSTCODE = 'UPDATE_POSTCODE',
  UPDATE_SKILLS = 'UPDATE_SKILLS',
  UPDATE_PHOTOS = 'UPDATE_PHOTOS',

  UPDATE_HOUSE_AGE = 'UPDATE_HOUSE_AGE',
  UPDATE_NUMBER_OF_STORIES = 'UPDATE_NUMBER_OF_STORIES',
  UPDATE_WALL_TYPE = 'UPDATE_WALL_TYPE',
  UPDATE_PARKING_AVAILABLE = 'UPDATE_PARKING_AVAILABLE',
  UPDATE_HAS_PETS = 'UPDATE_HAS_PETS',
  UPDATE_ROOF_TYPE = 'UPDATE_ROOF_TYPE',

  GET_PROPERTY_REQUEST = 'GET_PROPERTY_REQUEST',
  GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS',
  RESET_DATA = 'RESET_DATA',
}

export type BookingActionPayload =
  | Property
  | OfferType
  | DayAndDate[]
  | UserDetails
  | number
  | string
  | MaintenanceRequest
  | TradeRequest
  | PropertyRequest
  | Property[]
  | TradeResponse[]
  | MaintenanceRequestApi
  | UpdateOfferType
  | string[]
  | File[]
  | boolean
  | JobDetails;

export type BookingAction = StoreAction<BookingActionTypes, BookingActionPayload>;

export class BookingActions {
  public static updatePostcode(data: string): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_POSTCODE,
      data,
    };
  }

  public static updateOfferType(data: UpdateOfferType): BookingAction {
    return {
      type: BookingActionTypes.SET_OFFER_TYPE,
      data,
    };
  }

  public static updateSelectedProperty(data: Property): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_SELECTED_PROPERTY,
      data,
    };
  }

  public static updateSelectedTrade(data: TradeResponse[]): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_SELECTED_TRADE,
      data,
    };
  }

  public static updateSelectedTimeSlot(data: string): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_SELECTED_TIME_SLOT,
      data,
    };
  }

  public static updateUserDetails(data: UserDetails): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_USER_DETAILS,
      data,
    };
  }

  public static updateUserId(data: number): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_USER_ID,
      data,
    };
  }
  public static submitMaintenanceRequest(data: MaintenanceRequestApi): BookingAction {
    return {
      type: BookingActionTypes.SUBMIT_MAINTENANCE_REQUEST,
      data,
    };
  }
  public static updateJobDetails(data: JobDetails): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_JOB_DETAILS,
      data,
    };
  }
  public static getTradesList(data: TradeRequest): BookingAction {
    return {
      type: BookingActionTypes.GET_TRADES_LIST_REQUEST,
      data,
    };
  }

  public static getTradesListSuccess(data: TradeResponse[]): BookingAction {
    return {
      type: BookingActionTypes.GET_TRADES_LIST_SUCCESS,
      data,
    };
  }

  public static getPropertyList(data: PropertyRequest): BookingAction {
    return {
      type: BookingActionTypes.GET_PROPERTY_LIST,
      data,
    };
  }
  public static getPropertyListSuccess(data: Property[]): BookingAction {
    return {
      type: BookingActionTypes.GET_PROPERTY_LIST_SUCCESS,
      data,
    };
  }

  public static updateTradeComment(data: string): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_TRADE_COMMENT,
      data,
    };
  }

  public static updateSkills(data: string[]): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_SKILLS,
      data,
    };
  }

  public static updatePhotos(data: File[]): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_PHOTOS,
      data,
    };
  }

  public static getProperty(data: string): BookingAction {
    return {
      type: BookingActionTypes.GET_PROPERTY_REQUEST,
      data,
    };
  }
  public static getPropertySuccess(data: Property): BookingAction {
    return {
      type: BookingActionTypes.GET_PROPERTY_SUCCESS,
      data,
    };
  }

  public static resetData(): BookingAction {
    return {
      type: BookingActionTypes.RESET_DATA,
      data: null,
    };
  }

  public static updateHouseAge(data: string): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_HOUSE_AGE,
      data,
    };
  }

  public static updateNumberOfStories(data: string): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_NUMBER_OF_STORIES,
      data,
    };
  }

  public static updateWallType(data: string): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_WALL_TYPE,
      data,
    };
  }

  public static updateRoofType(data: string): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_ROOF_TYPE,
      data,
    };
  }

  public static updateParkingAvailable(data: boolean): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_PARKING_AVAILABLE,
      data,
    };
  }

  public static updateHasPets(data: boolean): BookingAction {
    return {
      type: BookingActionTypes.UPDATE_HAS_PETS,
      data,
    };
  }
}
