export const LABELS = {
  HEADING: 'Offering you more',
  PROPERTY: 'Property Address',
  PACKAGE: 'Package Name',
  QUOTE: 'Quote Type',
  TRADE: 'Trade(s) selected',
  TIME: 'Preferred timeframe',
  NO_DETAILS: 'No details',
  TOTAL: 'Total Cost',
  SOLAR_QUOTE: 'Solar',
  ELECTRICIAN: 'Electrician',
};
