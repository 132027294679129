import { theme } from '../../../../theme/Theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  filterBarContainer: {
    display: 'flex',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.filterBackground,
    paddingTop: theme.units.padding * 2,
    paddingBottom: theme.units.padding * 2,
    paddingLeft: theme.units.padding * 5,
    paddingRight: theme.units.padding * 5,
    position: 'relative',
    marginRight: theme.units.margin * 3,
    minWidth: 75,
    color: theme.colors.black87,
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    cursor: 'pointer',
  },
  activeFilter: {
    backgroundColor: theme.colors.buttonPrimary,
    color: theme.colors.light,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    cursor: 'pointer',
  },
});
