// checking app name from url, if it is not there,using sorted as default.
export const isSorted =
  window.location.href.split('?').length >= 2
    ? window.location.href.split('?')[1].split('=').length >= 2
      ? window.location.href.split('?')[1].split('=')[1] !== 'NAX'
      : true
    : true;

export enum AGENCIES {
  SORTED = 'SORTED',
  NAX = 'NAX',
  MIDDYS = 'MIDDYS',
}

export const APP = window.location.href.includes('SORTED')
  ? AGENCIES.SORTED
  : window.location.href.includes('NAX')
  ? AGENCIES.NAX
  : AGENCIES.MIDDYS;

export const getPageTitle = (location: string) => {
  const prefix = 'Middy’s Home Services | ';
  return (
    prefix +
    (() => {
      switch (true) {
        case location === '/':
          return 'Middy’s Electrical';
        case location.includes('selectTrade'):
          return 'Select Trade';
        case location.includes('electricianQuote'):
          return 'Book a local electrician';
        case location.includes('solarQuote'):
          return 'Solar and Battery proposal';
        case location.includes('addDetails'):
          return 'Confirm your details';
        case location.includes('jobDetails'):
          return 'Job Information';
        case location.includes('addAddress'):
          return 'Add Address';
        case location.includes('confirmDetails'):
          return 'Quote Details';
        default:
          return 'Middy’s Electrical';
      }
    })()
  );
};
