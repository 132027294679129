export const LABELS = {
  formHeading: 'Home service enquiry form',
  formSubheading: 'Your details',
  heading: 'Contact us',

  smallheading: "Middy's home services",
  descriptionLine1:
    "Middy's connects you to a team of licensed electricians, who are also our valued customers. Whether you need electrical services, or home automation solutions, our licensed electricians are here to help!",
  descriptionLine2: 'Reach out to us today to speak with a specialist and get started.',
};
