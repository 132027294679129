import React, { FC } from 'react';
import Header from '../../components/header/Header';
import ContentContainer from '../../components/containers/ContentContainer';
import RegisterInterestForm from '../registerInterestForm/RegisterInterestForm';
import { useStyles } from './ContactUsStyles';
import { Grid, Typography, Box, useMediaQuery, Theme } from '@material-ui/core';
import { ListBox } from '../../components/listBox/ListBox';
import { LABELS } from './ContactUsConstants';
import contactUs from '../../assets/contact-us.png';
import { EmailOutlined } from '@material-ui/icons';
import PageContainer from '../../components/containers/PageContainer';
import CrumbNav from '../../components/crumbNav/CrumbNav';

interface ContactUsProps {}

const ContactUs: FC<ContactUsProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  return (
    <>
      <Header />
      <PageContainer>
        <ContentContainer>
          <CrumbNav steps={[{ label: 'Contact Us' }]} />
          <Grid container spacing={4} style={{ justifyContent: 'space-between' }}>
            <Grid item md={5} xs={12}>
              <ListBox spacing={5}>
                <Typography color="secondary" variant="h1" className="title">
                  {LABELS.heading}
                </Typography>
                <Typography variant="h4" color="textSecondary">
                  {LABELS.descriptionLine1}
                </Typography>
                <Typography variant="h4" color="textSecondary">
                  {LABELS.descriptionLine2}
                </Typography>
              </ListBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <img src={contactUs} />
            </Grid>
          </Grid>
        </ContentContainer>

        <div className={classes.formBackgroundWrapper}>
          <ContentContainer>
            <Box px={isSmallScreen ? 2 : 10}>
              <Box className={classes.formContainer}>
                <Box mb={4}>
                  <Typography color="secondary" variant="h2" className="title">
                    {LABELS.formHeading}
                  </Typography>
                </Box>
                <Typography color="secondary" variant="h3" className="title">
                  {LABELS.formSubheading}
                </Typography>

                <Box mt={3}>
                  <RegisterInterestForm type="contactUs" />
                </Box>
              </Box>
            </Box>
          </ContentContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default ContactUs;
