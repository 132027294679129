import { QueryClient, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

// Query key will always have 2 sections only, second value being the fetcher params
export type QueryKey = (string | {})[];
type FetchQueryPromiseFn<T, P> = (params: P) => Promise<T>;
type MutationQueryPromiseFn<T, P = T> = (params: P) => Promise<T>;

export const useFetchQuery = <T, P extends {}, U = T>(
  key: QueryKey,
  fetcher: FetchQueryPromiseFn<T, P>,
  config: UseQueryOptions<T, Error, U, QueryKey> = {},
) => {
  const response = useQuery<T, Error, U, QueryKey>(key, () => fetcher(key[1]! as P), {
    ...config,
  });
  return response;
};

export const useInvalidateQuery = (keys: Array<QueryKey | undefined>) => {
  return {
    invalidate: async () => {
      await Promise.all(keys.filter(Boolean).map((key) => queryClient.invalidateQueries(key)));
    },
  };
};

// key: key for the react query
// func: a function that returns service call
// updater: a function that returns updated value for optimistic rendering, for example in case of
// delete we can return new list with deleted value

export const useMutationQuery = <T, P, R = T>({
  key,
  func,
  updater,
  doNotWaitForInvalidation,
  additionalInvalidationKeys,
  doNotInvalidate,
}: {
  func: MutationQueryPromiseFn<T, P>;
  key?: QueryKey;
  updater?: (oldData: R, params: P) => R;
  // Set this last param to false in cases where we do not wish to show loading state
  // until the background data fetch is done
  doNotWaitForInvalidation?: boolean;
  additionalInvalidationKeys?: QueryKey[];
  doNotInvalidate?: boolean;
}) => {
  const { invalidate } = useInvalidateQuery([...(additionalInvalidationKeys || []), key]);
  return useMutation<T, Error, P>(func, {
    onMutate: async (request) => {
      if (!key) return {};
      await queryClient.cancelQueries(key);

      const previousData = queryClient.getQueryData(key);
      queryClient.setQueryData<R>(key, (oldData) => {
        return updater ? updater(oldData!, request) : oldData;
      });

      return previousData;
    },

    // previousData: this is the value returned from onMutate, we are returning old value (value before call)
    // so that we can reset the value that has been optimistically rendered
    onError: (_err, _, previousData) => {
      if (!key) return;
      queryClient.setQueryData(key, previousData);
    },
    onSuccess: () => {
      if (doNotInvalidate || !(key || additionalInvalidationKeys)) {
        return true;
      }
      if (doNotWaitForInvalidation) {
        invalidate().catch(() => {});
        return true;
      }

      return invalidate();
    },
  });
};
