import { makeStyles } from '@material-ui/core';
import { theme } from '../../../theme/Theme';

export const useStyles = makeStyles({
  select: {
    borderRadius: 0,
    background: theme.colors.inputBackground,
    padding: 12,
    fontSize: theme.font.m,
    height: 22,
  },
});
