import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  selectInputStyle: {
    width: '100%',
    marginTop: theme.units.margin * 4,
    marginBottom: theme.units.margin * 4,
    height: 40,
    backgroundColor: theme.colors.inputBackground,
  },
  selectInput: {
    backgroundColor: theme.colors.inputBackground,
    display: 'flex',
    alignItems: 'center',
    height: 40,
    paddingLeft: theme.units.padding * 3,
    paddingRight: theme.units.padding * 3,
    color: theme.colors.inputText,
  },
  selectComStyle: {
    paddingLeft: theme.units.padding * 3,
    '&:focus': {
      backgroundColor: `${theme.colors.inputBackground}!important`,
    },
  },
  iconStyle: {
    marginRight: 8,
  },
  errorTextStyle: {
    fontSize: theme.font.xs,
    fontFamily: theme.font.family,
    color: theme.colors.error,
    marginTop: theme.units.margin,
  },
  errorSelectInput: {
    backgroundColor: theme.colors.inputBackground,
    display: 'flex',
    alignItems: 'center',
    height: 48,
    paddingLeft: theme.units.padding * 3,
    paddingRight: theme.units.padding * 3,
    color: theme.colors.inputText,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.error,
  },
});
