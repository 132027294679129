import { BundlePackage, CustomerInterestsRequest } from '../../models/bundles/Bundles';
import { StoreAction } from '../StoreHelper';

export enum BundlesActionTypes {
  GET_BUNDLES = 'GET_BUNDLES',
  GET_BUNDLES_REQUEST = 'GET_BUNDLES_REQUEST',
  GET_BUNDLES_SUCCESS = 'GET_BUNDLES_SUCCESS',
  GET_BUNDLES_ERROR = 'GET_BUNDLES_ERROR',

  UPDATE_SELECTED_BUNDLE = 'UPDATE_SELECTED_BUNDLE',

  POST_MAIL_SIGNUP = 'POST_MAIL_SIGNUP',
  POST_MAIL_SIGNUP_REQUEST = 'POST_MAIL_SIGNUP_REQUEST',
  POST_MAIL_SIGNUP_SUCCESS = 'POST_MAIL_SIGNUP_SUCCESS',
  POST_MAIL_SIGNUP_ERROR = 'POST_MAIL_SIGNUP_ERROR',
}

export type BundlesActionPayload =
  | BundlePackage
  | BundlePackage[]
  | CustomerInterestsRequest
  | undefined
  | Error;

export type BundlesAction = StoreAction<BundlesActionTypes, BundlesActionPayload>;

export class BundlesActions {
  public static getBundlesRequest(): BundlesAction {
    return {
      type: BundlesActionTypes.GET_BUNDLES_REQUEST,
      data: null,
    };
  }

  public static getBundlesSuccess(data: BundlePackage[]): BundlesAction {
    return {
      type: BundlesActionTypes.GET_BUNDLES_SUCCESS,
      data,
    };
  }

  public static getBundlesError(data: Error): BundlesAction {
    return {
      type: BundlesActionTypes.GET_BUNDLES_ERROR,
      data,
    };
  }

  public static postMailSignupRequest(data: CustomerInterestsRequest): BundlesAction {
    return {
      type: BundlesActionTypes.POST_MAIL_SIGNUP_REQUEST,
      data,
    };
  }

  public static postMailSignupSuccess(): BundlesAction {
    return {
      type: BundlesActionTypes.POST_MAIL_SIGNUP_SUCCESS,
      data: null,
    };
  }

  public static postMailSignupError(data: Error): BundlesAction {
    return {
      type: BundlesActionTypes.POST_MAIL_SIGNUP_ERROR,
      data,
    };
  }

  public static updateSelectedBundle(data: BundlePackage | undefined): BundlesAction {
    return {
      type: BundlesActionTypes.UPDATE_SELECTED_BUNDLE,
      data,
    };
  }
}
