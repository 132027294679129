import { BundlePackage } from '../models/bundles/Bundles';
import electrician from '../assets/electrical-quote.png';
import solar from '../assets/middysAssets/solar.png';
import trade from '../assets/middys-trade-accelerator.jpg';
import register from '../assets/middys-trade-accelerator.jpg';
import { BundleCardType } from '../components/bundleCard/BundleCardConstants';
import { routes } from '../Routes';
import { AppSettings } from '../AppSettings';

export const bundleListMock: BundlePackage[] = [
  {
    productId: 1,
    skills: [],
    standard: {
      heroImage:
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/hero.jpg',
      additionalImages: [
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/additional1.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/additional2.jpg',
      ],
      title: 'Kitchen Living Solutions',
      description:
        'Imagine living in a kitchen that’s been designed for seamless living. A practical space that doesn’t just fit into your busy life, but enhances it. Where your kitchen can also be your home office, entertaining space, and memory-making place. The kitchen is the heart and hub of your home and when planned right with comfort, convenience and safety at the forefront, it creates a space that connects the people in it today and grows with you well into tomorrow. This carefully curated kitchen package provides the key electrical solutions to enhance the way you live with an abundance of power point and USB charging solutions.',
      inclusions: [
        {
          name: 'LED Downlight',
          description:
            'With an extra-wide trim, integral driver, a low profile and flex and plug, the Clipsal TPDL Series LED Downlights are unobtrusive and easy to install. The lamp life is rated to 30,000 hours. This white surround model has a brightness level of 750lm.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/LEDDownlights.jpg',
          quantity: 3,
        },
        {
          name: 'USB Charger & Smart Shelf',
          description:
            'The new Clipsal Iconic Triple USB Charging Mechanism is a charging station for the 3043HS Horizontal USB Shelf. This USB wall outlet makes charging simple and convenient with three charging ports to let you to charge multiple devices at the same time.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/USBChargerandSmartShelf.jpg',
          quantity: 1,
        },
        {
          name: 'Quad Power Outlet',
          description:
            'The four outlet Quad Switched Power Point is designed for commercial areas where multiple appliances need to be plugged in at once. The 10A, 250V Power Point has an impact rating of IK08, which means they are strong enough to withstand the daily knocks of busy public places.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/QuadPowerOutlet.jpg',
          quantity: 1,
        },
        {
          name: 'Iconic Light Switch',
          description:
            'The Clipsal Iconic Flush Switch is a versatile 3 gang smart light switch assembly with 2-way functionality. This flush-mounted Clipsal 3 gang switch is great for residential use, and ideal for large spaces and staircases.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/IconicLightSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Cooker Switch',
          description:
            'The Clipsal Cooker Switch is a 250V cokker that contains 45A current. Simplt to clean, easily operatable and architecturally designed fascias accentuate a modern appearance.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/CookerSwitch.jpg',
          quantity: 1,
        },
      ],
      video: 'https://youtu.be/U-I-xmrgHTQ',
      extraContent: [
        {
          title: 'Get your home set up for life',
          subtitle: 'With Electric Home Design from Clipsal',
          description:
            "Life happens. It’s unexpected and constantly changing. We are demanding much more from our homes than ever before. Tonight’s dinner discussion might be the morning’s homework hub. An old garage might be a future electric vehicle charging station. When you’re planning a renovation, we often miss a key piece of the puzzle that is part of your everyday, and when planned right, can work seamlessly to make your home living smarter, safer, more stylish and sustainable. This is Electric Home Design. Whether you’re looking for solutions to complement your style, ideas to inspire and guide you, information on safety and creating a smart home, we've got you covered. Discover the possibilities of what Electric Home Design can bring to your renovation.",
          ctaText: 'Discover the possibilities',
          ctaLink: 'https://www.clipsal.com/home-renovation',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/kitchen.png',
        },
      ],
      price: 800,
    },
    upgraded: {
      heroImage:
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/upgraded/hero.jpg',
      additionalImages: [
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/upgraded/additional1.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/upgraded/additional2.jpg',
      ],
      title: 'The kitchen is the heart and hub of the home',
      description:
        'Imagine living in a home that’s seamlessly connected. A home that doesn’t just fit into your busy life, but enhances it. Where your kitchen can also be your home office, entertaining space, and memory-making place. The heart and hub of your home that’s intuitive, smart and adaptable, where the practicial simplicity of home electrical together with smart solutions, creates a space that connects the people in it today and grows with you well into tomorrow. With a Wiser Smart Home, you are in complete control of the light levels so you can have just the right light to meet the needs of your multi-funtional space. With lighting sensors in your pantry, you never need to worry when your hands are full or remember to turn the lights off when you leave - Wiser can take care of it all for you. So whether you are wanting to create moments or integrate voice control for easy commands, your Wiser home is ready to make your kitchen space work seamlessly for you and the way you live.',
      inclusions: [
        {
          name: 'LED Downlight',
          description:
            'With an extra-wide trim, integral driver, a low profile and flex and plug, the Clipsal TPDL Series LED Downlights are unobtrusive and easy to install. The lamp life is rated to 30,000 hours. This white surround model has a brightness level of 750lm.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/LEDDownlights.jpg',
          quantity: 3,
        },
        {
          name: 'USB Charger & Smart Shelf',
          description:
            'The new Clipsal Iconic Triple USB Charging Mechanism is a charging station for the 3043HS Horizontal USB Shelf. This USB wall outlet makes charging simple and convenient with three charging ports to let you to charge multiple devices at the same time.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/USBChargerandSmartShelf.jpg',
          quantity: 1,
        },
        {
          name: 'Quad Power Outlet',
          description:
            'The four outlet Quad Switched Power Point is designed for commercial areas where multiple appliances need to be plugged in at once. The 10A, 250V Power Point has an impact rating of IK08, which means they are strong enough to withstand the daily knocks of busy public places.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/QuadPowerOutlet.jpg',
          quantity: 1,
        },
        {
          name: 'Iconic Light Switch',
          description:
            'The Clipsal Iconic Flush Switch is a versatile 3 gang smart light switch assembly with 2-way functionality. This flush-mounted Clipsal 3 gang switch is great for residential use, and ideal for large spaces and staircases.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/IconicLightSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Cooker Switch',
          description:
            'The Clipsal Cooker Switch is a 250V cokker that contains 45A current. Simplt to clean, easily operatable and architecturally designed fascias accentuate a modern appearance.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/inclusions/CookerSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Hub',
          description:
            'The Wiser Hub enables you to easily manage your home environment. It connects Wiser devices and enables communication between multiple devices and a smartphone via the Wiser app. The hub also enables voice control and allows you to control your home from a remote location.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/upgraded/inclusions/WiserHub.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Dimmer Switch',
          description:
            'For smart device control and configuration with Bluetooth Low Energy technology, the Clipsal Iconic Push Button Wiser Smart Dimmer Switch allows you to set timer and scheduling functions through the Clipsal Wiser App. The mech also allows multiway LED dimmer switch with ControlLink technology.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/upgraded/inclusions/ClipsalDimmerSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Motion Sensor (Pantry)',
          description:
            'The Wiser Motion Smart Sensor detects movement, measures luminance in a room, and reports the status to the Wiser Hub. The threshold of luminance settings and/or detection of movement can be programmed to determine what action to be taken at that moment.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/upgraded/inclusions/MotionSensor.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Smart Switch (Pantry)',
          description:
            'The Iconic Push button Wiser Connected Smart Switch 2 AX offers flexible installation options and is ideal for controlling lighting and exhaust fans. It can be controlled through the push button on the wall, remotely using the Wiser by SE app or voice command. With default Zigbee mode it can connect to the Wiser Hub for full home control and automation functions.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/upgraded/inclusions/WiserSmartSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Connected Power Point',
          description:
            'Not just a Double Power Point, the Clipsal Iconic Double Connected Smart Power Point gives you app controlled switching with the Wiser Room App. Set schedules and timers for each outlet and enjoy energy savings and security. The 10A, 250V Iconic Connected Power Point can future-proof every smart home.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/upgraded/inclusions/ConnectedPowerPoint.jpg',
          quantity: 1,
        },
      ],
      video: 'https://youtu.be/U-I-xmrgHTQ',
      extraContent: [
        {
          title: 'Get your home set up for life',
          subtitle: 'With Electric Home Design from Clipsal',
          description:
            "Life happens. It’s unexpected and constantly changing. We are demanding much more from our homes than ever before. Tonight’s dinner discussion might be the morning’s homework hub. An old garage might be a future electric vehicle charging station. When you’re planning a renovation, we often miss a key piece of the puzzle that is part of your everyday, and when planned right, can work seamlessly to make your home living smarter, safer, more stylish and sustainable. This is Electric Home Design. Whether you’re looking for solutions to complement your style, ideas to inspire and guide you, information on safety and creating a smart home, we've got you covered. Discover the possibilities of what Electric Home Design can bring to your renovation.",
          ctaText: 'Discover the possibilities',
          ctaLink: 'https://www.clipsal.com/home-renovation',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/kitchen/standard/kitchen.png',
        },
      ],
      price: 900,
    },
    postcodes: [
      '5000',
      '5001',
      '5005',
      '5006',
      '5007',
      '5008',
      '5009',
      '5010',
      '5011',
      '5012',
      '5014',
      '5015',
      '5069',
      '5070',
      '5073',
      '5074',
      '5013',
      '5091',
      '5094',
      '5096',
      '5098',
    ],
  },
  {
    productId: 2,
    skills: [],
    standard: {
      heroImage:
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/hero.jpg',
      additionalImages: [
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/additional1.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/additional2.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/additional3.jpg',
      ],
      title: 'Bathroom Living Solutions',
      description:
        'Bathroom design is constantly evolving. From lush sanctuaries perfect for a relaxing soak, to everyday practical, there are many ways electric home design can optimise and elevate your bathroom style and function. Keep humidity levels at bay without compromising on the gentle warmth of the heat lamps with the Clipsal fan/light/heat unit ensuring the temperature of your little oasis is always just right. The icon pictures on your Iconic switch will always ensure that you and yours guests know exactly which switch does what. Get your bathroom set for seamless living with this Bathroom package.',
      inclusions: [
        {
          name: 'Fan/Light/Heater Unit',
          description:
            'With an attractive modern design, the Clipsal Airflow 3-in-1 unit is ideal for bathrooms with restricted ceiling cavities above 230mm. Standard features include 4 x 275W radiant heat lamps, a 100W centre lamp, built-in thermal protection and a powerful centrifugal exhaust fan, which provides superior air movement.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/fan-unit.jpg',
          quantity: 1,
        },
        {
          name: 'Iconic 3 Gang Switch',
          description:
            'The Clipsal Iconic Flush Switch is a versatile 3 gang smart light switch assembly with 2-way functionality. This flush-mounted Clipsal 3 gang switch is great for residential use, and ideal for large spaces and staircases.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/Iconic3GangSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Fan/Light/Heat Pictograms',
          description:
            'Clipsal Iconic is a modern electrical accessories range, based on a slim, sleek, clean design that is easy to customise and blends in perfectly with any environment.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/FanPictogram.jpg',
          quantity: 1,
        },
        {
          name: 'LED Downlights',
          description:
            'With an extra-wide trim, integral driver, a low profile and flex and plug, the Clipsal TPDL Series LED Downlights are unobtrusive and easy to install. The lamp life is rated to 30,000 hours. This white surround model has a brightness level of 750lm.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/LEDdownlight.jpg',
          quantity: 1,
        },
        {
          name: 'Iconic Double Power Point',
          description:
            'The Iconic Double Power Point has a Grid Plate with innovative protective flaps, which help prevent screw access after installation (by a qualified electrician); which means skins can be changed safely at any time.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/IconicDoublePowerPoint.jpg',
          quantity: 1,
        },
      ],
      video: 'https://youtu.be/U-I-xmrgHTQ',
      extraContent: [
        {
          title: 'Get your home set up for life',
          subtitle: 'With Electric Home Design from Clipsal',
          description:
            "Life happens. It’s unexpected and constantly changing. We are demanding much more from our homes than ever before. Tonight’s dinner discussion might be the morning’s homework hub. An old garage might be a future electric vehicle charging station. When you’re planning a renovation, we often miss a key piece of the puzzle that is part of your everyday, and when planned right, can work seamlessly to make your home living smarter, safer, more stylish and sustainable. This is Electric Home Design. Whether you’re looking for solutions to complement your style, ideas to inspire and guide you, information on safety and creating a smart home, we've got you covered. Discover the possibilities of what Electric Home Design can bring to your renovation.",
          ctaText: 'Discover the possibilities',
          ctaLink: 'https://www.clipsal.com/home-renovation',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/bathroom.png',
        },
      ],
      price: 500,
    },
    upgraded: {
      heroImage:
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/hero.jpg',
      additionalImages: [
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/additional1.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/additional2.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/additional3.jpg',
      ],
      title: 'Transform your bathroom space - From everyday practical to your personal santuary',
      description:
        'Being one of the busiest and most in-demand rooms in the house, the bathroom needs to seamlessly transition through the beats and routines of your daily family life and demands, while also being a space you can relax and unwind. With lighting that can be customised to suit the time of day and mood, sensors that automatically detect humidity levels, and exhaust fans that turn on and off without you having to lift a finger, you can enjoy a bathroom that pulls together convenience and efficiency in the morning and allows you to relax and unwind into the evening. Your mind can rest easy knowing that you have a connected power point with timer functions and remote control for devices such as hair straighteners so you no longer need to worry if it has been left on.',
      inclusions: [
        {
          name: 'Fan/Light/Heater Unit',
          description:
            'With an attractive modern design, the Clipsal Airflow 3-in-1 unit is ideal for bathrooms with restricted ceiling cavities above 230mm. Standard features include 4 x 275W radiant heat lamps, a 100W centre lamp, built-in thermal protection and a powerful centrifugal exhaust fan, which provides superior air movement.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/fan-unit.jpg',
          quantity: 1,
        },
        {
          name: 'Iconic 3 Gang Switch',
          description:
            'The Clipsal Iconic Flush Switch is a versatile 3 gang smart light switch assembly with 2-way functionality. This flush-mounted Clipsal 3 gang switch is great for residential use, and ideal for large spaces and staircases.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/Iconic3GangSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Fan/Light/Heat Pictograms',
          description:
            'Clipsal Iconic is a modern electrical accessories range, based on a slim, sleek, clean design that is easy to customise and blends in perfectly with any environment.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/FanPictogram.jpg',
          quantity: 1,
        },
        {
          name: 'LED Downlights',
          description:
            'With an extra-wide trim, integral driver, a low profile and flex and plug, the Clipsal TPDL Series LED Downlights are unobtrusive and easy to install. The lamp life is rated to 30,000 hours. This white surround model has a brightness level of 750lm.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/LEDdownlight.jpg',
          quantity: 1,
        },
        {
          name: 'Iconic Double Power Point',
          description:
            'The Iconic Double Power Point has a Grid Plate with innovative protective flaps, which help prevent screw access after installation (by a qualified electrician); which means skins can be changed safely at any time.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/inclusions/IconicDoublePowerPoint.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Hub',
          description:
            'The Wiser Hub enables you to easily manage your home environment. It connects Wiser devices and enables communication between multiple devices and a smartphone via the Wiser app. The hub also enables voice control and allows you to control your home from a remote location.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/inclusions/WiserHub.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Dimmer Switch',
          description:
            'For smart device control and configuration with Bluetooth Low Energy technology, the Clipsal Iconic Push Button Wiser Smart Dimmer Switch allows you to set timer and scheduling functions through the Clipsal Wiser App. The mech also allows multiway LED dimmer switch with ControlLink technology.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/inclusions/ClipsalDimmerSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Smart Switch',
          description:
            'The Iconic Push button Wiser Connected Smart Switch 2 AX offers flexible installation options and is ideal for controlling lighting and exhaust fans. It can be controlled through the push button on the wall, remotely using the Wiser by SE app or voice command. With default Zigbee mode it can connect to the Wiser Hub for full home control and automation functions.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/inclusions/WiserSmartSwitch.jpg',
          quantity: 2,
        },
        {
          name: 'Connected Power Outlet',
          description:
            'Not just a Double Power Point, the Clipsal Iconic Double Connected Smart Power Point gives you app controlled switching with the Wiser Room App. Set schedules and timers for each outlet and enjoy energy savings and security. The 10A, 250V Iconic Connected Power Point can future-proof every smart home.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/inclusions/ConnectedPowerPoint.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Motion Sensor',
          description:
            'The Wiser Motion Smart Sensor detects movement, measures luminance in a room, and reports the status to the Wiser Hub. The threshold of luminance settings and/or detection of movement can be programmed to determine what action to be taken at that moment.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/inclusions/WiserMotionSensor.jpg',
          quantity: 1,
        },
        {
          name: 'Humidity/Temperature Sensor',
          description:
            'The Wiser Temperature/Humidity Smart Sensor helps to maintain a comfortable environment in the home by detecting changes in temperature and humidity and reporting climate data to the Wiser Hub.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/inclusions/HumditySensor.jpg',
          quantity: 1,
        },
        {
          name: 'Water Leakage Sensor',
          description:
            'The Wiser Water Leakage Smart Sensor detects the presence of water and alerts via an alarm and/or notification. When the sensor is connected to the Wiser Hub and detects water on the floor, it sends out a sound alarm and reports the event to the Wiser Hub.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/upgraded/inclusions/WaterLeakageSensor.jpg',
          quantity: 1,
        },
      ],
      video: 'https://youtu.be/U-I-xmrgHTQ',
      extraContent: [
        {
          title: 'Get your home set up for life',
          subtitle: 'With Electric Home Design from Clipsal',
          description:
            "Life happens. It’s unexpected and constantly changing. We are demanding much more from our homes than ever before. Tonight’s dinner discussion might be the morning’s homework hub. An old garage might be a future electric vehicle charging station. When you’re planning a renovation, we often miss a key piece of the puzzle that is part of your everyday, and when planned right, can work seamlessly to make your home living smarter, safer, more stylish and sustainable. This is Electric Home Design. Whether you’re looking for solutions to complement your style, ideas to inspire and guide you, information on safety and creating a smart home, we've got you covered. Discover the possibilities of what Electric Home Design can bring to your renovation.",
          ctaText: 'Discover the possibilities',
          ctaLink: 'https://www.clipsal.com/home-renovation',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/bathroom/standard/bathroom.png',
        },
      ],
      price: 650,
    },
    postcodes: [
      '5000',
      '5001',
      '5005',
      '5006',
      '5007',
      '5008',
      '5009',
      '5010',
      '5011',
      '5012',
      '5014',
      '5015',
      '5069',
      '5070',
      '5073',
      '5074',
      '5013',
      '5091',
      '5094',
      '5096',
      '5098',
    ],
  },
  {
    productId: 3,
    skills: [],
    standard: {
      heroImage:
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/hero.jpg',
      additionalImages: [
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/additional1.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/additional2.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/additional3.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/additional4.jpg',
      ],
      title: 'Outdoor Living Solutions',
      description:
        'Nowadays we are reimagining our outdoor spaces as stylish extensions of our interiors. Our outdoor living areas are becoming multi-functional spaces for dining, relaxing and entertaining. Imagine creating your outdoor like never before space with outdoor lighting solutions which create a sense of grandeur to your home and fans which allow you to make the most of your outdoor space through our Australian summer days and balmy nights.',
      inclusions: [
        {
          name: 'Iconic Outdoor Double Power Point',
          description:
            'When you need space to plug in two appliances outside, the Clipsal Iconic Outdoor Twin Switched Socket Outlet has you covered. Rated 10A, 250V, the range has an IP54 rating with protection against dust and water ingress. It has an matt White finish.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/IconicOutdoorDoublePowerOutlet.jpg',
          quantity: 1,
        },
        {
          name: 'Exterior Lights',
          description:
            'Engineered to provide precise exterior and interior access lighting for residential, retail and light commercial buildings, the Clipsal TPWL Series Wall Lights combine highly efficient LED technology in a stylish durable UV stabilised die cast aluminium body. This TPWL2 model is 12W, 250V and is 239mm long.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/ExteriorLights.jpg',
          quantity: 2,
        },
        {
          name: 'Infrascan Motion Sensor ',
          description:
            'The Clipsal Outdoor Infrascan is the industry benchmark for IP66 outdoor motion sensors. Provided with a robust IP66 rated housing and UV protection, the Infrascan is designed for ongoing performance in harsh conditions. The Infrascan detects movement up to 18m at 110degrees with adjustable sensitivity, lux level and time delay, for control of LED or other lighting loads. Suitable for residential or commercial applications.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/Infrascanmotionsensor.jpg',
          quantity: 1,
        },
        {
          name: 'LED Downlights',
          description:
            'With an extra-wide trim, integral driver, a low profile and flex and plug, the Clipsal TPDL Series LED Downlights are unobtrusive and easy to install. The lamp life is rated to 30,000 hours. This white surround model has a brightness level of 750lm.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/LEDDownlights.jpg',
          quantity: 4,
        },
        {
          name: 'Ceiling Fan',
          description:
            'With 304 quality stainless steel blades, the Clipsal Airflow Stainless Steel Ceiling Sweep Fan is an excellent addition for homes.The fan has a diameter of 1400mm and has three blades for high quality air movement.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/Ceilingfan.jpg',
          quantity: 1,
        },
      ],
      video: 'https://youtu.be/U-I-xmrgHTQ',
      extraContent: [
        {
          title: 'Get your home set up for life',
          subtitle: 'With Electric Home Design from Clipsal',
          description:
            "Life happens. It’s unexpected and constantly changing. We are demanding much more from our homes than ever before. Tonight’s dinner discussion might be the morning’s homework hub. An old garage might be a future electric vehicle charging station. When you’re planning a renovation, we often miss a key piece of the puzzle that is part of your everyday, and when planned right, can work seamlessly to make your home living smarter, safer, more stylish and sustainable. This is Electric Home Design. Whether you’re looking for solutions to complement your style, ideas to inspire and guide you, information on safety and creating a smart home, we've got you covered. Discover the possibilities of what Electric Home Design can bring to your renovation.",
          ctaText: 'Discover the possibilities',
          ctaLink: 'https://www.clipsal.com/home-renovation',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/outdoor.png',
        },
      ],
      price: 950,
    },
    upgraded: {
      heroImage:
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/hero.jpg',
      additionalImages: [
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/additional1.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/additional2.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/additional3.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/additional4.jpg',
      ],
      title: 'Outdoor living like never before',
      description:
        'Imagine creating a seamless connection between your indoor and outdoor living spaces. A Wiser Smart Home is not just for the interior of your home. With complete lighting control you can create moments that transform your backyard into an amazing outdoor living space. Integrating security solutions such as window and door sensors and outdoor security cameras, provide you with peace of mind that you will be alerted when something is not quite right or allow you to keep an eye on what your cheeky pet is up to throughout the day when you are not around.',
      inclusions: [
        {
          name: 'Iconic Outdoor Double Power Point',
          description:
            'When you need space to plug in two appliances outside, the Clipsal Iconic Outdoor Twin Switched Socket Outlet has you covered. Rated 10A, 250V, the range has an IP54 rating with protection against dust and water ingress. It has an matt White finish.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/IconicOutdoorDoublePowerOutlet.jpg',
          quantity: 1,
        },
        {
          name: 'Exterior Lights',
          description:
            'Engineered to provide precise exterior and interior access lighting for residential, retail and light commercial buildings, the Clipsal TPWL Series Wall Lights combine highly efficient LED technology in a stylish durable UV stabilised die cast aluminium body. This TPWL2 model is 12W, 250V and is 239mm long.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/ExteriorLights.jpg',
          quantity: 2,
        },
        {
          name: 'Infrascan Motion Sensor ',
          description:
            'The Clipsal Outdoor Infrascan is the industry benchmark for IP66 outdoor motion sensors. Provided with a robust IP66 rated housing and UV protection, the Infrascan is designed for ongoing performance in harsh conditions. The Infrascan detects movement up to 18m at 110degrees with adjustable sensitivity, lux level and time delay, for control of LED or other lighting loads. Suitable for residential or commercial applications.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/Infrascanmotionsensor.jpg',
          quantity: 1,
        },
        {
          name: 'LED Downlights',
          description:
            'With an extra-wide trim, integral driver, a low profile and flex and plug, the Clipsal TPDL Series LED Downlights are unobtrusive and easy to install. The lamp life is rated to 30,000 hours. This white surround model has a brightness level of 750lm.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/LEDDownlights.jpg',
          quantity: 4,
        },
        {
          name: 'Ceiling Fan',
          description:
            'With 304 quality stainless steel blades, the Clipsal Airflow Stainless Steel Ceiling Sweep Fan is an excellent addition for homes.The fan has a diameter of 1400mm and has three blades for high quality air movement.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/inclusions/Ceilingfan.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Hub',
          description:
            'The Wiser Hub enables you to easily manage your home environment. It connects Wiser devices and enables communication between multiple devices and a smartphone via the Wiser app. The hub also enables voice control and allows you to control your home from a remote location.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/inclusions/WiserHub.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Dimmer Switch',
          description:
            'For smart device control and configuration with Bluetooth Low Energy technology, the Clipsal Iconic Push Button Wiser Smart Dimmer Switch allows you to set timer and scheduling functions through the Clipsal Wiser App. The mech also allows multiway LED dimmer switch with ControlLink technology.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/inclusions/ClipsalDimmerSwitch.jpg',
          quantity: 1,
        },
        {
          name: 'Iconic Outdoor Connected Outlet',
          description:
            'Perfect for use with equipment you only want to run at specific times, the Clipsal Iconic Outdoor Single Switched Socket with timer switch is rated 10A, 250V. Using Bluetooth Low Energy (BLE), configure your settings using the Wiser Room app from your phone or tablet. It can also be upgraded for remote access. The socket outlet has an matt White finish.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/inclusions/IconicOutdoorConnectedOutlet.jpg',
          quantity: 1,
        },
        {
          name: 'Window/Door Sensor',
          description:
            'The Wiser Window/Door Smart Sensor detects opened and closed windows and doors, and reports the status to theWiser Hub. When the sensor is connected to the Wiser Hub and the window/door is opened or closed, the sensor reports the change to the Wiser Hub.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/inclusions/WindowDoorSensor.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Outdoor Security Camera',
          description:
            'The Wiser Outdoor IP Camera provides day and night HD video recording, with wide angle view, motion detection notification alerts, intercom and event or continuous recording. Simple and intuitive control via the Wiser by SE App from anywhere. Use standalone or integrate into the Wiser smart home system and Automations. With adjustable camera position and IP56 protection, it can be installed on a wall or ceiling/eave to suit any home',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/upgraded/inclusions/WiserOutdoorSecurityCamera.jpg',
          quantity: 1,
        },
      ],
      video: 'https://youtu.be/U-I-xmrgHTQ',
      extraContent: [
        {
          title: 'Get your home set up for life',
          subtitle: 'With Electric Home Design from Clipsal',
          description:
            "Life happens. It’s unexpected and constantly changing. We are demanding much more from our homes than ever before. Tonight’s dinner discussion might be the morning’s homework hub. An old garage might be a future electric vehicle charging station. When you’re planning a renovation, we often miss a key piece of the puzzle that is part of your everyday, and when planned right, can work seamlessly to make your home living smarter, safer, more stylish and sustainable. This is Electric Home Design. Whether you’re looking for solutions to complement your style, ideas to inspire and guide you, information on safety and creating a smart home, we've got you covered. Discover the possibilities of what Electric Home Design can bring to your renovation.",
          ctaText: 'Discover the possibilities',
          ctaLink: 'https://www.clipsal.com/home-renovation',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/outdoor/standard/outdoor.png',
        },
      ],
      price: 1200,
    },
    postcodes: [
      '5000',
      '5001',
      '5005',
      '5006',
      '5007',
      '5008',
      '5009',
      '5010',
      '5011',
      '5012',
      '5014',
      '5015',
      '5069',
      '5070',
      '5073',
      '5074',
      '5013',
      '5091',
      '5094',
      '5096',
      '5098',
    ],
  },
  {
    productId: 4,
    skills: [],
    standard: {
      heroImage:
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/hero.jpg',
      additionalImages: [
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/additional1.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/additional2.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/additional3.jpg',
      ],
      title: 'Smart Home Solutions',
      description:
        'Imagine being in control of every aspect of your home – from the temperature to the lighting to your energy use – with just a few taps on your smartphone, or a few words to your voice assistant. A smart home is a home that works for you and takes care of things so you don’t have to think of them yourself. It enables you to concentrate on the important things; spending time with your family and enjoying life. With this Clipsal Wiser package you are taking your first step towards a simple yet powerful smart home solution that can grow to meet your changing needs.',
      inclusions: [
        {
          name: 'Wiser Hub',
          description:
            'The Wiser Hub enables you to easily manage your home environment. It connects Wiser devices and enables communication between multiple devices and a smartphone via the Wiser app. The hub also enables voice control and allows you to control your home from a remote location.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/inclusions/WiserHub.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Dimmer Switch',
          description:
            'For smart device control and configuration with Bluetooth Low Energy technology, the Clipsal Iconic Push Button Wiser Smart Dimmer Switch allows you to set timer and scheduling functions through the Clipsal Wiser App. The mech also allows multiway LED dimmer switch with ControlLink technology.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/inclusions/ClipsalDimmerSwitch.jpg',
          quantity: 3,
        },
        {
          name: 'Wiser Motion Sensor',
          description:
            'The Wiser Motion Smart Sensor detects movement, measures luminance in a room, and reports the status to the Wiser Hub. The threshold of luminance settings and/or detection of movement can be programmed to determine what action to be taken at that moment.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/inclusions/WiserMotionSensor.jpg',
          quantity: 2,
        },
        {
          name: 'Connected Power Point',
          description:
            'Not just a Double Power Point, the Clipsal Iconic Double Connected Smart Power Point gives you app controlled switching with the Wiser Room App. Set schedules and timers for each outlet and enjoy energy savings and security. The 10A, 250V Iconic Connected Power Point can future-proof every smart home.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/inclusions/ConnectedPowerPoint.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Outdoor Camera',
          description:
            'The Wiser Outdoor IP Camera provides day and night HD video recording, with wide angle view, motion detection notification alerts, intercom and event or continuous recording. Simple and intuitive control via the Wiser by SE App from anywhere. Use standalone or integrate into the Wiser smart home system and Automations. With adjustable camera position and IP56 protection, it can be installed on a wall or ceiling/eave to suit any home',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/inclusions/WiserOutdoorSecurityCamera.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Smart Switch',
          description:
            'The Iconic Push button Wiser Connected Smart Switch 2 AX offers flexible installation options and is ideal for controlling lighting and exhaust fans. It can be controlled through the push button on the wall, remotely using the Wiser by SE app or voice command. With default Zigbee mode it can connect to the Wiser Hub for full home control and automation functions.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/inclusions/WiserSmartSwitch.jpg',
          quantity: 4,
        },
      ],
      video: 'https://youtu.be/U-I-xmrgHTQ',
      extraContent: [
        {
          title: 'Get your home set up for life',
          subtitle: 'With Electric Home Design from Clipsal',
          description:
            "Life happens. It’s unexpected and constantly changing. We are demanding much more from our homes than ever before. Tonight’s dinner discussion might be the morning’s homework hub. An old garage might be a future electric vehicle charging station. When you’re planning a renovation, we often miss a key piece of the puzzle that is part of your everyday, and when planned right, can work seamlessly to make your home living smarter, safer, more stylish and sustainable. This is Electric Home Design. Whether you’re looking for solutions to complement your style, ideas to inspire and guide you, information on safety and creating a smart home, we've got you covered. Discover the possibilities of what Electric Home Design can bring to your renovation.",
          ctaText: 'Discover the possibilities',
          ctaLink: 'https://www.clipsal.com/home-renovation',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/smartHome.png',
        },
      ],
      price: 650,
    },
    upgraded: {
      heroImage:
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/hero.jpg',
      additionalImages: [
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/additional1.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/additional2.jpg',
        'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/additional3.jpg',
      ],
      title: 'Smart Home Solutions',
      description:
        "Taking charge of your home and taking the complications out of your daily life couldn’t be easier with a Clipsal Wiser Smart Home. Imagine being able to manage your lighting, blinds, temperature control, TV, appliances and more via an app, wherever you are. From blinds that open to greet the sun, aircon that comes on before the day's heat, and lights that dim as you drift off, a Wiser Smart Home adjusts to your lifestyle. You are in complete control of how your home responds to the way you live and can look for ways that your home can enrich and enhance the way you and your family live in your home. Create and schedule your own ‘Moments’, like movie nights or family dinners, where settings such as the lighting and temperature change to just how you like them. With simple smart indoor and outdoor IP cameras, you can monitor the inside and outside of your home from anywhere at anytime all from your Wiser by SE app. You can integrate sensors to monitor security parameters including door/window opening, motion and water leakage detection; or program your lights to turn on at set times. Imagine all this control over your comfort, peace of mind and energy efficiency, right at your fingertips. With a Clipsal Wiser Smart Home, you can.",
      inclusions: [
        {
          name: 'Wiser Hub',
          description:
            'The Wiser Hub enables you to easily manage your home environment. It connects Wiser devices and enables communication between multiple devices and a smartphone via the Wiser app. The hub also enables voice control and allows you to control your home from a remote location.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/WiserHub.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Dimmer Switch',
          description:
            'For smart device control and configuration with Bluetooth Low Energy technology, the Clipsal Iconic Push Button Wiser Smart Dimmer Switch allows you to set timer and scheduling functions through the Clipsal Wiser App. The mech also allows multiway LED dimmer switch with ControlLink technology.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/ClipsalDimmerSwitch.jpg',
          quantity: 7,
        },
        {
          name: 'Wiser Motion',
          description:
            'The Wiser Motion Smart Sensor detects movement, measures luminance in a room, and reports the status to the Wiser Hub. The threshold of luminance settings and/or detection of movement can be programmed to determine what action to be taken at that moment.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/WiserMotionSensor.jpg',
          quantity: 4,
        },
        {
          name: 'Wiser Blind Control',
          description:
            'The Wiser Micro Module Smart Blind Controller provides smart control of 240V twin active blinds and shutter motors. . It transforms a conventional double push-button switch into a connected device you can control from the switch or through the Wiser by SE app.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/blindcontrol.jpg',
          quantity: 3,
        },
        {
          name: 'Connected Power Point',
          description:
            'Not just a Double Power Point, the Clipsal Iconic Double Connected Smart Power Point gives you app controlled switching with the Wiser Room App. Set schedules and timers for each outlet and enjoy energy savings and security. The 10A, 250V Iconic Connected Power Point can future-proof every smart home.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/ConnectedPowerPoint.jpg',
          quantity: 2,
        },
        {
          name: 'Wiser Outdoor Camera',
          description:
            'The Wiser Outdoor IP Camera provides day and night HD video recording, with wide angle view, motion detection notification alerts, intercom and event or continuous recording. Simple and intuitive control via the Wiser by SE App from anywhere. Use standalone or integrate into the Wiser smart home system and Automations. With adjustable camera position and IP56 protection, it can be installed on a wall or ceiling/eave to suit any home',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/WiserOutdoorSecurityCamera.jpg',
          quantity: 2,
        },
        {
          name: 'Wiser Indoor Camera',
          description:
            'The Wiser Indoor IP Camera provides day and night HD video recording, with 340° panning view, motion detection notification alerts, intercom and event or continuous recording. Simple and intuitive control via the Wiser by SE App from anywhere.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/IndoorCamera.jpg',
          quantity: 1,
        },
        {
          name: 'Wiser Smart Switch',
          description:
            'The Iconic Push button Wiser Connected Smart Switch 2 AX offers flexible installation options and is ideal for controlling lighting and exhaust fans. It can be controlled through the push button on the wall, remotely using the Wiser by SE app or voice command. With default Zigbee mode it can connect to the Wiser Hub for full home control and automation functions.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/WiserSmartSwitch.jpg',
          quantity: 4,
        },
        {
          name: 'Humidity/Temperature Sensor',
          description:
            'The Wiser Temperature/Humidity Smart Sensor helps to maintain a comfortable environment in the home by detecting changes in temperature and humidity and reporting climate data to the Wiser Hub.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/HumditySensor.jpg',
          quantity: 2,
        },
        {
          name: 'IR Converter',
          description:
            'The Wiser IR Converter controls TVs, split air conditioners and HiFi systems via the Wiser by SE app using infrared (IR) technology. It can be mounted on the ceiling or placed on a table top.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/IRConverter.jpg',
          quantity: 1,
        },
        {
          name: 'Water Leakage Sensor',
          description:
            'The Wiser Water Leakage Smart Sensor detects the presence of water and alerts via an alarm and/or notification. When the sensor is connected to the Wiser Hub and detects water on the floor, it sends out a sound alarm and reports the event to the Wiser Hub.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/WaterLeakageSensor.jpg',
          quantity: 1,
        },
        {
          name: 'Window/Door Sensor',
          description:
            'The Wiser Window/Door Smart Sensor detects opened and closed windows and doors, and reports the status to theWiser Hub. When the sensor is connected to the Wiser Hub and the window/door is opened or closed, the sensor reports the change to the Wiser Hub.',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/upgraded/inclusions/DoorSensor.jpg',
          quantity: 3,
        },
      ],
      video: 'https://youtu.be/U-I-xmrgHTQ',
      extraContent: [
        {
          title: 'Get your home set up for life',
          subtitle: 'With Electric Home Design from Clipsal',
          description:
            "Life happens. It’s unexpected and constantly changing. We are demanding much more from our homes than ever before. Tonight’s dinner discussion might be the morning’s homework hub. An old garage might be a future electric vehicle charging station. When you’re planning a renovation, we often miss a key piece of the puzzle that is part of your everyday, and when planned right, can work seamlessly to make your home living smarter, safer, more stylish and sustainable. This is Electric Home Design. Whether you’re looking for solutions to complement your style, ideas to inspire and guide you, information on safety and creating a smart home, we've got you covered. Discover the possibilities of what Electric Home Design can bring to your renovation.",
          ctaText: 'Discover the possibilities',
          ctaLink: 'https://www.clipsal.com/home-renovation',
          image:
            'https://uat-private-works-assets.s3.amazonaws.com/middys/packages/smartHome/standard/smartHome.png',
        },
      ],
      price: 700,
    },
    postcodes: [
      '5000',
      '5001',
      '5005',
      '5006',
      '5007',
      '5008',
      '5009',
      '5010',
      '5011',
      '5012',
      '5014',
      '5015',
      '5069',
      '5070',
      '5073',
      '5074',
      '5013',
      '5091',
      '5094',
      '5096',
      '5098',
    ],
  },
];

export const mockDataServices: BundleCardType[] = [
  {
    id: 1,
    image: electrician,
    category: {
      title: 'Electrical Services for Homeowner',
      image: electrician,
    },
    title: 'Book an Electrician',
    description:
      'Find a local, licensed electrician to help with common repairs, and maintenance around your home',
    quote: 'Get a Free Quote',
    location: routes.electricianQuote,
    skills: ['ELECTRICIAN_MIDDYS'],
    postcodes: [
      '5000',
      '5016',
      '5033',
      '5046',
      '5066',
      '5083',
      '5095',
      '5114',
      '5133',
      '5152',
      '5164',
      '5202',
      '5235',
      '5253',
      '5372',
      '5005',
      '5017',
      '5034',
      '5047',
      '5067',
      '5084',
      '5096',
      '5115',
      '5134',
      '5153',
      '5165',
      '5203',
      '5236',
      '5254',
      '5373',
      '5006',
      '5018',
      '5035',
      '5048',
      '5068',
      '5085',
      '5097',
      '5116',
      '5136',
      '5154',
      '5166',
      '5204',
      '5237',
      '5255',
      '5400',
      '5007',
      '5019',
      '5037',
      '5049',
      '5069',
      '5086',
      '5098',
      '5117',
      '5137',
      '5155',
      '5167',
      '5210',
      '5240',
      '5256',
      '5410',
      '5008',
      '5020',
      '5038',
      '5050',
      '5070',
      '5087',
      '5106',
      '5118',
      '5138',
      '5156',
      '5168',
      '5211',
      '5241',
      '5350',
      '5460',
      '5009',
      '5021',
      '5039',
      '5051',
      '5072',
      '5088',
      '5107',
      '5120',
      '5139',
      '5157',
      '5169',
      '5212',
      '5242',
      '5351',
      '5501',
      '5010',
      '5022',
      '5040',
      '5052',
      '5073',
      '5089',
      '5108',
      '5121',
      '5140',
      '5158',
      '5170',
      '5213',
      '5243',
      '5352',
      '5502',
      '5011',
      '5023',
      '5041',
      '5061',
      '5074',
      '5090',
      '5109',
      '5125',
      '5141',
      '5159',
      '5171',
      '5214',
      '5244',
      '5353',
      '5950',
      '5012',
      '5024',
      '5042',
      '5062',
      '5075',
      '5091',
      '5110',
      '5126',
      '5142',
      '5160',
      '5172',
      '5231',
      '5245',
      '5355',
      '5960',
      '5013',
      '5025',
      '5043',
      '5063',
      '5076',
      '5092',
      '5111',
      '5127',
      '5144',
      '5161',
      '5173',
      '5232',
      '5250',
      '5356',
      '5014',
      '5031',
      '5044',
      '5064',
      '5081',
      '5093',
      '5112',
      '5131',
      '5150',
      '5162',
      '5174',
      '5233',
      '5251',
      '5360',
      '5015',
      '5032',
      '5045',
      '5065',
      '5082',
      '5094',
      '5113',
      '5132',
      '5151',
      '5163',
      '5201',
      '5234',
      '5252',
      '5371',
    ],
    actionLabel: 'View Details',
  },
  {
    id: 2,
    image: solar,
    title: 'Trades register here',
    category: {
      title: 'For Electrical Trades',
      image: trade,
    },
    description:
      "Get exclusive access to leads and grow your business via our Middy's Home Services Program",
    quote: '',
    location: AppSettings.server.tradesUIURL,
    skills: [],
    postcodes: [],
    actionLabel: 'Register Now',
  },
];

export const mockOffer = {
  image: register,
  title: 'Trades, Register Here',
  description:
    "Get exclusive access to leads and grow your business via our Middy's Home Services Program",
  button: 'Register Now',
  location: routes.electricianQuote,
};
