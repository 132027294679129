import { makeStyles } from '@material-ui/core';
import { theme } from '../../../theme/Theme';
import { SIZES } from '../../../Constants';

export const useStyles = makeStyles({
  container: {
    display: 'grid',
    columnGap: `${theme.units.margin * 2}px`,
    border: `2px solid ${theme.colors.borderColor}`,
    borderRadius: `0px 8px 8px 0px`,
    background: theme.colors.lightGrey,
    gridTemplateColumns: '1.5fr 2fr',
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 2fr',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  descContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.units.padding * 4,
    rowGap: theme.units.margin * 4,
    flexDirection: 'column',
    alignItems: 'space-between',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      padding: `${theme.units.padding * 4}px ${theme.units.padding * 1}px`,
    },
  },
  image: {
    minHeight: '150px',
    width: '100%',
    objectFit: 'cover',
    height: '100%',
    objectPosition: 'center center',
  },
  description: {
    fontSize: theme.font.s,
  },
});
