import React, { FC, useEffect, useState, useMemo } from 'react';
import HowItWorksSectionLoading from './HowItWorksSectionLoading';
import { useStyles } from './HowItWorksSectionStyles';

interface HowItWorksSectionProps {
  title: string;
  steps: { title: string; text: string }[];
  loading?: boolean;
}

const HowItWorksSection: FC<HowItWorksSectionProps> = ({ title, steps, loading }) => {
  const styles = useStyles();
  return (
    <>
      {loading ? (
        <HowItWorksSectionLoading />
      ) : (
        <div className={styles.howItWorksContainer}>
          <div className={styles.howItWorksTitle}>{title}</div>
          <div className={styles.howItWorksSteps}>
            {steps.map((step, idx) => (
              <div className={styles.howItWorksStep}>
                <div className={styles.step}>{idx + 1}</div>
                <div className={styles.stepTitle}>{step.title}</div>
                <div className={styles.stepText}>{step.text}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default HowItWorksSection;
