import { Theme, styled } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { theme } from '../../theme/Theme';

interface MiddysLinkProps {
  pink?: boolean;
  underlined?: boolean;
}

export default styled(Link)<Theme, MiddysLinkProps>(({ pink, underlined }) => ({
  color: pink ? theme.colors.secondary : 'black',
  '&:hover': {
    color: theme.colors.secondary,
  },
  textDecoration: underlined ? `underline ${theme.colors.secondary}` : 'none',
}));
