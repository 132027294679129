import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './MailingListModalStyles';
import { useFormik } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { INTERESTED_OPTIONS, LABELS, validationSchema } from './MailingListModalConstants';
import emailSignup from '../../assets/middysAssets/emailSignup.jpg';
import completeIcon from '../../assets/check-circle.png';
import { UserDetails } from '../../models/auth/User';
import AddDetailsInput from '../../containers/addDetails/components/AddDetailsInput';
import SelectComponent from '../select/SelectComponent';
import { CustomerInterestsRequest } from '../../models/bundles/Bundles';
import { BundlesActions, BundlesActionTypes } from '../../store/actions/BundlesActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import CircularProgress from '@material-ui/core/CircularProgress';

interface MailingListModalProps extends RouteComponentProps {
  userDetails: UserDetails | undefined;
  postcode: string;
  registeredMailingListSuccess: boolean;
  loadingMailSignup: boolean;
  postMailSignupRequest: (data: CustomerInterestsRequest) => void;
  closeModal: () => void;
}

const MailingListModal: FC<MailingListModalProps> = ({
  userDetails,
  postcode,
  registeredMailingListSuccess,
  loadingMailSignup,
  postMailSignupRequest,
  closeModal,
}) => {
  const styles = useStyles();
  const formik = useFormik({
    initialValues: {
      firstName: userDetails ? userDetails.firstName : '',
      lastName: userDetails ? userDetails.lastName : '',
      email: userDetails ? userDetails.email : '',
      postcode,
      interested: '',
    },
    onSubmit: (values) => {
      const request = {
        privateName: 'MIDDYS',
        name: `${values.firstName} ${values.lastName}`,
        email: values.email,
        postcode,
        packages: [values.interested],
      };
      postMailSignupRequest(request);
    },
    validationSchema,
  });

  return (
    <div className={styles.modal}>
      <div className={styles.modalImageContainer}>
        <img className={styles.modalImage} src={emailSignup} alt="image" />
      </div>
      <div className={styles.modalContent}>
        {registeredMailingListSuccess && (
          <img src={completeIcon} className={styles.iconStyle} alt="complete" />
        )}
        <div className={styles.modalTitle}>
          {registeredMailingListSuccess ? LABELS.THANK_YOU : LABELS.TITLE}
        </div>
        {!registeredMailingListSuccess && (
          <div className={styles.formStyle}>
            <AddDetailsInput
              placeholder={LABELS.ENTER_NAME}
              key={LABELS.FIRST_NAME}
              value={formik.values.firstName}
              setValue={formik.handleChange('firstName')}
              onBlur={() => formik.setFieldTouched('firstName')}
              touched={formik.touched.firstName}
              error={formik.errors.firstName}
              title={LABELS.FIRST_NAME}
              mandatory
            />
            <AddDetailsInput
              placeholder={LABELS.ENTER_NAME}
              key={LABELS.LAST_NAME}
              value={formik.values.lastName}
              setValue={formik.handleChange('lastName')}
              onBlur={() => formik.setFieldTouched('lastName')}
              touched={formik.touched.lastName}
              error={formik.errors.lastName}
              title={LABELS.LAST_NAME}
              mandatory
            />
            <AddDetailsInput
              placeholder={LABELS.EMAIL}
              key={LABELS.EMAIL}
              value={formik.values.email}
              setValue={formik.handleChange('email')}
              onBlur={() => formik.setFieldTouched('email')}
              touched={formik.touched.email}
              error={formik.errors.email}
              title={LABELS.EMAIL}
              mandatory
            />
            <div className={styles.titleContainer}>
              <div className={styles.titleStyle}>{LABELS.INTERESTED}</div>
              <div className={styles.mandatory}>*</div>
            </div>
            <SelectComponent
              value={formik.values.interested}
              setValue={formik.handleChange('interested')}
              options={INTERESTED_OPTIONS}
              error={
                formik.touched.email && !!formik.errors.interested
                  ? formik.errors.interested
                  : undefined
              }
            />
          </div>
        )}
        <div
          className={styles.addressButton}
          onClick={() => {
            if (registeredMailingListSuccess) {
              closeModal();
            } else {
              formik.handleSubmit();
            }
          }}
        >
          {loadingMailSignup ? (
            <CircularProgress style={{ color: 'white' }} size={20} />
          ) : (
            <>{registeredMailingListSuccess ? LABELS.CLOSE : LABELS.BUTTON}</>
          )}
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([BundlesActionTypes.POST_MAIL_SIGNUP]);

const mapStateToProps = (state: ApplicationState) => ({
  userDetails: state.bookingState.userDetails,
  registeredMailingListSuccess: state.bundlesState.registeredMailingListSuccess,
  loadingMailSignup: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postMailSignupRequest: (data: CustomerInterestsRequest) =>
    dispatch(BundlesActions.postMailSignupRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MailingListModal));
