import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './ButtonStyles';

interface ButtonProps {
  secondary?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  handlePress: () => void;
}

const Button: FC<ButtonProps> = ({ secondary, disabled, children, handlePress }) => {
  const styles = useStyles();
  return (
    <div
      className={
        disabled ? styles.disabled : secondary ? styles.secondaryButton : styles.primaryButton
      }
      onClick={() => {
        if (!disabled) {
          handlePress();
        }
      }}
    >
      {children}
    </div>
  );
};

export default Button;
