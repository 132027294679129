import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducer';
import { useStyles } from './AddSmartUpgradeStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import upgradeBG from '../../../../assets/middysAssets/starter.png';
import plus from '../../../../assets/plus.png';
import deleteIcon from '../../../../assets/delete.png';
import { LABELS } from './AddSmartUpgradeConstants';
import { BundlePackage } from '../../../../models/bundles/Bundles';
import { getCostDifference } from './AddSmartUpgradeUtils';
import { OfferType } from '../../../../models/common/OfferType';
import { routes } from '../../../../Routes';
import { BundlesActions } from '../../../../store/actions/BundlesActions';

interface AddSmartUpgradeProps extends RouteComponentProps {
  offerType: OfferType;
  selectedBundle: BundlePackage | undefined;
  updateSelectedBundle: (data: BundlePackage) => void;
}

const AddSmartUpgrade: FC<AddSmartUpgradeProps> = ({
  offerType,
  selectedBundle,
  updateSelectedBundle,
}) => {
  const styles = useStyles();
  const costDifference = getCostDifference(selectedBundle);
  const shouldHide = offerType !== OfferType.BUNDLE || (selectedBundle && !selectedBundle.upgraded);
  const shouldRemove = selectedBundle && selectedBundle.selectedUpgraded;

  return (
    <div
      className={styles.smartUpgradeContainer}
      style={shouldHide ? { display: 'none' } : {}}
      onClick={() =>
        updateSelectedBundle({
          ...selectedBundle!,
          selectedUpgraded: !selectedBundle!.selectedUpgraded,
        })
      }
    >
      <div className={styles.imageContainer}>
        <img src={upgradeBG} className={styles.image} alt="upgrade" />
      </div>
      <div className={styles.upgradeRow}>
        <div className={styles.upgradeTitle}>{shouldRemove ? LABELS.REMOVE : LABELS.ADD}</div>
        <div className={styles.upgradePrice}>
          {shouldRemove ? '-' : '+'}
          {costDifference.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  offerType: state.bookingState.offerType,
  selectedBundle: state.bundlesState.selectedBundle,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelectedBundle: (data: BundlePackage) =>
    dispatch(BundlesActions.updateSelectedBundle(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddSmartUpgrade));
