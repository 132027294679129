import React, { FC, useEffect, useState, useMemo } from 'react';
import SectionContentLoading from './SectionContentLoading';
import { useStyles } from './SectionContentStyles';

interface SectionContentProps {
  title: string;
  list?: string[];
  text?: string;
  image: string;
  actionText?: string;
  reversed?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  onAction?: () => void;
  imgPosition?: string;
}

const SectionContent: FC<SectionContentProps> = ({
  title,
  list,
  text,
  image,
  actionText,
  reversed,
  children,
  loading,
  onAction,
  imgPosition,
}) => {
  const styles = useStyles();
  return (
    <>
      {loading ? (
        <SectionContentLoading reversed={reversed} actionText={actionText} />
      ) : (
        <div className={styles.sectionContainer}>
          <div className={reversed ? styles.endRow : styles.sectionRow}>
            <div className={styles.sectionContent}>
              <div className={styles.sectionTitle}>{title}</div>
              {list && (
                <ul>
                  {list.map((li, idx) => (
                    <li className={styles.listItem} key={idx}>
                      {li}
                    </li>
                  ))}
                </ul>
              )}
              {text && <div className={styles.sectionBody}>{text}</div>}
              {!!children && children}
            </div>
            <div className={reversed ? styles.endImageContainer : styles.sectionImageContainer}>
              <img
                src={image}
                className={styles.sectionImage}
                style={imgPosition ? { objectPosition: imgPosition } : undefined}
                alt="section image"
              />
            </div>
          </div>
          {!!actionText && (
            <div className={styles.sectionButton} onClick={() => onAction!()}>
              {actionText}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SectionContent;
