import moment from 'moment';
import { DayAndDate, DateSlot } from '../../models/common/DateAndTime';

export const getDayAndDate = (date: Date, selectedDayAndDate: DayAndDate[]): DateSlot => {
  // current month
  const currentDateObj = moment();
  // selected month
  const selectedDateObj = moment(date);
  const daysInMonth: DayAndDate[] = [];
  let firstDate = 1;
  if (currentDateObj.format('MMMM') === selectedDateObj.format('MMMM')) {
    const currentDate = currentDateObj.format('DD');
    firstDate = parseInt(currentDate, 10) + 1;
    // current month date
    for (let i = 1; i < currentDateObj.daysInMonth() - parseInt(currentDate, 10) + 1; i++) {
      let newDay = currentDateObj.clone().add(i, 'days');
      daysInMonth.push({
        day: newDay.format('ddd'),
        date: newDay.format('DD'),
        month: selectedDateObj.format('MMMM'),
        year: selectedDateObj.format('yyyy'),
      });
    }
    // plus 3 months days and date
    for (let i = 0; i < 3; i++) {
      const nextMonth = currentDateObj
        .clone()
        .add(i + 1, 'months')
        .startOf('month');
      for (let i = 0; i < nextMonth.daysInMonth(); i++) {
        let newDay = nextMonth.clone().add(i, 'days');
        daysInMonth.push({
          day: newDay.format('ddd'),
          date: newDay.format('DD'),
          month: newDay.format('MMMM'),
          year: newDay.format('yyyy'),
        });
      }
    }
  } else {
    for (let i = 3; i > 0; i--) {
      const nextMonth = selectedDateObj.clone().add(-i, 'months').startOf('month');
      if (currentDateObj.format('MMMM') === nextMonth.format('MMMM')) {
        const currentDate = currentDateObj.format('DD');
        for (let i = 1; i < currentDateObj.daysInMonth() - parseInt(currentDate, 10) + 1; i++) {
          let newDay = currentDateObj.clone().add(i, 'days');
          daysInMonth.push({
            day: newDay.format('ddd'),
            date: newDay.format('DD'),
            month: newDay.format('MMMM'),
            year: newDay.format('yyyy'),
          });
        }
      }
      if (nextMonth > currentDateObj) {
        for (let i = 0; i < nextMonth.daysInMonth(); i++) {
          let newDay = nextMonth.clone().add(i, 'days');
          daysInMonth.push({
            day: newDay.format('ddd'),
            date: newDay.format('DD'),
            month: newDay.format('MMMM'),
            year: newDay.format('yyyy'),
          });
        }
      }
    }
    // selected month and plus 3 months days and dates
    for (let i = 0; i < 4; i++) {
      const nextMonth = selectedDateObj.clone().add(i, 'months').startOf('month');
      for (let i = 0; i < nextMonth.daysInMonth(); i++) {
        let newDay = nextMonth.clone().add(i, 'days');
        daysInMonth.push({
          day: newDay.format('ddd'),
          date: newDay.format('DD'),
          month: newDay.format('MMMM'),
          year: newDay.format('yyyy'),
        });
      }
    }
  }
  const firstDateIndex = daysInMonth.findIndex(
    (day) => day.month === selectedDateObj.format('MMMM') && parseInt(day.date, 10) === firstDate,
  );

  return {
    month: selectedDateObj.format('MMMM'),
    year: selectedDateObj.format('yyyy'),
    dayAndDate: daysInMonth.map((data) => {
      return {
        ...data,
        selected:
          selectedDayAndDate.findIndex(
            (selectedData) =>
              selectedData.day === data.day &&
              selectedData.date === data.date &&
              selectedData.month === data.month,
          ) === -1
            ? false
            : true,
      };
    }),
    firstDateIndex,
  };
};
