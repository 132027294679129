import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { cardContainer, pageContainer, root } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  root: {
    ...root,
    backgroundColor: theme.colors.light,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
  },
  container: {
    ...pageContainer,
    marginTop: 0,
  },
  card: {
    ...cardContainer,
    height: 100,
  },
  allOffersText: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xl,
    color: theme.colors.buttonPrimary,
    marginTop: theme.units.margin * 8,
    marginBottom: theme.units.margin * 8,
  },
  offersGrid: {
    display: 'grid',
    gridGap: theme.units.margin * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  servicesGrid: {
    display: 'grid',
    gridGap: theme.units.margin * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 3fr',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  categoryFilterButton: {
    '&:hover': {
      background: theme.colors.lightGrey,
      color: theme.colors.secondary,
      cursor: 'pointer',
    },
  },
  selectedCategory: {
    background: theme.colors.lightGrey,
    color: theme.colors.secondary,
  },
});
