import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer, noShadow } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    ...root,
  },
  container: {
    ...pageContainer,
    ...noShadow,
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
  },
  leftCard: {
    ...cardContainer,
    height: 'min-content',
    boxShadow: 'unset',
    border: `${theme.colors.borderColor} 2px solid`,
  },
  heading: {
    fontSize: theme.font.xl,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.buttonPrimary,
    marginBottom: theme.units.margin * 6,
  },
  subHeading: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.grey,
  },
  inputStyle: {
    border: 'none',
    borderRadius: theme.units.borderRadius,
    width: '100%',
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.l,
      fontFamily: theme.font.family,
      fontWeight: 300,
    },
    fontSize: theme.font.l,
    fontFamily: theme.font.family,
    color: theme.colors.inputText,
    backgroundColor: theme.colors.inputBackground,
    paddingLeft: theme.units.padding * 3,
    height: 52,
  },
  iconStyle: {
    width: theme.units.iconWidth * 1.2,
    height: theme.units.iconHeight * 1.2,
    marginRight: theme.units.margin * 4,
  },
  inputCard: {
    marginBottom: theme.units.margin * 4,
  },
  formStyle: {
    flex: 1,
    marginTop: theme.units.margin * 6,
  },
  titleStyle: {
    color: theme.colors.inputText,
  },
  fileContainer: {
    borderRadius: theme.units.borderRadius,
    // boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    border: `${theme.colors.borderColor} 4px solid`,
    padding: theme.units.padding * 6,
    backgroundColor: theme.colors.light,
    // overflow: 'hidden',
  },
  fileMainContainer: {
    borderRadius: theme.units.borderRadius,
    width: '100%',
    marginTop: theme.units.margin * 4,
  },
  imageIconStyle: {
    width: theme.units.iconWidth,
    height: theme.units.iconHeight,
    marginRight: 24,
  },
  addIconStyle: {
    width: theme.units.iconWidth / 1.5,
    height: theme.units.iconHeight / 1.5,
  },
  errorTextStyle: {
    fontSize: theme.font.xs,
    fontFamily: theme.font.family,
    color: theme.colors.error,
    marginTop: theme.units.margin * 2,
  },
  errorContainer: {
    border: `${theme.colors.error} 4px solid`,
  },
});
