import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './AddDetailsStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LABELS, validationSchema } from './AddDetailsConstants';
import { useFormik } from 'formik';
import Header from '../../components/header/Header';
import AddDetailsInput from './components/AddDetailsInput';
import SummaryCard from '../../components/summaryCard/SummaryCard';
import Footer from '../../components/footer/Footer';
import { routes } from '../../Routes';
import { OfferType } from '../../models/common/OfferType';
import { BookingActions } from '../../store/actions/BookingActions';
import Stepper from '../../components/stepper/Stepper';
import { UserDetails } from '../../models/auth/User';
import { getArrowText } from '../../helpers/StepHelper';
import { Step, StepType } from '../../models/common/Step';

interface AddDetailsProps extends RouteComponentProps {
  offerType: OfferType;
  steps: Step[];
  userDetails: UserDetails | undefined;
  updateUserDetails: (data: UserDetails) => void;
}

const AddDetails: FC<AddDetailsProps> = ({
  history,
  userDetails,
  offerType,
  steps,
  updateUserDetails,
}) => {
  const styles = useStyles();
  const formik = useFormik({
    initialValues: {
      firstName: userDetails ? userDetails.firstName : '',
      lastName: userDetails ? userDetails.lastName : '',
      email: userDetails ? userDetails.email : '',
      mobile: userDetails ? userDetails.mobile : '',
    },
    onSubmit: (values) => {
      updateUserDetails({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobile: values.mobile,
      });
      history.push(routes.addAddress);
    },
    validationSchema: validationSchema,
  });
  return (
    <div>
      <Header
        expanded
        arrowText={getArrowText(steps, StepType.USER_DETAILS)}
        expandedTitle={LABELS.HEADER_TITLE}
      />
      <Stepper activeStep={StepType.USER_DETAILS} />
      <div className={styles.container}>
        <div className={styles.leftCard}>
          <div className={styles.heading}>{LABELS.HEADING}</div>
          <div className={styles.formStyle}>
            <AddDetailsInput
              placeholder={LABELS.ENTER_NAME}
              key={LABELS.NAME}
              value={formik.values.firstName}
              setValue={formik.handleChange('firstName')}
              onBlur={() => formik.setFieldTouched('firstName')}
              touched={formik.touched.firstName}
              error={formik.errors.firstName}
              title={LABELS.NAME}
              mandatory
            />
            <AddDetailsInput
              placeholder={LABELS.ENTER_NAME}
              key={LABELS.LAST_NAME}
              value={formik.values.lastName}
              setValue={formik.handleChange('lastName')}
              onBlur={() => formik.setFieldTouched('lastName')}
              touched={formik.touched.lastName}
              error={formik.errors.lastName}
              title={LABELS.LAST_NAME}
              mandatory
            />
            <AddDetailsInput
              placeholder={LABELS.MOBILE}
              key={LABELS.MOBILE}
              value={formik.values.mobile}
              setValue={formik.handleChange('mobile')}
              onBlur={() => formik.setFieldTouched('mobile')}
              touched={formik.touched.mobile}
              error={formik.errors.mobile}
              title={LABELS.MOBILE}
              mandatory
            />
            <AddDetailsInput
              placeholder={LABELS.EMAIL}
              key={LABELS.EMAIL}
              value={formik.values.email}
              setValue={formik.handleChange('email')}
              onBlur={() => formik.setFieldTouched('email')}
              touched={formik.touched.email}
              error={formik.errors.email}
              title={LABELS.EMAIL}
              mandatory
            />
          </div>
        </div>
        <SummaryCard />
      </div>
      <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.NEXT}
        primaryAction={() => {
          formik.handleSubmit();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  steps: state.bookingState.steps,
  offerType: state.bookingState.offerType,
  userDetails: state.bookingState.userDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateUserDetails: (data: UserDetails) => dispatch(BookingActions.updateUserDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddDetails));
