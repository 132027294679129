import React, { FC } from 'react';
import { useStyles } from '../BundleDetailsStyles';

interface LoadingIntroProps {}

const LoadingIntro: FC<LoadingIntroProps> = ({}) => {
  const styles = useStyles();
  return (
    <div className={styles.bundleRow}>
      <div className={styles.bundleContent}>
        <div className={styles.loadingSubheading} />
        <div className={styles.loadingTitle} />
        <div className={styles.loadingDescription} />
      </div>
      <div className={styles.priceContent}>
        <div className={styles.loadingToggleContainer}>
          <div className={styles.loadingToggle} />
          <div className={styles.loadingToggle} />
        </div>
        <div className={styles.loadingPriceContainer} />
      </div>
    </div>
  );
};

export default LoadingIntro;
