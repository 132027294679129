import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import {
  pageContainer,
  root,
  cardContainer,
  mobilePaddingCounter,
  extraPadding,
  footerPadding,
} from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  root: {
    ...root,
    ...footerPadding,
  },
  container: {
    ...pageContainer,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
    paddingBottom: 120,
  },
  leftCard: {
    ...cardContainer,
    ...extraPadding,
    height: 'min-content',
    boxShadow: 'unset',
    border: `${theme.colors.borderColor} 2px solid`,
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      ...mobilePaddingCounter,
    },
    paddingBottom: theme.units.padding * 6,
  },
  heading: {
    fontSize: theme.font.xl,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.buttonPrimary,
    marginBottom: theme.units.margin * 6,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.margin,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    marginLeft: theme.units.margin,
  },
  inputContainer: {
    marginBottom: theme.units.margin * 6,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputStyle: {
    border: 'none',
    borderRadius: theme.units.borderRadius,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontFamily: theme.font.family,
      fontWeight: 300,
    },
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.inputText,
    backgroundColor: theme.colors.inputBackground,
    flex: 1,
    padding: theme.units.padding * 4,
  },
  buttonRow: {
    display: 'flex',
    marginTop: theme.units.margin * 4,
  },
  rightButton: {
    marginLeft: theme.units.margin * 4,
  },
  errorTextStyle: {
    fontSize: theme.font.xs,
    fontFamily: theme.font.family,
    color: theme.colors.error,
    marginTop: theme.units.margin,
  },
  inputErrorInput: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.error,
  },
});
