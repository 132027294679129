export const LABELS = {
  HEADER_ARROW_TEXT: 'Step 4 of 4',
  HEADER_TITLE: 'Select preferred date',
  HEADER_SUBTITLE: "Select up to 2. These times are 'preference only' and not guaranteed",
  BACK: 'Back',
  NEXT: 'Next',
};

export const TIME_SOLTS = {
  FIRST_SLOT: '8:00am – 12:00pm',
  SECOND_SLOT: '12:00pm – 5:00pm',
};
