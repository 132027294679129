import { makeStyles } from '@material-ui/core';
import { theme } from '../../../theme/Theme';
import { SIZES } from '../../../Constants';

export const useStyles = makeStyles({
  container: {
    [`@media (min-width: ${SIZES.sm}px)`]: {
      minWidth: 'min(calc(100% - 64px), 700px)',
    },
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
  },
  closeMenuButton: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5em',
    cursor: 'pointer',
  },
  packagesListContainer: {
    display: 'grid',
    columnGap: '3em',
    rowGap: '2em',
    gridTemplateColumns: '1fr 1fr',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  packageDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '0.5em',
    '& > *': {
      cursor: 'pointer',
      '&:hover': {
        color: theme.colors.secondary,
      },
    },
  },
});
