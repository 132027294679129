import { Property } from '../../models/common/Property';

export const getExistingAddress = (property: Property | undefined) => {
  if (!property) return '';
  const { unitNumber, streetNumber, streetName, state, suburb, postcode, country } = property;

  const address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}, `;
  const address2 = `${suburb} ${state} ${postcode}, ${country}`;
  return `${address1}${address2}`;
};
