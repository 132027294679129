import { theme } from '../../theme/Theme';
import { Theme, makeStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';
import { loadingAnimation, pageContainer } from '../../theme/GlobalStyles';

const HEIGHT = 500;
const SMALL_HEIGHT = 360;

interface StyleProps {
  imgPosition?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>({
  backgroundContainer: {
    position: 'relative',
  },
  backgroundWrapper: {
    position: 'absolute',
    height: HEIGHT,
    width: '100vw',
    display: 'flex',
    zIndex: 0,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  },
  backgroundImage2: (props) => ({
    position: 'absolute',
    height: HEIGHT,
    width: '100vw',
    objectPosition: props?.imgPosition || 'center',
    objectFit: 'cover',
    ...loadingAnimation,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  }),
  trapezoid: {
    borderBottom: `${HEIGHT}px solid ${theme.colors.buttonPrimary}`,
    borderRight: `${HEIGHT / 4}px solid transparent`,
    height: 0,
    width: '50%',
    zIndex: 2,
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '60%',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      width: '100%',
      borderRight: `0px solid transparent`,
      borderBottom: `${SMALL_HEIGHT}px solid ${theme.colors.buttonPrimary}`,
    },
  },
  backgroundImage: (props) => ({
    position: 'absolute',
    right: 0,
    height: HEIGHT,
    width: '58%',
    objectPosition: props?.imgPosition || 'top',
    objectFit: 'cover',
    zIndex: 1,
    ...loadingAnimation,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  }),
  darken: {
    position: 'absolute',
    height: HEIGHT,
    width: '100vw',
    backgroundColor: '#000',
    opacity: 0.5,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  },
  contentStart: {
    height: HEIGHT,
    width: '100vw',
    ...loadingAnimation,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: SMALL_HEIGHT,
    },
  },
  inset: {
    height: 260,
    width: '100vw',
  },
  children: {
    position: 'absolute',
    width: '100vw',
    top: 260,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      top: 200,
    },
  },
  textContainer: {
    ...pageContainer,
  },
  textWrapper: {
    position: 'absolute',
    top: 90,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      top: 40,
    },
  },
  preHeader: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family2,
    textTransform: 'uppercase',
    color: theme.colors.light,
    fontWeight: 600,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  headline: {
    fontSize: theme.font.xxxl,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    fontWeight: 600,
    marginTop: theme.units.margin * 2,
    marginBottom: theme.units.margin * 2,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.xxl,
    },
  },
  introText: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    fontWeight: 600,
    maxWidth: 520,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.m,
    },
  },
  text: {
    fontSize: theme.font.xxxl,
    fontFamily: theme.font.family2,
    color: theme.colors.light,
    fontWeight: 600,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: theme.font.xxl,
    },
  },
  // Loading
  loadingPreHeader: {
    width: 140,
    height: 24,
    backgroundColor: theme.colors.light,
  },
  loadingHeadline: {
    width: 260,
    height: 40,
    backgroundColor: theme.colors.light,
    marginTop: theme.units.margin * 2,
    marginBottom: theme.units.margin * 2,
  },
  loadingIntroText: {
    width: 140,
    height: 24,
    backgroundColor: theme.colors.light,
  },
});
