import React, { FC } from 'react';
import { useStyles } from './BackgroundCoverStyles';
import { theme } from '../../theme/Theme';
import BackgroundCoverLoading from './BackgroundCoverLoading';

interface BackgroundCoverProps {
  darken?: boolean;
  landing?: boolean;
  inset?: boolean;
  title?: string;
  text?: string;
  children?: React.ReactNode;
  backgroundImage?: string;
  loading?: boolean;
  imgPosition?: string;
}

const BackgroundCover: FC<BackgroundCoverProps> = ({
  darken,
  inset,
  title,
  text,
  landing,
  children,
  backgroundImage,
  loading,
  imgPosition,
}) => {
  const styles = useStyles({ imgPosition });

  return (
    <>
      {loading ? (
        <BackgroundCoverLoading landing={landing} inset={inset} children={children} />
      ) : (
        <div className={styles.backgroundContainer}>
          {landing ? (
            <div className={styles.backgroundWrapper}>
              <div className={styles.trapezoid} />
              <img
                src={backgroundImage || theme.hero}
                className={styles.backgroundImage}
                alt="hero"
              />
            </div>
          ) : (
            <img
              src={backgroundImage || theme.hero}
              className={styles.backgroundImage2}
              alt="hero"
            />
          )}

          {darken && <div className={styles.darken} />}
          {landing && (
            <div className={styles.textContainer}>
              <div className={styles.textWrapper}>
                <div className={styles.preHeader}>MIDDY'S HOME SERVICES</div>
                <div className={styles.headline}>{title || 'Offering you more...'}</div>
                <div className={styles.introText}>
                  {text || 'Whilst supporting our electrical contractors'}
                </div>
              </div>
            </div>
          )}
          {!!children && <div className={styles.children}>{children}</div>}
          <div className={inset ? styles.inset : styles.contentStart} />
        </div>
      )}
    </>
  );
};

export default BackgroundCover;
