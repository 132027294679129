import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './SolarQuoteStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import SolarBG from '../../assets/solar/hero.jpg';
import SolarInstallation from '../../assets/solar/solar.png';
import { LABELS, LIST, STEPS } from './SolarQuoteConstants';
import Footer from '../../components/footer/Footer';
import { routes } from '../../Routes';
import ContactUs from '../../assets/contact-us-cropped.png';
import { OfferType } from '../../models/common/OfferType';
import { BookingActions } from '../../store/actions/BookingActions';
import SectionContent from '../../components/sectionContent/SectionContent';
import HowItWorksSection from '../../components/howItWorksSection/HowItWorksSection';
import { UpdateOfferType } from '../../models/common/UpdateOfferTypeRequest';
import { getSteps } from '../../helpers/StepHelper';

interface SolarQuoteProps extends RouteComponentProps {
  updateOfferType: (data: UpdateOfferType) => void;
}

const SolarQuote: FC<SolarQuoteProps> = ({ history, updateOfferType }) => {
  const styles = useStyles();

  const onSubmit = () => {
    updateOfferType({ offerType: OfferType.SOLAR_FLOW_1, steps: getSteps(OfferType.SOLAR_FLOW_1) });
    history.push(routes.jobDetails);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Header />
      <BackgroundCover darken backgroundImage={SolarBG}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.headerTitle}>{LABELS.HERO_TITLE}</div>
            <div className={styles.headerSubtitle}>{LABELS.HERO_TEXT}</div>
            <div className={styles.headerButton} onClick={() => onSubmit()}>
              {LABELS.GET_QUOTE}
            </div>
          </div>
        </div>
      </BackgroundCover>
      <div className={styles.container}>
        <SectionContent
          title={LABELS.TITLE}
          text={LABELS.TEXT}
          image={SolarInstallation}
          actionText={LABELS.GET_QUOTE}
          onAction={() => onSubmit()}
        />
        <HowItWorksSection steps={STEPS} title={LABELS.HOW_IT_WORKS} />
        <SectionContent
          title={LABELS.MANAGE_EVERYTHING_TITLE}
          image={ContactUs}
          reversed
          list={LIST}
        />
      </div>
      <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.GET_QUOTE}
        primaryAction={() => onSubmit()}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateOfferType: (data: UpdateOfferType) => dispatch(BookingActions.updateOfferType(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SolarQuote));
