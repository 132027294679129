import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import {
  pageContainer,
  root,
  cardContainer,
  extraPadding,
  mobilePaddingCounter,
  footerPadding,
} from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  root: {
    ...root,
    ...footerPadding,
  },
  card: {
    ...cardContainer,
  },
  container: {
    ...pageContainer,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
    paddingBottom: 120,
  },
  leftCard: {
    ...cardContainer,
    ...extraPadding,
    height: 'min-content',
    boxShadow: 'unset',
    border: `${theme.colors.borderColor} 2px solid`,
    width: '100%',
  },
  heading: {
    fontSize: theme.font.xl,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.buttonPrimary,
    marginBottom: theme.units.margin * 6,
  },
  cardWrapper: {
    padding: theme.units.padding * 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.units.borderRadius,
  },
  selectedCardWrapper: {
    borderColor: theme.colors.secondary,
    backgroundColor: 'rgba(0, 85, 171, 0.03)',
  },
  iconStyle: {
    width: theme.units.iconWidth * 1.5,
    height: theme.units.iconHeight * 1.5,
  },
  tradeCardWrapper: {
    marginTop: theme.units.margin * 6,
  },
  tradeCardStyle: {
    marginBottom: theme.units.margin * 4,
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  detailsStyle: {
    marginLeft: theme.units.margin * 4,
  },
  nameText: {
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
    fontWeight: 300,
    color: theme.colors.black87,
    marginBottom: theme.units.margin,
  },
  emailText: {
    fontSize: theme.font.s,
    color: theme.colors.black38,
    marginBottom: theme.units.margin * 2,
  },
  verifiedText: {
    height: 26,
    width: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
    fontSize: theme.font.xs,
    marginTop: theme.units.margin * 4,
    borderRadius: 500,
  },
  ratingText: {
    fontSize: theme.font.xl,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.buttonPrimary,
    textAlign: 'end',
  },
  ratingSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  ratingStyle: {
    marginTop: theme.units.margin,
  },
  ratingColor: {
    '&.MuiRating-root': {
      // opacity: 1,
      color: theme.colors.secondary,
    },
    '&.MuiRating-root.Mui-disabled': {
      opacity: 1,
    },
  },
  disabledRatingStyle: {
    opacity: 1,
  },
  addIconStyle: {
    width: theme.units.iconWidth,
    height: theme.units.iconHeight,
    marginTop: theme.units.margin * 4,
    transform: 'scale(1.2)',
    cursor: 'pointer',
  },
  tickIcon: {
    marginTop: theme.units.margin * 4,
    transform: 'scale(1.2)',
    cursor: 'pointer',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.margin,
  },
  helper: {
    marginLeft: '1rem',
  },
  helperBox: {
    width: '18rem',
    padding: '0.5rem',
    borderRadius: '4',
    background: theme.colors.black87,
    color: theme.colors.light,
    fontSize: theme.font.xs,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    marginLeft: theme.units.margin,
  },
  tradesContainer: {
    marginTop: theme.units.margin * 6,
  },
  inputStyle: {
    border: 'none',
    borderRadius: theme.units.borderRadius,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.grey,
      fontSize: theme.font.s,
      fontFamily: theme.font.family,
      fontWeight: 300,
    },
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
    color: theme.colors.inputText,
    backgroundColor: theme.colors.inputBackground,
    flex: 1,
    padding: theme.units.padding * 4,
    fontWeight: 400,
  },
  notesTitle: {
    color: theme.colors.inputText,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    marginBottom: 12,
  },
  errorTextStyle: {
    fontSize: theme.font.xs,
    fontFamily: theme.font.family,
    color: theme.colors.error,
    marginLeft: theme.units.margin * 2,
  },
  inputContainer: {
    marginTop: theme.units.margin * 6,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  noTradesStyle: {
    color: theme.colors.inputText,
    fontWeight: 400,
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
  },
  emptyCardWrapper: {
    padding: theme.units.padding * 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.units.borderRadius,
    marginBottom: theme.units.margin * 5,
  },
  questionContainer: {
    marginBottom: theme.units.margin * 5,
  },
  toggleStyles: {
    marginTop: theme.units.margin * 3,
  },
  viewAllText: {
    color: theme.colors.secondary,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 500,
  },
});
