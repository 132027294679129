import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './FooterStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Button from '../button/Button';
import { LABELS } from './FooterConstants';
import CircularProgress from '@material-ui/core/CircularProgress';

interface FooterProps extends RouteComponentProps {
  price?: boolean;
  secondaryText: string;
  primaryText: string;
  secondaryAction: () => void;
  primaryAction: () => void;
  secondaryDisabled?: boolean;
  loading?: boolean;
}

const Footer: FC<FooterProps> = ({
  price,
  primaryText,
  secondaryText,
  secondaryAction,
  primaryAction,
  secondaryDisabled,
  loading,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContentWrapper}>
        {price ? (
          <div className={styles.priceContainer}>
            <div className={styles.priceLabel}>{LABELS.PRICE}</div>
            <div className={styles.priceWrapper}>
              <div className={styles.price}>{`$${secondaryText.split('.')[0]}`}</div>
              <div className={styles.decimal}>.00</div>
            </div>
          </div>
        ) : (
          <Button secondary handlePress={() => secondaryAction()}>
            {secondaryText}
          </Button>
        )}
        <Button handlePress={() => primaryAction()} disabled={secondaryDisabled}>
          {loading ? <CircularProgress style={{color: 'white'}} size={20}/> : primaryText}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
