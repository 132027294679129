import { Button, ButtonProps } from '@material-ui/core';
import React, { FC } from 'react';
import { useStyles } from './ActionButtonStyles';

export interface ActionButtonProps extends ButtonProps {}

const ActionButton: FC<ActionButtonProps> = ({ children, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <Button className={classes.actionButton} {...rest}>
      {children}
    </Button>
  );
};

export default ActionButton;
