import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './MiddysFooterStyles';
import logoWhite from '../../assets/logos/middys-white.png';
import instagram from '../../assets/socials/instagram.png';
import facebook from '../../assets/socials/facebook.png';
import linkedIn from '../../assets/socials/linkedIn.png';
import sponsor1 from '../../assets/sponsors/sponsor1.png';
import sponsor2 from '../../assets/sponsors/sponsor2.png';
import sponsor3 from '../../assets/sponsors/sponsor3.png';
import sponsor4 from '../../assets/sponsors/sponsor4.png';
import poweredBy from '../../assets/poweredBy.png';
import { LABELS } from './MiddysFooterConstants';
import { Typography } from '@material-ui/core';
import ActionButton from '../actionButton/ActionButton';
import ContentContainer from '../containers/ContentContainer';
import { useHistory } from 'react-router';
import { routes } from '../../Routes';

interface MiddysFooterProps {
  showRegisterInterestBanner?: boolean;
}

const MiddysFooter: FC<MiddysFooterProps> = ({ showRegisterInterestBanner }) => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <div className={styles.footerContainer}>
      {showRegisterInterestBanner && (
        <div className={styles.registerInterestBanner}>
          <ContentContainer>
            <div className={styles.bannerContent}>
              <Typography variant="h3" className="title" style={{ color: 'white' }}>
                {LABELS.REGISTER_INTEREST_BANNER_TEXT}
              </Typography>
              <ActionButton
                onClick={() => {
                  history.push(routes.registerInterest);
                }}
              >
                {LABELS.REGISTER_INTEREST}
              </ActionButton>
            </div>
          </ContentContainer>
        </div>
      )}
      <div className={styles.topFooter}>
        <div className={styles.footerContent}>
          <div className={styles.flexRow}>
            <img src={logoWhite} className={styles.middysLogoImg} alt="logo" />
            {/* <div className={styles.interestedContainer}>
              <div className={styles.interestedTitle}>{LABELS.INTERESTED_TITLE}</div>
              <div className={styles.interestedText}>
                {LABELS.INTERESTED_TEXT}{' '}
                <span
                  className={styles.span}
                  onClick={() => window.open('https://www.middys.com.au/', '_blank')}
                >
                  here
                </span>
              </div>
            </div> */}
            <div className={styles.socialsContainer}>
              <div className={styles.socialsTitle}>{LABELS.FOLLOW}</div>
              <div className={styles.socialIcons}>
                <img
                  onClick={() => window.open(LABELS.FACEBOOK_LINK, '_blank')}
                  src={facebook}
                  className={styles.socialIcon}
                  alt="facebook"
                />
                <img
                  onClick={() => window.open(LABELS.INSTAGRAM_LINK, '_blank')}
                  src={instagram}
                  className={styles.socialIcon}
                  alt="instagram"
                />
                <img
                  onClick={() => window.open(LABELS.LINKEDIN, '_blank')}
                  src={linkedIn}
                  className={styles.socialIcon}
                  alt="linkedin"
                />
              </div>
            </div>
          </div>
          <div className={styles.flexRow}>
            <img
              src={poweredBy}
              className={styles.poweredBy}
              alt="powered by sorted"
              onClick={() => window.open('https://www.sortedservices.com/', '_blank')}
            />
          </div>
        </div>
      </div>
      <div className={styles.bottomFooter}>
        <div className={styles.footerContent}>
          <div className={styles.flexRowCentered}>
            <div className={styles.sponsors}>
              <img src={sponsor1} style={{ width: 42 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor2} style={{ width: 50 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor3} style={{ width: 70 }} className={styles.sponsor} alt="sponsor" />
              <img src={sponsor4} style={{ width: 80 }} className={styles.sponsor} alt="sponsor" />
            </div>
            <div className={styles.cc}>{LABELS.CC}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddysFooter;
