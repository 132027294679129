export const LABELS = {
  title: 'Booking Request Received!',
  subtitle:
    "Your home is special and we're excited to help you transform it, your way! We’ve sent you an email with further details on what to expect next.",
  subtitle1:
    'You will also be receiving a SMS from our partner Sorted Services. We’ve partnered with Sorted to help you view and manage your Middy’s booking requests. Download the Sorted App now to get started.',
  solarTitle: 'Quote Request Received',
  solarSubtitle:
    'Sit back and relax, someone from the Middy’s EnergyTrust team will be in contact with you soon. Make sure to check out our other home services packages, we have customised home automation solutions to suit individual living spaces.',
  solarSubtitle1:
    "We've partnered with Sorted to help more Australians easily access Middy’s offers and manage their booking requests all within the one app. Download the Sorted App now to get started.",
};
