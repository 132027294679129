/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
// LOWERCASE ONLY
export enum ServiceType {
  RefreshToken = 'refreshToken',
  Offers = 'Offers',
  TradesAuth = 'trades/auth',
  booking = 'trades/api/private-works',
  property = 'api/trades',
}
