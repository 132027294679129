import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { LABELS, PRIORITY_ORDER, getIcon } from './PackagesMenuConstants';
import { CancelOutlined } from '@material-ui/icons';
import { BundlePackage } from '../../../models/bundles/Bundles';
import { useStyles } from './PackagesMenuStyles';
import { groupBy } from 'lodash';
import { theme } from '../../../theme/Theme';
import MiddysLink from '../../link/MiddysLink';
import { routes } from '../../../Routes';
import { useHistory } from 'react-router';
import ActionButton from '../../actionButton/ActionButton';

interface PackagesMenuProps {
  packages: BundlePackage[] | undefined;
  loading: boolean;
  onClose: () => void;
}

export const PackagesMenu: FC<PackagesMenuProps> = ({ packages, loading, onClose }) => {
  const classes = useStyles();
  const history = useHistory();

  if (loading || !packages)
    return (
      <Box className={classes.container} p={2}>
        <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
          <CircularProgress color="secondary" />
        </Box>
      </Box>
    );

  const data = groupBy(packages, (p) => p.category!.title);

  return (
    <Box className={classes.container} px={3} py={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" color="secondary">
          {LABELS.packages}
        </Typography>
        <Typography
          onClick={() => {
            onClose();
          }}
          className={classes.closeMenuButton}
          style={{ fontSize: theme.font.s }}
        >
          {LABELS.close} <CancelOutlined />
        </Typography>
      </Box>

      <Box display="flex" gridGap="2em" alignItems="flex-start" flexWrap="wrap">
        <Box mt={3} className={classes.packagesListContainer}>
          {PRIORITY_ORDER.map((p) => (
            <Box className={classes.packageDetailsContainer}>
              <MiddysLink
                onClick={() => {
                  onClose();
                }}
                to={{ pathname: routes.packages, state: { filter: p } }}
              >
                <Box display="flex" gridGap="0.5em">
                  <img src={getIcon(p)} />
                  <Typography variant="h5">{p}</Typography>
                </Box>
              </MiddysLink>
              {data[p].map((item) => (
                <Box
                  onClick={() => {
                    history.push(`${routes.bundleDetails}?bundleId=${item.productId}`);
                    onClose();
                  }}
                >
                  <Typography style={{ fontSize: theme.font.s }}>
                    {item.packageName || item.standard.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        <Box>
          <Box mt={1} py={2} className={` ${classes.packageDetailsContainer}`}>
            <MiddysLink
              onClick={() => {
                onClose();
              }}
              to={{ pathname: routes.packages, state: { filter: 'Services' } }}
            >
              <Box display="flex" gridGap="0.5em">
                <img src={getIcon('Services')} />
                <Typography variant="h5">{LABELS.services}</Typography>
              </Box>
            </MiddysLink>
            <MiddysLink to={routes.electricianQuote}>
              <Typography style={{ fontSize: theme.font.s }}>{LABELS.electrician}</Typography>
            </MiddysLink>
            <MiddysLink to={routes.solarQuote}>
              <Typography style={{ fontSize: theme.font.s }}>{LABELS.solar}</Typography>
            </MiddysLink>
          </Box>
          <Box my={3} display="flex" justifyContent="center">
            <MiddysLink to={routes.packages}>
              <ActionButton variant="outlined">{LABELS.viewAll}</ActionButton>
            </MiddysLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
