import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';

interface FieldLabelProps {
  label: string;
  required?: boolean;
}

const FieldLabel: FC<FieldLabelProps> = ({ label, required }) => {
  return (
    <Box mb={1}>
      <Typography color="textSecondary" variant="body2" style={{ fontWeight: 500 }}>
        {label}
        {required ? ' *' : ''}
      </Typography>
    </Box>
  );
};

export default FieldLabel;
