import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { AppSettings } from '../../AppSettings';
import {
  GuestTokenRequest,
  GuestTokenResponse,
  RegisterClientResponse,
} from '../../models/auth/RegisterToken';

export class TokenService {
  private readonly tradesAuthService: ApiServiceBase = new ApiService(ServiceType.TradesAuth);
  public registerClient(): Promise<RegisterClientResponse> | RegisterClientResponse {
    return this.tradesAuthService.get<any>({ route: ['token'] });
  }

  public getGuestToken(data: string): Promise<GuestTokenResponse> | GuestTokenResponse {
    const customTradesAuthService: ApiServiceBase = new ApiService(
      ServiceType.TradesAuth,
      AppSettings.server.baseUrl,
      {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${data}`,
      },
    );
    return customTradesAuthService.get<GuestTokenResponse>({ route: ['guest-token'] });
  }
}

const tokenService = new TokenService();
export default tokenService;
