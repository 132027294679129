import { FilterItem } from './components/filterBar/FilterBarConstants';

export const LABELS = {
  OFFERS_FOR_YOU: 'Search results',
  ADDING_MORE:
    "We're adding more offers in your area soon, check back later or sign up for notifications here",
  SIGN_UP: 'Sign up for email updates',
  HERO_TITLE: 'Personalised offers for you',
  HERO_TITLE_NOT_AVAILABLE: 'Coming to You Soon...',
  HERO_TEXT:
    'Find a local, qualified electrician to help with common repairs, and maintenance around the home.',
  NOT_AVAILABLE_HEADING: 'Not yet available in your area',
  NOT_AVAILABLE_DESCRIPTION:
    "For now Middy's Home Services is only in some Metro areas. We're expanding our offering and hope to bring our home packages to more locations soon. Register your interest below and we'll notify when we are in your area.",
  BACK_TO_SEARCH: 'Back to search',
  REGISTER_INTEREST: 'Register your interest',
};

export enum Filter {
  Packages = 'Packages',
  Services = 'Services',
}

export const valueFilter = [
  {
    value: Filter.Packages,
    active: false,
  },
];

export const expiringFilter = [
  {
    value: Filter.Services,
    active: false,
  },
];

export const initialFilters = () => {
  let result: FilterItem[] = [];

  result = [...result, ...valueFilter, ...expiringFilter];

  return result;
};
