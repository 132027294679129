export const getCategoryDescription = (title: string) => {
  switch (title) {
    case 'Home Automation':
      return 'Create the perfect smart home you’ve always dreamed of with packages you can trust';
    case 'EV Charging':
      return "Charge into the future with our 'plug & play' easy EV charging for homes";
    case 'Fans':
      return 'Cool your home in style with our extensive range of Fans';
    case 'Lights':
      return 'Explore classic and contemporary light fittings to find the perfect fit for your space';
    case 'Electrical Services for Homeowner':
      return 'Look no further for general repairs and maintenance, you’re in good hands with our trusted contractors';
    case 'For Electrical Trades':
      return "Get exclusive access to leads and grow your business via our Middy's Home Services Program";
    case 'Solar':
      return 'We’ve got you covered with our best in class solar proposal tool to set you up for the future';
    default:
      return '';
  }
};
