import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './CompleteScreenStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundCover from '../../components/backgroundCover/BackgroundCover';
import completeIcon from '../../assets/check-circle.png';
import { routes } from '../../Routes';
import { sendMessageToApp, ValidMessages } from '../../helpers/MessageHelper';
import bgImage from '../../assets/register-interest.png';
import sortedBanner from '../../assets/completed-banner.png';
import { LABELS } from './CompleteScreenConstants';
import { OfferType } from '../../models/common/OfferType';

interface CompleteScreenProps extends RouteComponentProps {
  isExternal: boolean;
  offerType: OfferType;
}

const CompleteScreen: FC<CompleteScreenProps> = ({ history, isExternal, offerType }) => {
  const styles = useStyles();

  const onFinish = () => {
    if (isExternal) {
      history.push(routes.home);
    } else {
      sendMessageToApp(ValidMessages.Dashboard);
    }
  };
  const isSolar = offerType === OfferType.SOLAR_FLOW_1;
  return (
    <div>
      <BackgroundCover backgroundImage={bgImage} inset text="" imgPosition="top" />

      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={`${styles.leftCard} ${styles.roundCorner}`}>
            <div className={styles.cardWrapper}>
              <img src={completeIcon} className={styles.iconStyle} alt="complete" />
              <div className={styles.headingStyle}>
                {isSolar ? LABELS.solarTitle : LABELS.title}
              </div>
              <div className={styles.subHeadingStyle}>
                {isSolar ? LABELS.solarSubtitle : LABELS.subtitle}
              </div>
              {isExternal && (
                <div className={styles.subHeadingStyle}>
                  {isSolar ? LABELS.solarSubtitle1 : LABELS.subtitle1}
                </div>
              )}
              <div className={styles.doneButtom} onClick={() => onFinish()}>
                Finish
              </div>
            </div>
          </div>
        </div>
      </div>
      {isExternal && (
        <div className={`${styles.container} ${styles.vertSpace}`}>
          <img src={sortedBanner} className={styles.roundCorner} alt="sorted banner" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isExternal: state.token.isExternal,
  offerType: state.bookingState.offerType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompleteScreen));
