import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import {
  pageContainer,
  root,
  cardContainer,
  extraPadding,
  mobilePaddingCounter,
} from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  root: {
    ...root,
  },
  container: {
    ...pageContainer,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
    paddingBottom: 120,
  },
  leftCard: {
    ...cardContainer,
    ...extraPadding,
    height: 'min-content',
    boxShadow: 'unset',
    border: `${theme.colors.borderColor} 2px solid`,
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      ...mobilePaddingCounter,
    },
  },
  heading: {
    fontSize: theme.font.xl,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.buttonPrimary,
  },
  inputStyle: {
    border: 'none',
    borderRadius: theme.units.borderRadius,
    width: '100%',
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.l,
      fontFamily: theme.font.family,
      fontWeight: 300,
    },
    fontSize: theme.font.l,
    fontFamily: theme.font.family,
    color: theme.colors.inputText,
    backgroundColor: theme.colors.inputBackground,
    paddingLeft: theme.units.padding * 3,
    height: 52,
  },
  iconStyle: {
    width: theme.units.iconWidth * 1.2,
    height: theme.units.iconHeight * 1.2,
    marginRight: theme.units.margin * 4,
  },
  inputCard: {
    marginBottom: theme.units.margin * 4,
  },
  formStyle: {
    flex: 1,
    marginTop: theme.units.margin * 6,
  },
  titleStyle: {
    color: theme.colors.inputText,
  },
  fileContainer: {
    borderRadius: theme.units.borderRadius,
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    padding: theme.units.padding * 6,
    backgroundColor: theme.colors.light,
    // overflow: 'hidden',
  },
  fileMainContainer: {
    borderRadius: theme.units.borderRadius,
    width: '100%',
    marginTop: theme.units.margin * 4,
  },
  imageIconStyle: {
    width: theme.units.iconWidth,
    height: theme.units.iconHeight,
    marginRight: 24,
  },
  addIconStyle: {
    width: theme.units.iconWidth / 1.5,
    height: theme.units.iconHeight / 1.5,
  },
  errorInput: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.error,
  },
  errorTextStyle: {
    fontSize: theme.font.xs,
    fontFamily: theme.font.family,
    color: theme.colors.error,
    marginTop: 4,
  },
});
