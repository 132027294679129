import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { cardContainer, pageContainer, root } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  root: {
    ...root,
    backgroundColor: theme.colors.light,
  },
  content: {
    width: '100%',
  },
  container: {
    ...pageContainer,
  },
  card: {
    ...cardContainer,
    height: 100,
  },
  headerContainer: {
    maxWidth: 450,
    marginTop: -140,
  },
  headerTitle: {
    fontFamily: theme.font.family2,
    fontWeight: 500,
    fontSize: theme.font.xxxl,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 4,
  },
  headerSubtitle: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 12,
  },
  allOffersText: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginTop: theme.units.margin * 6,
    marginBottom: theme.units.margin * 8,
  },
  offersGrid: {
    display: 'grid',
    gridGap: theme.units.margin * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.margin * 8,
  },
  signupContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.units.margin * 12,
    paddingBottom: theme.units.margin * 20,
  },
  signupText: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black60,
    fontWeight: 100,
    textAlign: 'center',
    maxWidth: 400,
    marginBottom: theme.units.margin * 8,
  },
});
