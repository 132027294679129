import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import './Spinner.css';
import SORTED_LOADER from '../../assets/loader.png';
import NAX_LOADER from '../../assets/na-loader.png';
import MIDDYS_LOADER from '../../assets/middys-loader.png';
import { ApplicationState } from '../../store/RootReducer';
import { AGENCIES, APP } from '../../helpers/AppNameHelper';

interface LoadingIndicatorStyles {
  readonly overlay: any;
}
enum DEVICE_TYPE {
  Android,
  IOS,
}

const styles: LoadingIndicatorStyles = {
  overlay: {
    background: APP === AGENCIES.SORTED ? '#2d1b49' : APP === AGENCIES.NAX ? '#f4f3f3' : '#ec008c',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 99999,
    padding: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface LoadingIndicatorProps {
  readonly isLoading: boolean;
  readonly classes: any;
}

interface ComponentProps {
  readonly overrideLoading?: boolean;
}

export const Spinner = (props: LoadingIndicatorProps & ComponentProps) => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const IOSdevice = /iPhone|iPad|iPod/i.test(userAgent);
  return (
    <div>
      {props.isLoading || props.overrideLoading ? (
        <div className={props.classes.overlay}>
          <img
            className="image"
            style={{
              width: IOSdevice ? '20vw' : '7rem',
            }}
            alt="spinner"
            src={
              APP === AGENCIES.SORTED
                ? SORTED_LOADER
                : APP === AGENCIES.NAX
                ? NAX_LOADER
                : MIDDYS_LOADER
            }
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.spinner.isLoading,
});

const SpinnerComponent = connect(mapStateToProps)(withStyles(styles)(Spinner as any));

export default SpinnerComponent;
