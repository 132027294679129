import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  activeButton: {
    color: `${theme.colors.light} !important`,
    backgroundColor: theme.colors.buttonPrimary,
    height: '35px',
    minWidth: '100px',
    textTransform: 'capitalize',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: theme.colors.buttonPrimary,
    },
    borderRadius: `0 !important`,
  },
  inactiveButton: {
    color: `${theme.colors.label} !important`,
    backgroundColor: '#f6f6f6',
    height: '35px',
    minWidth: '100px',
    marginRight: '6px',
    textTransform: 'capitalize',
    borderRadius: `0 !important`,
  },
  noPadding: {
    padding: 0,
  },
});
