import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import {
  cardContainer,
  extraPadding,
  mobilePaddingCounter,
  noShadow,
  pageContainer,
} from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  card: {
    ...cardContainer,
    width: 280,
    boxShadow: 'unset',
    border: `${theme.colors.borderColor} 2px solid`,
    overflow: 'visible',
    flexShrink: 0,
    marginLeft: theme.units.margin * 6,
    backgroundColor: theme.colors.light,
    height: 'min-content',
    padding: 0,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
      marginLeft: 0,
      marginTop: theme.units.margin * 6,
      ...mobilePaddingCounter,
    },
    marginTop: theme.units.margin * 4,
  },
  headingContainer: {
    paddingTop: theme.units.padding * 8,
    paddingBottom: theme.units.padding * 8,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.secondary,
    marginBottom: theme.units.margin * 4,
  },
  middysLogo: {
    height: 34,
    objectFit: 'contain',
  },
  heading: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.light,
    fontWeight: 500,
    marginTop: theme.units.margin * 3,
  },
  headerSubTextStyle: {
    fontSize: theme.font.s,
    color: theme.colors.grey,
    marginTop: theme.units.margin / 2,
  },
  sectionStyle: {
    borderBottom: '2px solid',
    borderBottomColor: theme.colors.borderColor,
    marginBottom: theme.units.margin * 4,
    marginLeft: theme.units.margin * 4,
    marginRight: theme.units.margin * 4,
    marginTop: theme.units.margin * 4,
  },
  sectionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.units.margin * 4,
  },
  sectionTitle: {
    fontSize: theme.font.m,
    fontWeight: 500,
    color: theme.colors.darkGrey,
    marginBottom: theme.units.margin * 4,
  },
  sectionCommonText: {
    fontSize: theme.font.m,
    color: theme.colors.grey,
  },
  priceSection: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.margin * 4,
    marginLeft: theme.units.margin * 4,
    marginRight: theme.units.margin * 4,
  },
  totalText: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.darkGrey,
  },
  priceValue: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.secondary,
  },
  confirmDetailsCard: {
    ...cardContainer,
    padding: 0,
    backgroundColor: theme.colors.light,
    width: '100%',
    flex: 1,
    marginTop: theme.units.margin * 4,
  },
});
