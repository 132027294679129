import { theme } from '../../../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { loadingAnimation } from '../../../../theme/GlobalStyles';
import { SIZES } from '../../../../Constants';

export const useStyles = makeStyles({
  searchContainer: {
    marginTop: theme.units.margin * 8,
  },
  infoText: {
    fontFamily: theme.font.family2,
    fontWeight: 500,
    fontSize: theme.font.xl,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 4,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.l,
    },
  },
  searchBar: {
    borderRadius: theme.units.borderRadius,
    padding: theme.units.padding * 2,
    paddingLeft: theme.units.padding * 4,
    backgroundColor: theme.colors.light,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    width: theme.units.iconHeight,
    height: theme.units.iconHeight,
    marginRight: theme.units.padding * 6,
    marginLeft: theme.units.padding,
    opacity: 0.7,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginRight: theme.units.padding * 4,
    },
  },
  input: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    color: theme.colors.black87,
    flex: 1,
    border: 'none',
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.black38,
    },
    backgroundColor: theme.colors.light,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.s,
    },
  },
  actionButton: {
    marginLeft: 'auto',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    minWidth: 100,
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: theme.font.s,
      minWidth: 80,
    },
  },
  // Loading
  loadingInfoText: {
    marginBottom: theme.units.margin * 4,
    width: 160,
    height: theme.units.iconHeight * 1.5,
    backgroundColor: theme.colors.light,
    borderRadius: theme.units.borderRadius,
  },
  loadingIcon: {
    width: theme.units.iconHeight,
    height: theme.units.iconHeight,
    ...loadingAnimation,
    borderRadius: theme.units.borderRadius,
    marginRight: theme.units.padding * 6,
    marginLeft: theme.units.padding,
  },
  loadingInput: {
    width: 100,
    height: theme.units.iconHeight,
    ...loadingAnimation,
    borderRadius: theme.units.borderRadius,
  },
  loadingButton: {
    marginLeft: 'auto',
    width: 140,
    height: 40,
    ...loadingAnimation,
  },
});
