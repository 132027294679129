import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import {
  cardContainer,
  extraPadding,
  footerPadding,
  mobilePaddingCounter,
  pageContainer,
  root,
} from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  root: {
    ...root,
    ...footerPadding,
  },
  container: {
    ...pageContainer,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
    paddingBottom: 120,
  },
  leftCard: {
    ...cardContainer,
    ...extraPadding,
    height: 'min-content',
    boxShadow: 'unset',
    border: `${theme.colors.borderColor} 2px solid`,
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      ...mobilePaddingCounter,
    },
  },
  heading: {
    fontSize: theme.font.xl,
    fontFamily: theme.font.family,
    fontWeight: 500,
    color: theme.colors.buttonPrimary,
  },
  addressContainer: {
    marginTop: theme.units.margin * 6,
    marginBottom: theme.units.margin * 3,
  },
  locationWrapper: {
    marginTop: theme.units.margin * 1.33,
  },
  headingStyle: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.units.padding,
    color: theme.colors.black87,
    fontSize: theme.font.l,
    marginTop: theme.units.margin * 4,
  },
  addressStyle: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    borderTopColor: theme.colors.borderColor,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderRadius: theme.units.borderRadius,
    paddingLeft: theme.units.padding * 4,
    color: theme.colors.black87,
    fontSize: theme.font.s,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.light,
      backgroundColor: theme.colors.secondary,
    },
  },
  addressInput: {
    fontSize: theme.font.l,
    fontFamily: theme.font.family,
    color: theme.colors.inputText,
    backgroundColor: theme.colors.inputBackground,
    height: 52,
    flex: 1,
    padding: theme.units.padding * 3,
  },
  selectedAddressStyle: {
    borderBottomColor: theme.colors.secondary,
    color: theme.colors.light,
    backgroundColor: theme.colors.secondary,
    borderRadius: theme.units.borderRadius / 2,
  },
  popupContainer: {
    backgroundColor: theme.colors.light,
    height: '100vh',
  },
  inputCard: {
    marginBottom: theme.units.margin * 4,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.margin,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    marginLeft: theme.units.margin,
  },
  addressCard: {
    display: 'flex',
    marginTop: 12,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.darkGrey,
    paddingTop: 12,
    paddingBottom: 12,
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.colors.secondary,
      color: theme.colors.light,
    },
    color: theme.colors.black87,
    justifyContent: 'space-between',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  locationIcon: {
    width: 24,
    height: 24,
  },
  propertyContainer: {
    marginLeft: 12,
  },
  propertyHeading: {
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  propertySubHeading: {
    fontWeight: 300,
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
  },
  propertyListContainer: {
    marginTop: 24,
  },
  propertyTitle: {
    color: theme.colors.black87,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  propertySubTitle: {
    color: theme.colors.inputText,
    fontWeight: 200,
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
    marginTop: 4,
  },
  selectedAddressCard: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  checkContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    marginRight: 24,
  },
  checkIconStyle: {
    width: 24,
    height: 24,
  },
  noAddressTitle: {
    color: theme.colors.secondary,
    fontWeight: 200,
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
    marginTop: 24,
    cursor: 'pointer',
  },
  errorInput: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.error,
  },
  errorTextStyle: {
    fontSize: theme.font.xs,
    fontFamily: theme.font.family,
    color: theme.colors.error,
  },
});
