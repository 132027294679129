import React, { FC } from 'react';
import { useStyles } from './BundleCardStyles';

interface BundleCardLoadingProps {}

const BundleCardLoading: FC<BundleCardLoadingProps> = ({}) => {
  const styles = useStyles();
  return (
    <div className={styles.loadingBackground}>
      <div className={styles.offerWrapper}>
        <div className={styles.topLeftContainer}>
          <div className={styles.loadingIcon} />
          <div className={styles.loadingIconText} />
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.tagContainer}>
            <div className={styles.loadingTagTitle} />
            <div className={styles.loadingTagSubtitle} />
          </div>
          <div className={styles.loadingActionButton} />
        </div>
      </div>
    </div>
  );
};

export default BundleCardLoading;
