import React, { FC } from 'react';
import { useStyles } from './TradeCardStyles';

interface TradeCardLoadingProps {}

const TradeCardLoading: FC<TradeCardLoadingProps> = ({}) => {
  const styles = useStyles();
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.detailContainer}>
        <div className={styles.loaderIconStyle}></div>
        <div className={styles.detailsStyle}>
          <div className={styles.loaderHeadingStyle}></div>
          <div className={styles.loaderSubHeadingStyle}></div>
        </div>
      </div>
      <div className={styles.ratingSection}>
        <div className={styles.loaderIconStyle}></div>
      </div>
    </div>
  );
};

export default TradeCardLoading;
