import { makeStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.margin * 10,
  },
  homeIcon: {
    height: 18,
    cursor: 'pointer',
  },
  navArrow: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    fontWeight: 500,
    color: theme.colors.black60,
    marginLeft: theme.units.margin * 4,
    marginRight: theme.units.margin * 3,
  },
  navTitle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    fontWeight: 500,
    color: theme.colors.black60,
  },
});
