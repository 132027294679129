import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  offerContainer: {
    position: 'relative',
    width: '100%',
    border: `${theme.colors.borderColor} 2px solid`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 0,
    minHeight: 300,
  },
  overlay: {
    backgroundColor: theme.colors.black,
    opacity: 0.2,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  offerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.units.padding * 4,
    paddingLeft: theme.units.padding * 8,
    zIndex: 2,
  },
  title: {
    fontFamily: theme.font.family2,
    fontWeight: 500,
    fontSize: theme.font.xxl,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 2,
  },
  description: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.l,
    color: theme.colors.light,
    marginBottom: theme.units.margin * 4,
  },
  actionButton: {
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    color: theme.colors.light,
    borderRadius: theme.units.borderRadius / 1.5,
    padding: theme.units.padding * 2,
    paddingLeft: theme.units.padding * 4,
    paddingRight: theme.units.padding * 4,
    minWidth: 140,
    width: 'max-content',
    backgroundColor: theme.colors.buttonPrimary,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
