import { SelectOptions } from '../../components/select/SelectComponentConstants';
import * as Yup from 'yup';
import { ErrorMessages } from '../addDetails/AddDetailsConstants';
import { titleCaseLabels } from '../../helpers/helperFunctions';

export const LABELS = {
  ...titleCaseLabels({
    HEADING: 'Select preferred trades',
    HEADER_ARROW_TEXT: 'Step 3 of 4',
    HEADER_TITLE: 'Select preferred trade',
    HEADER_SUBTITLE: 'We found some trades for your job',
    BACK: 'Back',
    NEXT: 'Next',
    TIME_HEADING: 'Preferred timeframe',
    TIME: 'Time',
    TRADE: 'Select an Expert Trade',
    NOTES_TITLE: 'Enter additional notes for the trade (optional)',

    SELECT_TRADE_LIST_TITLE:
      'Please select one trade. Trades are vetted, accredited and local to your area. The trade(s) below are closest to you',
    PHOTOS: 'Upload Attachments',
    PHOTO_TITLE: 'Attachments',
    NO_TRADES: "Sorry, we don't have any trades servicing your postcode at the moment!",

    WHY_AM_I_SEEING_THIS:
      'The Trade You Select Will Contact You To Discuss Your Booking and Your Preferred Installation Date/time.',
    ELECTRICIAN_WHY_AM_I_SEEING_THIS:
      'The Trade You Select Will Contact You To Discuss Your Booking and Your Preferred Installation Date/time.',
    ADDITIONAL_DETAILS: 'Additional Details',
    AGE_OF_HOUSE: 'What is the age of your house?',
    NUMBER_OF_STORIES: 'Number of stories in your house?',
    TYPE_OF_WALLS: 'What type of walls do you have?',
    PARKING: 'Is parking available?',
    PETS: 'Do you have pets?',
    TYPE_OF_ROOF: 'What is the type of your roof?',
  }),
  NOTES_PLACEHOLDER:
    'Leave a note for the trades with any helpful information they need to know (e.g. a specific installation date and time that you would prefer, parking access etc)',
  PHOTO_SUBTITLE:
    'We value your time and to ensure a prompt response from our trades, we kindly request that you upload at least two photos - one of your switchboard and the front of your house',
  VIEW_ALL: 'Show all trades',
  VIEW_LESS: 'Show less trades',
};

export const TIME_OPTIONS: SelectOptions[] = [
  {
    name: 'Select timeframe',
    value: '',
    disabled: true,
  },
  {
    name: 'Next few days',
    value: 'Next few days',
  },
  {
    name: 'Next 2 weeks',
    value: 'Next 2 weeks',
  },
  {
    name: '2-4 weeks',
    value: '2-4 weeks',
  },
  {
    name: '+1 month or more',
    value: '+1 month or more',
  },
];

export const WALLS_OPTIONS: SelectOptions[] = [
  {
    name: 'Select wall type',
    value: '',
    disabled: true,
  },
  {
    name: 'Solid brick',
    value: 'Solid brick',
  },
  {
    name: 'Brick veneer',
    value: 'Brick veneer',
  },
  {
    name: 'Concrete',
    value: 'Concrete',
  },
  {
    name: 'Stone',
    value: 'Stone',
  },
  {
    name: 'Hebel',
    value: 'Hebel',
  },
  {
    name: 'Besser brick',
    value: 'Besser brick',
  },
  {
    name: 'Timber sheeting',
    value: 'Timber sheeting',
  },
  {
    name: 'Fibre Cement sheeting',
    value: 'Fibre Cement sheeting',
  },
  {
    name: 'Not sure',
    value: 'Not sure',
  },
  {
    name: 'Other – Not on this list',
    value: 'Other – Not on this list',
  },
];

export const ROOF_OPTIONS: SelectOptions[] = [
  {
    name: 'Select roof type',
    value: '',
    disabled: true,
  },
  {
    name: 'Tin',
    value: 'Tin',
  },
  {
    name: 'Tile',
    value: 'Tile',
  },
  {
    name: 'Not sure',
    value: 'Not sure',
  },
  {
    name: 'Other – Not on this list',
    value: 'Other – Not on this list',
  },
];

export const validationSchema = Yup.object().shape({
  trade: Yup.array().min(1, ErrorMessages.REQUIRED),
  time: Yup.string().required(ErrorMessages.REQUIRED),
  attachments: Yup.array().min(2, ErrorMessages.ATTACHMENTS),
});
