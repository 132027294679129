import { routes } from '../../Routes';

export const LABELS = {};

export const noBackArrow = ['/', routes.packages, routes.complete];

export const noLogo = [routes.complete];

export const topBarLinks = [
  {
    label: 'Home',
    target: routes.home,
  },
  {
    label: 'Packages',
    target: routes.packages,
  },
  {
    label: 'Contact Us',
    target: routes.contactUs,
  },
  {
    label: 'Register Interest',
    target: routes.registerInterest,
  },
];
