/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { put, takeEvery } from 'redux-saga/effects';
import { Property } from '../../models/common/Property';
import { MaintenanceRequestApi } from '../../models/maintenanceRequest/MaintenanceRequest';
import { PropertyRequest } from '../../models/propertyRequest/PropertyRequest';
import { TradeRequest } from '../../models/tradeRequest/TradeRequest';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import BookingService from '../../services/booking/BookingService';
import { BookingAction, BookingActions, BookingActionTypes } from '../actions/BookingActions';

function* createMainteanceRequest(action: BookingAction) {
  try {
    const { data, onSuccess } = action.data as MaintenanceRequestApi;
    yield BookingService.createMaintenance(data);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { onError } = action.data as MaintenanceRequestApi;
    if (onError) {
      onError();
    }
    console.error(error);
  }
}

function* getTradeListSaga(action: BookingAction) {
  try {
    const response: TradeResponse[] = yield BookingService.getTrades(action.data as TradeRequest);
    yield put(BookingActions.getTradesListSuccess(response));
  } catch (error) {
    console.error(error);
  }
}

function* getPropertyListSaga(action: BookingAction) {
  try {
    const response: Property[] = yield BookingService.getProperties(action.data as PropertyRequest);
    yield put(BookingActions.getPropertyListSuccess(response));
  } catch (error) {
    console.error(error);
  }
}

function* getPropertySaga(action: BookingAction) {
  try {
    const response: Property = yield BookingService.getProperty(action.data as string);
    yield put(BookingActions.getPropertySuccess(response));
    yield put(BookingActions.updatePostcode(response.postcode));
  } catch (error) {
    console.error(error);
  }
}

function* bookingWatcher(): IterableIterator<any> {
  yield takeEvery(BookingActionTypes.SUBMIT_MAINTENANCE_REQUEST, (action: BookingAction) =>
    createMainteanceRequest(action),
  );
  yield takeEvery(BookingActionTypes.GET_TRADES_LIST_REQUEST, (action: BookingAction) =>
    getTradeListSaga(action),
  );
  yield takeEvery(BookingActionTypes.GET_PROPERTY_LIST, (action: BookingAction) =>
    getPropertyListSaga(action),
  );
  yield takeEvery(BookingActionTypes.GET_PROPERTY_REQUEST, (action: BookingAction) =>
    getPropertySaga(action),
  );
}

export default bookingWatcher;
