import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './AddAddressStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import SummaryCard from '../../components/summaryCard/SummaryCard';
import { LABELS } from './AddAddressConstants';
import Footer from '../../components/footer/Footer';
import { routes } from '../../Routes';
import { Property } from '../../models/common/Property';
import { BookingActions } from '../../store/actions/BookingActions';
import { Dialog, Input, InputAdornment } from '@material-ui/core';
import { GooglePlacesHelper } from '../../helpers/GooglePlacesHelper';
import { AddressValue } from '../../helpers/AddressValue.data';
import SlideLeft from '../../helpers/transitionsHelper/SlideLeft';
import AddressForm from './AddressForm';
import { OfferType } from '../../models/common/OfferType';
import Stepper from '../../components/stepper/Stepper';
import { PropertyRequest } from '../../models/propertyRequest/PropertyRequest';
import LocationIcon from '../../assets/location-pink.png';
import CloseIcon from '../../assets/close.png';
import CheckIcon from '../../assets/check-blue.png';
import LocationWhiteIcon from '../../assets/location-white.png';
import { UserDetails } from '../../models/auth/User';
import { Step, StepType } from '../../models/common/Step';
import { getArrowText, getCurrentStep, getNextStep } from '../../helpers/StepHelper';
import { getExistingAddress } from './AddAddressUtil';

interface AddAddressProps extends RouteComponentProps {
  user: UserDetails;
  steps: Step[];
  propertyList: Property[];
  selectedProperty: Property | undefined;
  offerType: OfferType;
  updateSelectedProperty: (data: Property) => void;
  getPropertyList: (data: PropertyRequest) => void;
}

const AddAddress: FC<AddAddressProps> = ({
  history,
  user,
  steps,
  propertyList,
  selectedProperty,
  offerType,
  updateSelectedProperty,
  getPropertyList,
}) => {
  const [selectedAddress, setSelectedAddress] = useState<Property | undefined>(selectedProperty);
  const styles = useStyles();
  const [address, setAddress] = useState<AddressValue>({
    inputValue: getExistingAddress(selectedProperty),
    place: null,
  });
  const [showForm, setShowForm] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  let addressInput: HTMLInputElement;

  useEffect(() => {
    GooglePlacesHelper.createGooglePlacesAutoComplete(addressInput, (value) =>
      handleAddressChanged(value),
    );
  });

  const handleAddressChanged = (value: AddressValue) => {
    if (value.place) {
      const formattedAddress = GooglePlacesHelper.getAddressFeildsFromGoogleAPI(value);
      if (
        !(formattedAddress.streetNumber && formattedAddress.streetName && formattedAddress.suburb)
      ) {
        setShowForm(true);
      }
      setSelectedAddress(undefined);
      setAddress(value);
      setErrorMessage('');
    }
  };

  useEffect(() => {
    getPropertyList({ email: user.email });
  }, []);

  const renderPropertyAddress = (property: Property) => {
    const { unitNumber, streetNumber, streetName, state, suburb, postcode } = property;
    const address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
    const address2 = `${suburb}, ${state} ${postcode}`;
    return (
      <div className={styles.propertyContainer}>
        <div className={styles.propertyHeading}>{address1}</div>
        <div className={styles.propertySubHeading}>{address2}</div>
      </div>
    );
  };
  return (
    <div>
      <Header
        expanded
        arrowText={getArrowText(steps, StepType.PROPERTY_DETAILS)}
        expandedTitle={LABELS.HEADER_TITLE}
      />
      <Stepper activeStep={StepType.PROPERTY_DETAILS} />
      <div className={styles.container}>
        <div className={styles.leftCard}>
          <div className={styles.heading}>{LABELS.HEADING}</div>
          {propertyList && propertyList.length > 0 && (
            <div className={styles.propertyListContainer}>
              <div className={styles.propertyTitle}>We found property matches for you!</div>
              <div className={styles.propertySubTitle}>
                Select a property from the list below or add a new one by searching below.
              </div>
              <div>
                {propertyList.map((property, index) => (
                  <div
                    className={`${styles.addressCard} ${
                      selectedAddress && selectedAddress.id === property.id
                        ? styles.selectedAddressCard
                        : undefined
                    }`}
                    onClick={() => {
                      setAddress({ inputValue: '', place: null });
                      setSelectedAddress(property);
                      setErrorMessage('');
                    }}
                    onMouseOver={() => setHoveredIndex(index)}
                    onMouseOut={() => setHoveredIndex(null)}
                  >
                    <div className={styles.imageContainer}>
                      <img
                        src={
                          (selectedAddress && selectedAddress.id === property.id) ||
                          hoveredIndex === index
                            ? LocationWhiteIcon
                            : LocationIcon
                        }
                        className={styles.locationIcon}
                        alt="location"
                      />
                      {renderPropertyAddress(property)}
                    </div>
                    {selectedAddress && selectedAddress.id === property.id && (
                      <div className={styles.checkContainer}>
                        <img src={CheckIcon} className={styles.checkIconStyle} alt="check" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles.addressContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.titleStyle}>{LABELS.ADDRESS}</div>
              <div className={styles.mandatory}>*</div>
            </div>
            <Input
              disableUnderline={true}
              value={AddressValue.format(address)}
              onChange={(e: React.ChangeEvent) => {
                setAddress({
                  inputValue: (e.target as HTMLInputElement).value,
                  place: null,
                });
              }}
              inputProps={{
                maxLength: 200,
              }}
              className={`${styles.addressInput} ${errorMessage ? styles.errorInput : ''}`}
              placeholder={undefined}
              startAdornment={<InputAdornment position="end"></InputAdornment>}
              endAdornment={
                <InputAdornment position="end">
                  {address.inputValue && (
                    <img
                      src={CloseIcon}
                      style={{ width: 12, height: 12, cursor: 'pointer' }}
                      onClick={() =>
                        setAddress({
                          inputValue: '',
                          place: null,
                        })
                      }
                      alt="close"
                    />
                  )}
                </InputAdornment>
              }
              inputRef={(elem: HTMLInputElement) => (addressInput = elem)}
              fullWidth
            />
          </div>
          {errorMessage && <div className={styles.errorTextStyle}>{errorMessage}</div>}
          <div className={styles.noAddressTitle} onClick={() => setShowForm(true)}>
            Can't find your address?
          </div>
        </div>
        <SummaryCard />
      </div>
      <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.NEXT}
        primaryAction={() => {
          if ((address && address.place) || selectedAddress) {
            updateSelectedProperty(
              selectedAddress || GooglePlacesHelper.getAddressFeildsFromGoogleAPI(address),
            );
            history.push(getNextStep(steps, StepType.PROPERTY_DETAILS).route);
          } else {
            setErrorMessage('Required field');
          }
        }}
      />
      <Dialog open={showForm} fullScreen style={{ zIndex: 999999 }} TransitionComponent={SlideLeft}>
        <div className={styles.popupContainer}>
          <AddressForm
            closePopup={setShowForm}
            address={
              address && address.place
                ? GooglePlacesHelper.getAddressFeildsFromGoogleAPI(address)
                : null
            }
            goNext={() => {
              history.push(getNextStep(steps, StepType.PROPERTY_DETAILS).route);
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  steps: state.bookingState.steps,
  propertyList: state.bookingState.propertyList,
  selectedProperty: state.bookingState.selectedProperty,
  offerType: state.bookingState.offerType,
  user: state.bookingState.userDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelectedProperty: (data: Property) => dispatch(BookingActions.updateSelectedProperty(data)),
  getPropertyList: (data: PropertyRequest) => dispatch(BookingActions.getPropertyList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddAddress));
