import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './ConfirmDetailsStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import SummaryCard from '../../components/summaryCard/SummaryCard';
import Footer from '../../components/footer/Footer';
import { LABELS } from './ConfirmDetailsConstants';
import { Property } from '../../models/common/Property';
import { BundlePackage } from '../../models/bundles/Bundles';
import { BookingState } from '../../store/state/BookingState';
import {
  MaintenanceRequest,
  MaintenanceRequestApi,
} from '../../models/maintenanceRequest/MaintenanceRequest';
import { BookingActions } from '../../store/actions/BookingActions';
import { ContentType, MultipartFormData } from '../../services/MultipartFormData.data';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { routes } from '../../Routes';
import { getRequest } from './ConfirmDetailsUtils';
import { getArrowText } from '../../helpers/StepHelper';
import { Step, StepType } from '../../models/common/Step';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BundlesState } from '../../store/state/BundlesState';
import AddSmartUpgrade from './components/AddSmartUpgrade/AddSmartUpgrade';
//@ts-ignore
import TermsPdf from '../../assets/Terms.pdf';
import { sendMessageToApp, ValidMessages } from '../../helpers/MessageHelper';
import { OfferType } from '../../models/common/OfferType';

interface ConfirmDetailsProps extends RouteComponentProps {
  booking: BookingState;
  bundles: BundlesState;
  steps: Step[];
  submitMaintenanceRequest: (data: MaintenanceRequestApi) => void;
  resetData: () => void;
  isExternal: boolean;
}

const ConfirmDetails: FC<ConfirmDetailsProps> = ({
  history,
  booking,
  bundles,
  steps,
  submitMaintenanceRequest,
  resetData,
  isExternal,
}) => {
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { offerType } = booking;
  const submit = () => {
    const { photos } = booking;
    const request = getRequest(booking, bundles);
    const submitRequest: MultipartFormData[] = [
      {
        name: 'privateWorksRequest',
        content: JSON.stringify(request),
      },
    ];
    if (photos && photos.length) {
      photos.forEach((photo) => {
        submitRequest.push({
          name: 'images',
          content: {
            contentRef: {
              contentType: ContentType.png,
              name: photo.name,
            },
            data: photo,
            size: photo.size,
          },
        });
      });
    }
    if (!loading) {
      setLoading(true);
      submitMaintenanceRequest({
        data: submitRequest,
        onSuccess: () => {
          setLoading(false);
          resetData();
          history.push(routes.complete);
        },
        onError: () => {
          setOpen(true);
          setLoading(false);
        },
      });
    }
  };
  return (
    <div>
      <Header
        expanded
        arrowText={getArrowText(steps, StepType.CONFIRM)}
        expandedTitle={LABELS.HEADER_TITLE}
      />
      <div className={styles.container}>
        <SummaryCard isConfirmDetails />
        <AddSmartUpgrade />
        {offerType !== OfferType.SOLAR_FLOW_1 && (
          <div className={styles.termsContainer}>
            <div className={styles.termsCopyStyle}>
              {LABELS.TERMS_TITLE}
              <span
                className={styles.linkStyle}
                onClick={() => {
                  if (isExternal) {
                    window.open(TermsPdf, '_blank');
                  } else {
                    sendMessageToApp(ValidMessages.Pdf, TermsPdf);
                  }
                }}
              >
                terms and conditions
              </span>
            </div>
          </div>
        )}
      </div>
      <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.NEXT}
        primaryAction={() => {
          submit();
        }}
        loading={loading}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={() => setOpen(false)}
        key={'top' + 'right'}
        autoHideDuration={3000}
      >
        <Alert onClose={() => setOpen(false)} severity="error">
          Sorry, something went wrong! Please try again later.
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  steps: state.bookingState.steps,
  booking: state.bookingState,
  bundles: state.bundlesState,
  isExternal: state.token.isExternal,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submitMaintenanceRequest: (data: MaintenanceRequestApi) =>
    dispatch(BookingActions.submitMaintenanceRequest(data)),
  resetData: () => dispatch(BookingActions.resetData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmDetails));
