import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './SectionContentStyles';

interface SectionContentLoadingProps {
  reversed?: boolean;
  actionText?: string;
}

const SectionContentLoading: FC<SectionContentLoadingProps> = ({ reversed, actionText }) => {
  const styles = useStyles();
  return (
    <div className={styles.loadingSectionContainer}>
      <div className={reversed ? styles.endRow : styles.sectionRow}>
        <div className={styles.sectionContent}>
          <div className={styles.loadingSectionTitle} />
          <div className={styles.loadingSectionBody} />
        </div>
        <div className={reversed ? styles.endImageContainer : styles.sectionImageContainer}>
          <div className={styles.loadingSectionImage} />
        </div>
      </div>
      {!!actionText && <div className={styles.loadingSectionButton} />}
    </div>
  );
};

export default SectionContentLoading;
