import { theme } from '../../../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { loadingAnimation } from '../../../../theme/GlobalStyles';
import { SIZES } from '../../../../Constants';

export const useStyles = makeStyles({
  cardWrapper: {
    padding: theme.units.padding * 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.units.borderRadius,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  selectedCardWrapper: {
    borderColor: theme.colors.secondary,
    backgroundColor: 'rgba(0, 85, 171, 0.03)',
  },
  iconStyle: {
    width: theme.units.iconWidth * 2,
    height: theme.units.iconHeight * 2,
    borderRadius: (theme.units.iconWidth * 2.5) / 2,
  },
  tradeCardWrapper: {
    marginTop: theme.units.margin * 6,
  },
  tradeCardStyle: {
    marginBottom: theme.units.margin * 4,
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  detailsStyle: {
    marginLeft: theme.units.margin * 4,
  },
  nameText: {
    fontSize: theme.font.s,
    fontFamily: theme.font.family,
    // fontWeight: 300,
    color: theme.colors.black87,
    marginBottom: theme.units.margin,
    fontWeight: 'bold',
  },
  emailText: {
    fontSize: theme.font.s,
    color: theme.colors.black38,
    marginBottom: theme.units.margin * 2,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: 12,
    },
    fontWeight: 'bold',
  },
  verifiedText: {
    display: 'flex',
    gap: theme.units.margin * 2,
    alignItems: 'center',
    fontSize: theme.font.xs,
    fontWeight: 'bold',
    color: theme.colors.secondary,
  },
  ratingText: {
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    fontWeight: 500,
    textAlign: 'end',
  },
  ratingSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      position: 'relative',
      marginTop: -33,
    },
  },
  ratingStyle: {
    marginTop: theme.units.margin,
  },
  ratingColor: {
    '&.MuiRating-root': {
      // opacity: 1,
      color: theme.colors.black87,
      width: 70,
    },
    '&.MuiRating-root.Mui-disabled': {
      opacity: 0.9,
    },
  },
  addIconStyle: {
    width: theme.units.iconWidth,
    height: theme.units.iconHeight,
    transform: 'scale(1.2)',
    cursor: 'pointer',
  },
  tickIcon: {
    transform: 'scale(1.2)',
    cursor: 'pointer',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.margin,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    marginLeft: theme.units.margin,
  },
  tradesContainer: {
    marginTop: theme.units.margin * 6,
  },
  inputStyle: {
    border: 'none',
    borderRadius: theme.units.borderRadius,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontFamily: theme.font.family,
      fontWeight: 300,
    },
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    color: theme.colors.inputText,
    backgroundColor: theme.colors.inputBackground,
    flex: 1,
    padding: theme.units.padding * 4,
  },
  notesTitle: {
    color: theme.colors.inputText,
    fontWeight: 500,
    fontSize: theme.font.m,
    fontFamily: theme.font.family,
    marginBottom: 12,
  },
  loaderContainer: {
    padding: theme.units.padding * 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  loaderIconStyle: {
    width: theme.units.iconWidth * 1.5,
    height: theme.units.iconHeight * 1.5,
    borderRadius: (theme.units.iconWidth * 1.5) / 2,
    ...loadingAnimation,
  },
  loaderHeadingStyle: {
    width: 200,
    height: 20,
    ...loadingAnimation,
  },
  loaderSubHeadingStyle: {
    width: 100,
    height: 20,
    marginTop: theme.units.margin,
    ...loadingAnimation,
  },
});
