import { combineReducers } from 'redux';
import { bookingReducer } from './reducers/BookingReducer';
import { tokenReducer } from './reducers/TokenReducer';
import { BookingState } from './state/BookingState';
import { ErrorReducer } from './reducers/ErrorReducer';
import { LoadingReducer } from './reducers/LoadingReducer';
import { ErrorState } from './state/ErrorState';
import { LoadingState } from './state/LoadingState';
import { TokenState } from './state/TokenState';
import { bundlesReducer } from './reducers/BundlesReducer';
import { BundlesState } from './state/BundlesState';
import { SpinnerState } from './state/SpinnerState';
import { spinnerReducer } from './reducers/SpinnerReducer';

export interface ApplicationState {
  token: TokenState;
  spinner: SpinnerState;
  bundlesState: BundlesState;
  bookingState: BookingState;
  loadingState: LoadingState;
  errorState: ErrorState;
}

const rootReducer = combineReducers<ApplicationState>({
  token: tokenReducer,
  spinner: spinnerReducer,
  bookingState: bookingReducer,
  bundlesState: bundlesReducer,
  loadingState: LoadingReducer,
  errorState: ErrorReducer,
});

export default rootReducer;
