/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@material-ui/core';
import { pageContainer } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';

export const useStyles = makeStyles({
  secondaryButton: {
    height: 40,
    minWidth: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.darkGrey,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    fontWeight: 500,
    paddingLeft: theme.units.padding * 6,
    paddingRight: theme.units.padding * 6,
    borderRadius: theme.units.borderRadius / 2,
    border: `${theme.colors.borderColor} 4px solid`,
    backgroundColor: theme.colors.light,
    textTransform: theme.font.buttonCase as any,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      minWidth: 120,
    },
  },
  primaryButton: {
    height: 40,
    minWidth: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.light,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    paddingLeft: theme.units.padding * 6,
    paddingRight: theme.units.padding * 6,
    borderRadius: theme.units.borderRadius / 2,
    border: `${theme.colors.buttonPrimary} 4px solid`,
    backgroundColor: theme.colors.buttonPrimary,
    textTransform: theme.font.buttonCase as any,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      minWidth: 120,
    },
  },
  disabled: {
    height: 40,
    minWidth: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.disabledText,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    paddingLeft: theme.units.padding * 6,
    paddingRight: theme.units.padding * 6,
    borderRadius: theme.units.borderRadius / 2,
    border: `${theme.colors.disabled} 1px solid`,
    backgroundColor: theme.colors.disabled,
    textTransform: theme.font.buttonCase as any,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      minWidth: 120,
    },
  },
});
