import { GuestTokenResponse } from '../../models/auth/RegisterToken';
import { StoreAction } from '../StoreHelper';

export enum TokenActionTypes {
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SET_APP_NAME = 'SET_APP_NAME',
  SET_USER_ID = 'SET_USER_ID',
  SET_EXTERNAL = 'SET_EXTERNAL',
  REGISTER_CLIENT_REQUEST = 'REGISTER_CLIENT_REQUEST',
  REGISTER_CLIENT_SUCCESS = 'REGISTER_CLIENT_SUCCESS',
  REGISTER_CLIENT_ERROR = 'REGISTER_CLIENT_ERROR',
  GET_GUEST_TOKEN_REQUEST = 'GET_GUEST_TOKEN_REQUEST',
  GET_GUEST_TOKEN_SUCCESS = 'GET_GUEST_TOKEN_SUCCESS',
  GET_GUEST_TOKEN_ERROR = 'GET_GUEST_TOKEN_ERROR',
}

export type TokenActionPayload = string | boolean | GuestTokenResponse | Error;

export type TokenAction = StoreAction<TokenActionTypes, TokenActionPayload>;

export class TokenActions {
  public static registerClientRequest(): TokenAction {
    return {
      type: TokenActionTypes.REGISTER_CLIENT_REQUEST,
      data: null,
    };
  }

  public static registerClientSuccess(data: string): TokenAction {
    return {
      type: TokenActionTypes.REGISTER_CLIENT_SUCCESS,
      data,
    };
  }

  public static registerClientError(data: Error): TokenAction {
    return {
      type: TokenActionTypes.REGISTER_CLIENT_ERROR,
      data,
    };
  }

  public static setAccessToken(token: string): TokenAction {
    return {
      type: TokenActionTypes.SET_ACCESS_TOKEN,
      data: token,
    };
  }
  public static setRefreshToken(token: string): TokenAction {
    return {
      type: TokenActionTypes.SET_REFRESH_TOKEN,
      data: token,
    };
  }
  public static setAppName(appName: string): TokenAction {
    return {
      type: TokenActionTypes.SET_APP_NAME,
      data: appName,
    };
  }
  public static setUserId(userId: string): TokenAction {
    return {
      type: TokenActionTypes.SET_USER_ID,
      data: userId,
    };
  }
  public static setExternal(value: boolean): TokenAction {
    return {
      type: TokenActionTypes.SET_EXTERNAL,
      data: value,
    };
  }

  public static getGuestToken(data: string): TokenAction {
    return {
      type: TokenActionTypes.GET_GUEST_TOKEN_REQUEST,
      data,
    };
  }

  public static getGuestTokenSuccess(data: GuestTokenResponse): TokenAction {
    return {
      type: TokenActionTypes.GET_GUEST_TOKEN_SUCCESS,
      data,
    };
  }

  public static getGuestTokenError(data: Error): TokenAction {
    return {
      type: TokenActionTypes.GET_GUEST_TOKEN_ERROR,
      data,
    };
  }
}
