import React from 'react';
import { Field, FieldProps } from 'formik';
import { styled } from '@material-ui/core';
import { theme } from '../../theme/Theme';
import MiddysErrorMessage from './MiddysErrorMessage'; // Adjust the import path as necessary
import FieldLabel from '../fieldLabel/FieldLabel';

const StyledField = styled('input')(() => ({
  background: theme.colors.inputBackground,
  padding: 12,
  outline: 'none',
  border: 'none',
  fontSize: theme.font.m,
  width: '100%',
  boxSizing: 'border-box',
}));

interface MiddysTextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  required?: boolean;
}

const CustomInputComponent: React.FC<FieldProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
  field,
  form,
  ...props
}) => {
  return <StyledField {...field} {...props} />;
};

const MiddysTextField: React.FC<MiddysTextFieldProps> = ({ label, required, name, ...props }) => (
  <>
    {label && <FieldLabel required={required} label={label} />}
    <Field name={name} component={CustomInputComponent} {...props} />
    <MiddysErrorMessage name={name} />
  </>
);

export default MiddysTextField;
