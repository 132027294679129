import React, { FC } from 'react';
import { useStyles } from './BackgroundCoverStyles';
import { theme } from '../../theme/Theme';

interface BackgroundCoverLoadingProps {
  landing?: boolean;
  inset?: boolean;
  children?: React.ReactNode;
}

const BackgroundCoverLoading: FC<BackgroundCoverLoadingProps> = ({ inset, landing, children }) => {
  const styles = useStyles({});
  return (
    <div className={styles.backgroundContainer}>
      {landing ? (
        <div className={styles.backgroundWrapper}>
          <div className={styles.trapezoid} />
          <div className={styles.backgroundImage} />
        </div>
      ) : (
        <div className={styles.backgroundImage2} />
      )}

      {landing && (
        <div className={styles.textContainer}>
          <div className={styles.textWrapper}>
            <div className={styles.loadingPreHeader} />
            <div className={styles.loadingHeadline} />
            <div className={styles.loadingIntroText} />
          </div>
        </div>
      )}
      {!!children && <div className={styles.children}>{children}</div>}
      <div className={inset ? styles.inset : styles.contentStart} />
    </div>
  );
};

export default BackgroundCoverLoading;
