import React, { FC, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './BookTradeStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import {
  LABELS,
  ROOF_OPTIONS,
  TIME_OPTIONS,
  WALLS_OPTIONS,
  validationSchema,
} from './BookTradeConstants';
import TradeCard from './components/TradeCard/TradeCard';
import SummaryCard from '../../components/summaryCard/SummaryCard';
import Footer from '../../components/footer/Footer';
import { routes } from '../../Routes';
import { BookingActions, BookingActionTypes } from '../../store/actions/BookingActions';
import SelectComponent from '../../components/select/SelectComponent';
import { TradeRequest } from '../../models/tradeRequest/TradeRequest';
import Stepper from '../../components/stepper/Stepper';
import { OfferType } from '../../models/common/OfferType';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { StepType } from '../../models/common/Step';
import { getArrowText } from '../../helpers/StepHelper';
import { useFormik } from 'formik';
import ImageUpload from '../../components/imageUpload/ImageUpload';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import TradeCardLoader from './components/TradeCard/TradeCardLoader';
import { Box, Icon, IconButton, Popover, Tooltip, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { theme } from '../../theme/Theme';
import { useState } from 'react';
import AddDetailsInput from '../addDetails/components/AddDetailsInput';
import InputToggle from '../../components/inputToggle/InputToggle';
import { getAddressField } from '../confirmDetails/ConfirmDetailsUtils';

interface BookTradeProps
  extends RouteComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const BookTrade: FC<BookTradeProps> = ({
  history,
  updateSelectedTrade,
  updateSelectedTimeSlot,
  getTradeList,
  updateTradeComment,
  updatePhotos,
  updateHouseAge,
  updateNumberOfStories,
  updateWallType,
  updateParkingAvailable,
  updateHasPets,
  updateRoofType,
  steps,
  offerType,
  tradeList,
  preferredTime,
  tradeComment,
  selectedProperty,
  skills,
  photos,
  loadingList,
  accessToken,
  ageOfHouse,
  numberOfStories,
  typeOfWalls,
  typeOfRoof,
  isParkingAvailable,
  hasPets,
  selectedTrade,
}) => {
  const styles = useStyles();

  const [infoOpen, openInfoPopover] = useState<HTMLElement | null>(null);
  const [viewAll, setViewAll] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      time: preferredTime || '',
      comment: tradeComment || '',
      attachments: photos || [],
      ageOfHouse: ageOfHouse || '',
      numberOfStories: numberOfStories || '',
      typeOfWalls: typeOfWalls || '',
      isParkingAvailable: isParkingAvailable || false,
      hasPets: hasPets || false,
      typeOfRoof: typeOfRoof || '',
      trade: selectedTrade || [],
    },
    onSubmit: (values) => {
      updateSelectedTrade(values.trade);
      updateTradeComment(values.comment);
      updateSelectedTimeSlot(values.time);
      updateHouseAge(values.ageOfHouse);
      updateNumberOfStories(values.numberOfStories);
      updateWallType(values.typeOfWalls);
      updateParkingAvailable(values.isParkingAvailable);
      updateHasPets(values.hasPets);
      updateRoofType(values.typeOfRoof);
      if (values.attachments && values.attachments.length > 0) {
        updatePhotos(values.attachments);
      }
      history.push(routes.confirmDetails);
    },

    validationSchema: validationSchema,
  });
  useEffect(() => {
    getTradeList({
      postcode: selectedProperty ? selectedProperty.postcode : '',
      categories: skills,
      privateName: 'MIDDYS',
      propertyAddress: selectedProperty ? getAddressField(selectedProperty) : '',
    });
  }, [accessToken]);
  return (
    <div>
      <Header
        expanded
        arrowText={getArrowText(steps!!, StepType.TRADE_DETAILS)}
        expandedTitle={LABELS.HEADER_TITLE}
      />
      <Stepper activeStep={StepType.TRADE_DETAILS} />

      <div className={styles.container}>
        <div className={styles.leftCard}>
          <div className={styles.heading}>{LABELS.TIME_HEADING}</div>
          <div className={styles.titleContainer}>
            <div className={styles.titleStyle}>{LABELS.TIME}</div>
            <div className={styles.mandatory}>*</div>
          </div>
          <SelectComponent
            value={formik.values.time}
            setValue={formik.handleChange('time')}
            options={TIME_OPTIONS}
            error={formik.touched.time && formik.errors.time ? formik.errors.time : ''}
          />
          <div className={styles.tradesContainer}>
            <div className={styles.heading}>{LABELS.TRADE}</div>
            <div className={styles.titleContainer}>
              <div className={styles.titleStyle}>
                {LABELS.SELECT_TRADE_LIST_TITLE}
                <span className={styles.mandatory}>*</span>
              </div>

              <div className={styles.helper}>
                <IconButton
                  size="small"
                  onClick={(e) => openInfoPopover(e.currentTarget)}
                  onMouseOver={(e) => openInfoPopover(e.currentTarget)}
                >
                  <HelpOutline
                    style={{ minWidth: 30 }}
                    htmlColor={theme.colors.buttonPrimary}
                    fontSize="medium"
                  />
                </IconButton>
                <Popover
                  open={Boolean(infoOpen)}
                  anchorEl={infoOpen}
                  onClose={() => openInfoPopover(null)}
                >
                  <Box className={styles.helperBox}>{LABELS.WHY_AM_I_SEEING_THIS}</Box>
                </Popover>
              </div>
              {formik.errors.trade && formik.touched.trade && (
                <div className={styles.errorTextStyle}>{`(${formik.errors.trade as string})`}</div>
              )}
            </div>
            <div className={styles.tradeCardWrapper}>
              {loadingList ? (
                <TradeCardLoader />
              ) : (
                <div>
                  {tradeList && tradeList.length > 0 ? (
                    (viewAll ? tradeList : tradeList.slice(0, 3)).map((tradeData) => (
                      <div
                        className={styles.tradeCardStyle}
                        key={tradeData.id}
                        onClick={() => {
                          const selected = formik.values.trade[0];

                          if (selected && selected.id === tradeData.id) {
                            formik.setFieldValue('trade', []);
                          } else {
                            formik.setFieldValue('trade', [tradeData]);
                          }

                          // const selectedIndex = formik.values.trade.findIndex(
                          //   (data) => data.id === tradeData.id,
                          // );
                          // if (selectedIndex !== -1) {
                          //   const updatedTradeList = [...formik.values.trade];
                          //   updatedTradeList.splice(selectedIndex, 1);
                          //   formik.setFieldValue('trade', updatedTradeList);
                          // } else {
                          //   formik.setFieldValue('trade', [...formik.values.trade, tradeData]);
                          // }
                        }}
                      >
                        <TradeCard
                          trade={tradeData}
                          selected={
                            formik.values.trade.findIndex((data) => data.id === tradeData.id) !== -1
                          }
                        />
                      </div>
                    ))
                  ) : (
                    <div className={styles.emptyCardWrapper}>
                      <div className={styles.noTradesStyle}>{LABELS.NO_TRADES}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <Box display="flex" justifyContent="flex-end" width="100%" my={1}>
              <Typography
                variant="caption"
                className={styles.viewAllText}
                onClick={() => {
                  setViewAll((curr) => !curr);
                }}
              >
                {viewAll ? LABELS.VIEW_LESS : LABELS.VIEW_ALL}
              </Typography>
            </Box>
          </div>
          <div className={styles.heading}>{LABELS.ADDITIONAL_DETAILS}</div>

          <AddDetailsInput
            placeholder="Eg. 7 years"
            key={LABELS.AGE_OF_HOUSE}
            value={formik.values.ageOfHouse}
            setValue={formik.handleChange('ageOfHouse')}
            onBlur={() => formik.setFieldTouched('ageOfHouse')}
            touched={formik.touched.ageOfHouse}
            error={formik.errors.ageOfHouse}
            title={LABELS.AGE_OF_HOUSE}
          />

          <AddDetailsInput
            placeholder="Eg. 2"
            key={LABELS.NUMBER_OF_STORIES}
            value={formik.values.numberOfStories}
            setValue={formik.handleChange('numberOfStories')}
            onBlur={() => formik.setFieldTouched('numberOfStories')}
            touched={formik.touched.numberOfStories}
            error={formik.errors.numberOfStories}
            title={LABELS.NUMBER_OF_STORIES}
          />
          <div className={styles.questionContainer}>
            <div className={styles.titleStyle}>{LABELS.TYPE_OF_WALLS}</div>

            <SelectComponent
              value={formik.values.typeOfWalls}
              setValue={formik.handleChange('typeOfWalls')}
              options={WALLS_OPTIONS}
              error={
                formik.touched.typeOfWalls && formik.errors.typeOfWalls
                  ? formik.errors.typeOfWalls
                  : ''
              }
            />
          </div>
          <div className={styles.questionContainer}>
            <div className={styles.titleStyle}>{LABELS.TYPE_OF_ROOF}</div>

            <SelectComponent
              value={formik.values.typeOfRoof}
              setValue={formik.handleChange('typeOfRoof')}
              options={ROOF_OPTIONS}
              error={
                formik.touched.typeOfRoof && formik.errors.typeOfRoof
                  ? formik.errors.typeOfRoof
                  : ''
              }
            />
          </div>
          <div className={styles.questionContainer}>
            <div className={styles.titleStyle}>{LABELS.PARKING}</div>
            <InputToggle
              parentStyles={styles.toggleStyles}
              value={formik.values.isParkingAvailable}
              setValue={(value: boolean) => formik.setFieldValue('isParkingAvailable', value)}
            />
          </div>

          <div className={styles.questionContainer}>
            <div className={styles.titleStyle}>{LABELS.PETS}</div>
            <InputToggle
              parentStyles={styles.toggleStyles}
              value={formik.values.hasPets}
              setValue={(value: boolean) => formik.setFieldValue('hasPets', value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.notesTitle}>{LABELS.NOTES_TITLE}</div>
            <textarea
              className={styles.inputStyle}
              rows={5}
              cols={15}
              placeholder={LABELS.NOTES_PLACEHOLDER}
              value={formik.values.comment}
              onChange={(e) => formik.setFieldValue('comment', e.target.value)}
            />
          </div>
          {offerType === OfferType.BUNDLE && (
            <div className={styles.inputContainer}>
              <div className={styles.titleContainer}>
                <div className={styles.heading} style={{ marginBottom: 0 }}>
                  {LABELS.PHOTOS}
                </div>
                <div className={styles.mandatory}>{'*'}</div>
              </div>
              <ImageUpload
                title={''}
                subtitle={LABELS.PHOTO_SUBTITLE}
                files={formik.values.attachments}
                setFiles={(files: File[]) => formik.setFieldValue('attachments', files)}
                errorMessage={formik.errors.attachments as string}
                multiple
                onBlur={() => formik.setFieldTouched('attachments')}
                touched={!!formik.touched.attachments}
              />
            </div>
          )}
        </div>
        <SummaryCard />
      </div>
      <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.NEXT}
        primaryAction={() => {
          formik.handleSubmit();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  steps: state.bookingState.steps,
  offerType: state.bookingState.offerType,
  tradeList: state.bookingState.tradeList,
  preferredTime: state.bookingState.preferredTime,
  tradeComment: state.bookingState.tradeComment,
  ageOfHouse: state.bookingState.ageOfHouse,
  numberOfStories: state.bookingState.numberOfStories,
  typeOfWalls: state.bookingState.typeOfWalls,
  isParkingAvailable: state.bookingState.isParkingAvailable,
  hasPets: state.bookingState.hasPets,
  typeOfRoof: state.bookingState.typeOfRoof,
  selectedTrade: state.bookingState.selectedTrade,
  selectedProperty: state.bookingState.selectedProperty,
  skills: state.bookingState.skills,
  photos: state.bookingState.photos,
  loadingList: loadingSelector([BookingActionTypes.GET_TRADES_LIST])(state),
  accessToken: state.token.accessToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelectedTrade: (data: TradeResponse[]) =>
    dispatch(BookingActions.updateSelectedTrade(data)),
  updateSelectedTimeSlot: (data: string) => dispatch(BookingActions.updateSelectedTimeSlot(data)),
  getTradeList: (data: TradeRequest) => dispatch(BookingActions.getTradesList(data)),
  updateTradeComment: (data: string) => dispatch(BookingActions.updateTradeComment(data)),
  updatePhotos: (data: File[]) => dispatch(BookingActions.updatePhotos(data)),
  updateHouseAge: (data: string) => dispatch(BookingActions.updateHouseAge(data)),
  updateNumberOfStories: (data: string) => dispatch(BookingActions.updateNumberOfStories(data)),
  updateWallType: (data: string) => dispatch(BookingActions.updateWallType(data)),
  updateParkingAvailable: (data: boolean) => dispatch(BookingActions.updateParkingAvailable(data)),
  updateHasPets: (data: boolean) => dispatch(BookingActions.updateHasPets(data)),
  updateRoofType: (data: string) => dispatch(BookingActions.updateRoofType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookTrade));
