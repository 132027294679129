import { startCase, upperFirst, words } from 'lodash';

export const enumToOptions = (en: Object) =>
  Object.keys(en).map((k) => ({ label: en[k], value: en[k] }));

export const titleCaseLabels = (obj: any) => {
  const exclude = ['in', '\n', 'of', 'at', 'if', 'and', 'or', 'is', 'the', 'a', 'to', 'are'];
  Object.keys(obj).map(
    (k) =>
      (obj[k] = obj[k]
        .split(' ')
        .map((w: string) => (!exclude.includes(w) ? upperFirst(w) : w))
        .join(' ')),
  );
  return obj;
};
