import { routes } from '../../Routes';

export enum StepType {
  JOB_DETAILS = 'Job details',
  QUOTE_DETAILS = 'Quote details',
  USER_DETAILS = 'Your details',
  PROPERTY_DETAILS = 'Property details',
  TRADE_DETAILS = 'Timeframe',
  CONFIRM = 'Confirm',
}

export interface Step {
  route: string;
  stepType: StepType;
}

export const jobDetails: Step = {
  route: routes.jobDetails,
  stepType: StepType.JOB_DETAILS,
};

export const quoteDetails: Step = {
  route: routes.jobDetails,
  stepType: StepType.QUOTE_DETAILS,
};

export const userDetails: Step = {
  route: routes.addDetails,
  stepType: StepType.USER_DETAILS,
};

export const propertyDetails: Step = {
  route: routes.addAddress,
  stepType: StepType.PROPERTY_DETAILS,
};

export const tradeDetails: Step = {
  route: routes.selectTrade,
  stepType: StepType.TRADE_DETAILS,
};

export const confirm: Step = {
  route: routes.confirmDetails,
  stepType: StepType.CONFIRM,
};
