import { OfferType } from '../../models/common/OfferType';
import Hero from '../../assets/marketplace.jpg';
import ElectricianBG from '../../assets/electrician/hero.jpg';
import SolarBG from '../../assets/solar/hero.jpg';

export const getBackgroundImage = (offerType: OfferType | undefined) => {
  switch (offerType) {
    case OfferType.BUNDLE:
      return Hero;
    case OfferType.SOLAR_FLOW_1:
      return SolarBG;
    case OfferType.ELECTRICIAN_FLOW_1:
      return ElectricianBG;
    default:
      return Hero;
  }
};
