import { theme } from '../../theme/Theme';
import { makeStyles, withStyles } from '@material-ui/core';
import { pageContainer, root, cardContainer, loadingAnimation } from '../../theme/GlobalStyles';
import { SIZES } from '../../Constants';
import { ToggleButtonGroup } from '@material-ui/lab';

export const useStyles = makeStyles({
  root: {
    ...root,
    backgroundColor: theme.colors.light,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
  },
  container: {
    ...pageContainer,
    marginTop: 0,
    // width: '100%',
  },
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.units.padding * 6,
    paddingBottom: theme.units.padding * 6,
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingTop: theme.units.padding * 4,
      paddingBottom: 0,
    },
  },
  homeIcon: {
    height: 18,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  navArrow: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    fontWeight: 500,
    color: theme.colors.black60,
    marginLeft: theme.units.margin * 4,
    marginRight: theme.units.margin * 3,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  navTitle: {
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    fontWeight: 500,
    color: theme.colors.black60,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  offersGrid: {
    display: 'grid',
    gridGap: theme.units.margin * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  heroImageContainer: {
    width: '100%',
    position: 'relative',
    height: 400,
    display: 'flex',
    alignItems: 'center',
  },
  heroImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  title: {
    fontFamily: theme.font.family2,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    color: theme.colors.secondary,
    marginTop: theme.units.margin * 4,
    marginBottom: theme.units.margin * 4,
  },
});
