import React, { FC } from 'react';
import { useStyles } from './OfferStyles';

interface OfferProps {
  title: string;
  description?: string;
  button: string;
  image: string;
  onClick: () => void;
}

const Offer: FC<OfferProps> = ({ title, description, button, image, onClick }) => {
  const styles = useStyles();
  return (
    <div
      className={styles.offerContainer}
      style={{ backgroundImage: `url(${image})`, backgroundPosition: '0% 5%' }}
      onClick={onClick}
    >
      <div className={styles.overlay} />
      <div className={styles.offerWrapper}>
        <div className={styles.title}>{title}</div>
        {!!description && <div className={styles.description}>{description}</div>}
        <div className={styles.actionButton}>{button}</div>
      </div>
    </div>
  );
};

export default Offer;
