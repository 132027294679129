/* eslint-disable import/prefer-default-export */
import { FilterItem } from './FilterBarConstants';

export const isFilterActive = (filters: FilterItem[], check: string): boolean => {
  const filter = filters.find((filter) => filter.value === check);
  if (filter) {
    return filter.active;
  }
  return false;
};

export const areAllFiltersOff = (filters: FilterItem[]) => {
  return !filters.find((filter) => filter.active);
};
