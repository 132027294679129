import React, { FC } from 'react';
import Header from '../../components/header/Header';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { LABELS } from './RegisterInterestContants';
import { ListBox } from '../../components/listBox/ListBox';
import rgeisterInterestImage from '../../assets/register-interest.png';
import { useStyles } from './RegisterInterestStyles';
import ContentContainer from '../../components/containers/ContentContainer';
import RegisterInterestForm from '../registerInterestForm/RegisterInterestForm';
import PageContainer from '../../components/containers/PageContainer';
import CrumbNav from '../../components/crumbNav/CrumbNav';

interface RegisterInterestProps {}

const RegisterInterest: FC<RegisterInterestProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  return (
    <>
      <Header />
      <PageContainer>
        <ContentContainer>
          <CrumbNav steps={[{ label: 'Register Interest' }]} />
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item md={5} xs={12}>
              <ListBox spacing={5}>
                <Typography color="secondary" variant="h1" className="title">
                  {LABELS.heading}
                </Typography>
                <Typography variant="h4" color="textSecondary">
                  {LABELS.description}
                </Typography>
              </ListBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <img src={rgeisterInterestImage} />
            </Grid>
          </Grid>
        </ContentContainer>
        <div className={classes.formBackgroundWrapper}>
          <ContentContainer>
            <Box px={isSmallScreen ? 2 : 10}>
              <Box className={classes.formContainer}>
                <Box mb={4}>
                  <Typography color="secondary" variant="h2" className="title">
                    {LABELS.heading}
                  </Typography>
                </Box>
                <Typography color="secondary" variant="h3" className="title">
                  {LABELS.formSubheading}
                </Typography>

                <Box mt={3}>
                  <RegisterInterestForm type="registerInterest" />
                </Box>
              </Box>
            </Box>
          </ContentContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default RegisterInterest;
