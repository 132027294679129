import { wait } from '@testing-library/user-event/dist/utils';
import { bundleListMock } from '../../mock/BundleMock';
import {
  BundlePackage,
  BundleResponse,
  CustomerInterestsRequest,
} from '../../models/bundles/Bundles';
import ApiService from '../ApiService';
import { ServiceType } from '../ServiceType.data';
import { useFetchQuery } from '../reactQuery/reactQuery';

function bundlesService() {
  function getBundles(): Promise<BundleResponse> {
    const service: ApiService = new ApiService(ServiceType.booking);
    return service.get({
      query: {
        privateName: 'MIDDYS',
      },
      route: ['packages'],
    });
  }

  function postMailSignup(data: CustomerInterestsRequest): Promise<void> {
    const service: ApiService = new ApiService(ServiceType.booking);
    return service.post(
      {
        route: ['customer-interests'],
      },
      data,
    );
  }

  function useGetBundles() {
    const { data, isLoading } = useFetchQuery(['packages'], getBundles);
    return { bundles: data, loadingPackages: isLoading };
  }

  return {
    getBundles,
    postMailSignup,
    useGetBundles,
  };
}

export default bundlesService();
