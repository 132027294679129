import React, { FC } from 'react';
import { useStyles } from './AddDetailsInputStyles';

interface AddDetailsInputProps {
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
  title?: string;
  mandatory?: boolean;
  onBlur?: () => void;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
}

const AddDetailsInput: FC<AddDetailsInputProps> = ({
  title,
  placeholder,
  value,
  setValue,
  mandatory,
  onBlur,
  touched,
  error,
  disabled,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.inputCard}>
      {title && (
        <div className={styles.titleContainer}>
          <div className={styles.titleStyle}>{title}</div>
          <div className={styles.mandatory}>{mandatory ? '*' : ''}</div>
        </div>
      )}
      <input
        type="text"
        className={`${styles.inputStyle} ${touched && error ? styles.errorInput : undefined}`}
        placeholder={placeholder}
        onChange={(event) => setValue(event.target.value)}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
      />
      {touched && error && <div className={styles.errorTextStyle}>{error}</div>}
    </div>
  );
};

export default AddDetailsInput;
