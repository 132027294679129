import * as redux from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagas from './RootSaga';
import reducers, { ApplicationState } from './RootReducer';
import moment from 'moment';

function saveToLocalStorage(state: ApplicationState) {
  try {
    const { token, bookingState, bundlesState } = state;
    const serialisedState = JSON.stringify({
      token: {
        clientToken: token.clientToken,
        isExternal: token.isExternal,
      },
      bookingState: {
        offerType: bookingState.offerType,
        steps: bookingState.steps,
        skills: bookingState.skills,
      },
      bundlesState: {
        selectedBundle: bundlesState.selectedBundle,
      },
    });
    const currentDate = moment().format('DD/MM/YYYY');
    localStorage.setItem('state', serialisedState);
    localStorage.setItem('stateCreatedDate', currentDate);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem('state');
    const stateCreatedDate = localStorage.getItem('stateCreatedDate');
    if (serialisedState === null) {
      return undefined;
    }
    if (stateCreatedDate) {
      const date = moment(stateCreatedDate, 'DD/MM/YYYY');
      if (moment().diff(date, 'days') > 60) {
        localStorage.clearAll(); // clearing out the local storage to be fetched again
        // window.location.href = `/#/login`; // clearing out the redux store and resetting the app state
      }
    }
    return JSON.parse(serialisedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;
const sagaMiddleware = createSagaMiddleware();

// Creates store and sets it into singleton (Store.ts/store)
const createAppStore = (testing?: boolean | undefined | null) => {
  const persistedStore = loadFromLocalStorage();

  store = redux.createStore(
    reducers,
    persistedStore,
    composeEnhancers(redux.applyMiddleware(sagaMiddleware)),
  );

  store.subscribe(() => saveToLocalStorage(store.getState()));

  sagaMiddleware.run(sagas);

  return { store };
};

export type AppStore = redux.Store<ApplicationState>;
export let store: AppStore;

// initialize
createAppStore();
