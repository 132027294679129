import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './BookTimeStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import SummaryCard from '../../components/summaryCard/SummaryCard';
import calendar from '../../assets/calendar.png';
import Carousel from 'react-elastic-carousel';
import rightArrow from '../../assets/right-arrow.png';
import leftArrow from '../../assets/left-arrow.png';
import cancelIcon from '../../assets/cancel.png';
import Footer from '../../components/footer/Footer';
import { routes } from '../../Routes';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { createMuiTheme, ThemeProvider, InputAdornment, IconButton } from '@material-ui/core/';
import { getDayAndDate } from './BookTimeUtil';
import { theme } from '../../theme/Theme';
import { classNameGenerator } from '../../theme/GlobalStyles';
import moment from 'moment';
import { LABELS, TIME_SOLTS } from './BookTimeConstants';
import { DayAndDate } from '../../models/common/DateAndTime';
import { BookingActions } from '../../store/actions/BookingActions';

interface BookTimeProps extends RouteComponentProps {
  // updatedSelectedDayAndDate: (data: DayAndDate[]) => void;
  // selectedDayAndDate: DayAndDate[];
}

const BookTime: FC<BookTimeProps> = ({
  history,
  // updatedSelectedDayAndDate,
  // selectedDayAndDate,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const { dayAndDate, month, year, firstDateIndex } = getDayAndDate(selectedMonth, []);
  const [initialActiveIndex, setInitialActiveIndex] = useState<number>(firstDateIndex);
  const [selectedItem, setSelectedItem] = useState<DayAndDate | null>(null);

  const styles = useStyles();
  const defaultMaterialTheme = createMuiTheme({
    spacing: 2,
    palette: {
      primary: { main: theme.colors.secondary },
    },
  });

  const isSelected = (currentItem: DayAndDate) => {
    if (selectedItem) {
      return !!(
        currentItem.day === selectedItem.day &&
        currentItem.date === selectedItem.date &&
        currentItem.month === selectedItem.month
      );
    } else {
      return false;
    }
  };
  const isTimeSlotSelected = (timeSlot: string) => {
    // if (selectedItem && selectedDayAndDate && selectedDayAndDate.length > 0) {
    //   const selectedItemIndex = selectedDayAndDate.findIndex(
    //     (data) =>
    //       selectedItem.day === data.day &&
    //       selectedItem.date === data.date &&
    //       timeSlot === data.time,
    //   );
    //   if (selectedItemIndex !== -1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return false;
    // }
  };
  return (
    <div>
      <Header
        expanded
        arrowText={LABELS.HEADER_ARROW_TEXT}
        expandedTitle={LABELS.HEADER_TITLE}
        expandedSubtext={LABELS.HEADER_SUBTITLE}
      />
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.leftCard}>
            <div className={styles.dateValueContainer}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <KeyboardDatePicker
                    className={styles.calendarStyle}
                    value={selectedMonth}
                    placeholder="Select month"
                    onChange={(date, value) => {
                      if (value) {
                        setSelectedMonth(new Date(value));
                        setInitialActiveIndex(firstDateIndex);
                      }
                    }}
                    minDate={moment(moment().startOf('month'))}
                    openTo="month"
                    views={['year', 'month']}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <img src={calendar} className={styles.iconStyle} alt="arrow" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoOk
                    clearable={false}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ flex: 1, flexDirection: 'row', paddingLeft: 12, paddingRight: 12 }}>
              <Carousel
                isRTL={false}
                pagination={false}
                itemsToShow={6}
                itemsToScroll={2}
                itemPosition={'START'}
                key={`${month}_${year}`}
                initialActiveIndex={initialActiveIndex}
                onChange={(currentItemObject: any) => {
                  const firstDayDetails = dayAndDate[currentItemObject.index];
                  const dateObj = new Date(`${firstDayDetails.month}-${firstDayDetails.year}`);
                  if (moment(selectedMonth).format('MMMM') !== firstDayDetails.month) {
                    setInitialActiveIndex(currentItemObject.index);
                    setSelectedMonth(dateObj);
                  }
                }}
                renderArrow={({ type, onClick }) => {
                  const pointer = type === 'PREV' ? leftArrow : rightArrow;
                  return (
                    <div
                      onClick={onClick}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img src={pointer} className={styles.iconStyle} alt="arrow" />
                    </div>
                  );
                }}
              >
                {dayAndDate.map((item, i) => (
                  <div
                    className={classNameGenerator([
                      styles.dayCard,
                      isSelected(item) ? styles.selectedDayCard : '',
                    ])}
                    onClick={() => {
                      // if (selectedDayAndDate && selectedDayAndDate.length < 2) {
                      //   setSelectedItem(item);
                      // }
                    }}
                  >
                    <div>{item.day}</div>
                    <div>{item.date}</div>
                  </div>
                ))}
              </Carousel>
            </div>
            <div
              className={classNameGenerator([
                styles.timeSlotStyle,
                // isTimeSlotSelected(TIME_SOLTS.FIRST_SLOT) ? styles.disabledTimeSlotStyle : '',
              ])}
              onClick={() => {
                // if (selectedItem && !isTimeSlotSelected(TIME_SOLTS.FIRST_SLOT)) {
                //   const updatedList = [...selectedDayAndDate];
                //   updatedList.push({ ...selectedItem, time: TIME_SOLTS.FIRST_SLOT });
                //   updatedSelectedDayAndDate(updatedList);
                //   setSelectedItem(null);
                // }
              }}
            >
              <div>{TIME_SOLTS.FIRST_SLOT}</div>
              <img src={rightArrow} className={styles.iconStyle} alt="arrow" />
            </div>
            <div
              className={classNameGenerator([
                styles.timeSlotStyle,
                // isTimeSlotSelected(TIME_SOLTS.SECOND_SLOT) ? styles.disabledTimeSlotStyle : '',
              ])}
              onClick={() => {
                // if (selectedItem && !isTimeSlotSelected(TIME_SOLTS.SECOND_SLOT)) {
                //   const updatedList = [...selectedDayAndDate];
                //   updatedList.push({ ...selectedItem, time: TIME_SOLTS.SECOND_SLOT });
                //   updatedSelectedDayAndDate(updatedList);
                //   setSelectedItem(null);
                // }
              }}
            >
              <div>{TIME_SOLTS.SECOND_SLOT}</div>
              <img src={rightArrow} className={styles.iconStyle} alt="arrow" />
            </div>
            <div className={styles.chipWrapper}>
              {/* {selectedDayAndDate && selectedDayAndDate.length > 0
                ? selectedDayAndDate.map((data) => (
                    <div>
                      {data.time ? (
                        <div className={styles.chipStyle}>
                          <div>{`${data.day}, ${data.date} ${data.month} ${data.year}`}</div>
                          <img
                            src={cancelIcon}
                            className={styles.cancelIconStyle}
                            onClick={() => {
                              const itemIndex = selectedDayAndDate.findIndex(
                                (item) =>
                                  item.day === data.day &&
                                  item.date === data.date &&
                                  item.month === data.month,
                              );
                              const updatedList = [...selectedDayAndDate];
                              if (itemIndex !== -1) {
                                updatedList.splice(itemIndex, 1);
                              }
                              updatedSelectedDayAndDate(updatedList);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  ))
                : null} */}
            </div>
          </div>
          <SummaryCard />
        </div>
      </div>
      <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.NEXT}
        primaryAction={() => history.push(routes.confirmDetails)}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  // selectedDayAndDate: state.booking.selectedDayAndDate,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // updatedSelectedDayAndDate: (data: DayAndDate[]) =>
  //   dispatch(BookingActions.updateSelectedTimeSlot(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookTime));
