import { SelectOptions } from '../../components/select/SelectComponentConstants';
import * as Yup from 'yup';
import { ErrorMessages } from '../addDetails/AddDetailsConstants';
import { titleCaseLabels } from '../../helpers/helperFunctions';

export const LABELS = {
  ...titleCaseLabels({
    HEADING: 'Job details',
    HEADING_QUOTE: 'Quote details',
    HEADER_ARROW_TEXT: 'Step 1 of 4',
    HEADER_TITLE: 'Job information',
    HEADER_TITLE2: 'Quote information',
    HEADER_SUBTITLE: 'Step 1 of 4',
    PHOTO_TITLE: 'Photos',
    BACK: 'Back',
    NEXT: 'Next',
    QUOTE_TYPE_TITLE: 'Type',
    QUOTE_OPTION_TITLE: 'What is most important to you?',
    ENERGY_SAVING_TITLE: 'Energy savings',
    PAYBACK_PERIOD_TITLE: 'Payback period',
    LOCATION: 'Location',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    PHOTOS: 'Photos',
    CURRENT_BILL: 'Add a current bill',
    PHOTO_BILL_UPLOAD_SUBTITLE: 'Upload a recent photo of your electricity bill',
  }),
  PHOTO_UPLOAD_SUBTITLE:
    'Attach at least two photos, showing the site and affected areas, appliances, brand model and size (if applicable)',
};

export const QUOTE_TYPES: SelectOptions[] = [
  { name: 'What type of quote are you interested in?', value: '', disabled: true },
  { name: 'New solar installation', value: 'New solar installation' },
  { name: 'Solar upgrade', value: 'Solar upgrade' },
  { name: 'New battery installation', value: 'New battery installation' },
  { name: 'Battery upgrade', value: 'Battery upgrade' },
  { name: 'New solar and battery installation', value: 'New solar and battery installation' },
  { name: 'Solar and battery upgrade', value: 'Solar and battery upgrade' },
];

export const LOCATION_OPTIONS: SelectOptions[] = [
  {
    name: 'What area is affected?',
    value: '',
    disabled: true,
  },
  {
    name: 'Bedroom',
    value: 'BEDROOM',
  },
  {
    name: 'Living room',
    value: 'LIVING_ROOM',
  },
  {
    name: 'Dining room',
    value: 'DINING_ROOM',
  },
  {
    name: 'Rumpus/Recreation room',
    value: 'RUMPUS_ROOM',
  },
  {
    name: 'Study',
    value: 'STUDY',
  },
  {
    name: 'Kitchen',
    value: 'KITCHEN',
  },
  {
    name: 'Pantry',
    value: 'PANTRY',
  },
  {
    name: 'Laundry',
    value: 'LAUNDRY',
  },
  {
    name: 'Bathroom',
    value: 'BATHROOM',
  },
  {
    name: 'Hallway',
    value: 'HALLWAY',
  },
  {
    name: 'Entrance',
    value: 'ENTRANCE',
  },
  {
    name: 'Outdoor/External',
    value: 'OUTDOOR_EXTERNAL',
  },
  {
    name: 'Pool house',
    value: 'POOL_HOUSE',
  },
  {
    name: 'Equipment room',
    value: 'EQUIPMENT_ROOM',
  },
  {
    name: 'Garage',
    value: 'GARAGE',
  },
  {
    name: 'Carport',
    value: 'CARPORT',
  },
  {
    name: 'Shed',
    value: 'SHED',
  },
  {
    name: 'Loft',
    value: 'LOFT',
  },
  {
    name: 'Basement',
    value: 'BASEMENT',
  },
  {
    name: 'Gym',
    value: 'GYM',
  },
  {
    name: 'Sustainability',
    value: 'SUSTAINABILITY',
  },
  {
    name: 'Technology',
    value: 'TECHNOLOGY',
  },
];

export const ATTACHMENT_ERROR = 'Attach at least two photos';
export const ATTACHMENT_ERROR_SOLAR = 'Attach at least one photo';

export const validationSchema = Yup.object().shape({
  location: Yup.string().required(ErrorMessages.REQUIRED),
  title: Yup.string().required(ErrorMessages.REQUIRED),
  description: Yup.string().required(ErrorMessages.REQUIRED),
  attachments: Yup.array().min(2, ATTACHMENT_ERROR),
});

export const solarValidationSchema = Yup.object().shape({
  quoteType: Yup.string().required(ErrorMessages.REQUIRED),
  mostImportant: Yup.string().required(ErrorMessages.REQUIRED),
  attachments: Yup.array().min(1, ATTACHMENT_ERROR_SOLAR),
});
