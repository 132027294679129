import { TokenState } from '../state/TokenState';
import { TokenAction, TokenActionTypes } from '../actions/TokenActions';
import { GuestTokenResponse } from '../../models/auth/RegisterToken';

const initialState: TokenState = {
  accessToken: false,
  refreshToken: false,
  appName: 'SORTED',
  userId: '',
  isExternal: true,
  clientToken: false,
};

export const tokenReducer = (state = initialState, action: TokenAction) => {
  switch (action.type) {
    case TokenActionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.data as string,
      };
    case TokenActionTypes.SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.data as string,
      };
    case TokenActionTypes.SET_APP_NAME:
      return {
        ...state,
        appName: action.data as string,
      };
    case TokenActionTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.data as string,
      };
    case TokenActionTypes.SET_EXTERNAL:
      return {
        ...state,
        isExternal: action.data as boolean,
      };
    case TokenActionTypes.REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        clientToken: action.data as string,
      };
    case TokenActionTypes.GET_GUEST_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: (action.data as GuestTokenResponse).authToken,
        refreshToken: (action.data as GuestTokenResponse).refreshToken,
      };

    default:
      return state;
  }
};
