import * as Yup from 'yup';
import { SelectOptions } from '../select/SelectComponentConstants';

export const LABELS = {
  TITLE: 'Signup Form',
  SUBTITLE: 'Sign up here to get notified for when our services become available in your area',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'Email',
  BUTTON: 'Sign Up',
  ENTER_NAME: 'Enter name',
  INTERESTED: "You're interested in",
  PROCESSING: 'Processing',
  THANK_YOU: 'Thank you for registering your interest',
  CLOSE: 'Close',
};

export const ErrorMessages = {
  REQUIRED: 'Required field',
  EMAIL: 'Enter a valid email',
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(ErrorMessages.REQUIRED)
    .matches(/^[a-z ,.'-]+$/i, "Only Albhabets and [,.'-] allowed"),
  lastName: Yup.string()
    .required(ErrorMessages.REQUIRED)
    .matches(/^[a-z ,.'-]+$/i, "Only Albhabets and [,.'-] allowed"),
  email: Yup.string().required(ErrorMessages.EMAIL).email(ErrorMessages.EMAIL),
  interested: Yup.string().required(ErrorMessages.REQUIRED),
});

export const INTERESTED_OPTIONS: SelectOptions[] = [
  {
    name: 'Select',
    value: '',
    disabled: true,
  },
  {
    name: 'Kitchen Living Solutions',
    value: 'Kitchen Living Solutions',
  },
  {
    name: 'Bathroom Living Solutions',
    value: 'Bathroom Living Solutions',
  },
  {
    name: 'Outdoor Living Solutions',
    value: 'Outdoor Living Solutions',
  },
  {
    name: 'Smart Home Solutions',
    value: 'Smart Home Solutions',
  },
  {
    name: 'Electrical Switchboard Upgrade',
    value: 'Electrical Switchboard Upgrade',
  },
  {
    name: 'Book an Electrician',
    value: 'Book an Electrician',
  },
  {
    name: 'Get a FREE Solar Proposal',
    value: 'Get a FREE Solar Proposal',
  },
];
