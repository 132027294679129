import React, { FC } from 'react';
import { OfferCategory } from '../../../models/bundles/Bundles';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './HighlightedPackageStyles';
import { getCategoryDescription } from '../CustomerLandingUtils';
import { useHistory } from 'react-router';
import ActionButton from '../../../components/actionButton/ActionButton';
import { BundleCardType } from '../../../components/bundleCard/BundleCardConstants';
import { theme } from '../../../theme/Theme';

interface HighlightedPackageProps {
  servicePackage: BundleCardType;
  actionLabel: string;
}

const HighlightedPackage: FC<HighlightedPackageProps> = ({ servicePackage, actionLabel }) => {
  const { category, location } = servicePackage;
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <img className={classes.image} src={category!.image} alt={category!.title} />
      <div className={classes.descContainer}>
        <Box display="flex" flexDirection="column" gridGap="0.5em">
          <Typography variant="h3">{category!.title}</Typography>
          <Typography className={classes.description} color="textSecondary">
            {getCategoryDescription(category!.title)}
          </Typography>
        </Box>

        <ActionButton
          onClick={() => {
            if (location?.includes('https')) {
              window.location.href = location;
            } else {
              history.push!(location!);
            }
          }}
        >
          {actionLabel}
        </ActionButton>
      </div>
    </div>
  );
};

export default HighlightedPackage;
