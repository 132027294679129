import * as axios from 'axios';

import { AppSettings } from '../AppSettings';
import AxiosInstance from './AxiosInstance';
import { HttpContentType } from './HttpContentType';
import { QueryPath } from './QueryPath.data';
import { ServiceType } from './ServiceType.data';
import { RefreshTokenRequest } from '../models/auth/RefreshTokenRequest';
import { sendMessageToApp, ValidMessages } from '../helpers/MessageHelper';
import { store } from '../store/Store';
import { MultipartFormData } from './MultipartFormData.data';
import { TokenActions } from '../store/actions/TokenActions';
import { GuestTokenResponse } from '../models/auth/RegisterToken';

/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly customURL: string | undefined;
  protected readonly customHeaders: any | undefined;
  protected tokenSubscribers: any = [];

  constructor(serviceType: ServiceType, customURL?: string, customHeaders?: any) {
    this.serviceType = serviceType;
    this.customURL = customURL;
    this.customHeaders = customHeaders;
  }

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract get<T = void>(path: QueryPath): Promise<T> | T;

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract post<T = void>(path: QueryPath, body: any): Promise<T> | T;

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract postOtherService<T = void>(
    path: string,
    body: any,
    token: string,
  ): Promise<T> | T;

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract put<T = void>(path: QueryPath, body: any): Promise<T> | T;

  // Real implementation in ApiService, mock implementation in __mocks__/ApiService
  public abstract delete<T = void>(path: QueryPath): Promise<T>;

  /* tslint:disable:cyclomatic-complexity */
  public processError(error: any) {
    const errorCode = error.response ? error.response.status || 500 : 500;

    switch (errorCode) {
      case 404:
        return new Error('The request is not found');
      case 500:
        return new Error('Internal server error');
      case 400:
      case 422: {
        if (error.response.data.errors) {
          const err = error.response.data.errors;

          if (err instanceof Array) {
            const errArr = err;

            if (errArr.length > 0 && errArr[0]) {
              if (errArr[0].message) {
                return new Error(errArr[0].message.toString());
              } else if (errArr[0].Message) {
                return new Error(errArr[0].Message.toString());
              } else {
                return new Error(errArr[0].toString());
              }
            }
          } else if (err.message) {
            return new Error(err.message.toString());
          } else if (err.Message) {
            return new Error(err.Message.toString());
          } else {
            return new Error(err.toString());
          }
        }

        return new Error('Internal server error');
      }
    }
    return error;
  }

  /* tslint:enable */
  protected getConfig(contentType: HttpContentType): axios.AxiosRequestConfig {
    const authToken = store.getState().token.accessToken;
    if (!!this.customHeaders) {
      return {
        headers: this.customHeaders,
      };
    }
    const headers = this.serviceType.includes('api')
      ? {
          'Content-Type': contentType.toString(),
          'Authorization': `Bearer ${authToken}`,
        }
      : {
          'Content-Type': contentType.toString(),
        };

    return {
      headers,
    };
  }

  /* tslint:enable */
  protected getConfigOtherService(
    contentType: HttpContentType,
    token: string,
  ): axios.AxiosRequestConfig {
    const headers = {
      'Content-Type': contentType.toString(),
      'Authorization': `Bearer ${token}`,
    };
    return {
      headers,
    };
  }

  protected isAuthTokenRequired(path: string): boolean {
    return path.includes('/api');
  }
  protected getAxiosInstanceOther(): axios.AxiosInstance {
    const instance = AxiosInstance.create();

    instance.interceptors.response.use(
      (response): any => {
        return response;
      },
      (error): any => {
        return Promise.reject(error);
      },
    );
    return instance;
  }

  protected getAxiosInstance(): axios.AxiosInstance {
    const instance = AxiosInstance.create();
    let triedRefreshingToken = false;
    const baseUrl = this.customURL || AppSettings.server.baseUrl;
    const { refreshToken, isExternal, clientToken } = store.getState().token;

    instance.interceptors.response.use(
      (response): any => {
        return response;
      },
      (error): any => {
        const originalRequest = error.config;
        if (error.response.status === 401 && this.isAuthTokenRequired(error.config.url)) {
          if (!triedRefreshingToken) {
            sendMessageToApp(ValidMessages.Log, 'Auth token expired. Attempting refresh.');
            triedRefreshingToken = true;
            const tokenRequestData: RefreshTokenRequest = {
              refreshToken,
            };
            return instance
              .post(
                `${baseUrl}/${ServiceType.RefreshToken}`,
                tokenRequestData,
                isExternal
                  ? {
                      headers: {
                        'Authorization': `Basic ${clientToken}`,
                      },
                    }
                  : {},
              )
              .then(
                (response) => {
                  const refreshedTokens: GuestTokenResponse = {
                    authToken: response.data.authToken,
                    refreshToken: response.data.refreshToken,
                  };
                  if (!isExternal) {
                    sendMessageToApp(ValidMessages.UpdateToken, refreshedTokens);
                  }
                  // UPDATING TOKENS IN APP AND WEB
                  store.dispatch(TokenActions.getGuestTokenSuccess(refreshedTokens));
                  originalRequest.headers.Authorization = `Bearer ${refreshedTokens.authToken}`;
                  return instance(originalRequest);
                },
                (ReAttemptError: any) => {
                  if (!isExternal) {
                    sendMessageToApp(ValidMessages.Log, 'Token refresh attemp failed.');
                    sendMessageToApp(ValidMessages.SessionExpired);
                  }
                },
              );
          } else {
            if (isExternal) {
              sendMessageToApp(ValidMessages.SessionExpired);
            }
          }
        }
        return Promise.reject(error);
      },
    );
    return instance;
  }

  /// Generates url: {AppSettings.service.baseUrl}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  /// Need this to be able to write the mocks properly. Don't want to parse urls.
  /// Query params with null, undefined or empty string won't be appended to the url.

  protected getUrl(path: QueryPath): string {
    const baseUrl = this.customURL || AppSettings.server.baseUrl;
    let url = `${baseUrl}/${this.serviceType}`;

    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route) {
            url += `/${route}`;
          }
        }
      }

      if (path.query) {
        let separator = '?';

        for (const name in path.query) {
          if (path.query[name] !== undefined && path.query[name] !== null) {
            url += `${separator}${encodeURI(name)}=${encodeURI(path.query[name]!.toString())}`;
            separator = '&';
          }
        }
      }
    }

    return url;
  }

  protected prepareMultiPartForm(data: MultipartFormData[]): FormData {
    const formData = new FormData();

    for (const item of data) {
      if (typeof item.content === 'string') {
        // Json string
        formData.append(item.name, item.content);
      } else {
        // Blob
        formData.append(item.name, item.content.data!, item.content.contentRef.name);
      }
    }

    return formData;
  }
}
