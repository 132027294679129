import { theme } from '../../../../theme/Theme';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  postcodeContainer: {
    paddingTop: theme.units.padding * 2,
    paddingBottom: theme.units.padding * 2,
    paddingLeft: theme.units.padding * 6,
    paddingRight: theme.units.padding * 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `${theme.colors.borderColor} 4px solid`,
    backgroundColor: theme.colors.filterBackground,
    position: 'relative',
  },
  border: {
    border: `${theme.colors.buttonPrimary} 4px solid !important`,
  },
  location: {
    color: theme.colors.secondary,
    fontWeight: 500,
    fontFamily: theme.font.family,
    fontSize: theme.font.m,
    marginRight: theme.units.padding * 6,
    border: 'none',
    width: 40,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {},
    backgroundColor: theme.colors.filterBackground,
  },
  icon: {
    position: 'absolute',
    right: theme.units.padding * 2,
    width: theme.units.iconHeight / 1.2,
    height: theme.units.iconHeight / 1.2,
  },
});
