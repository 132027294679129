import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './AddressCheckFooterStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LABELS } from './AddressCheckFooterConstants';
import alert from '../../assets/alert.png';
import { BundlePackage } from '../../models/bundles/Bundles';
import { isAvailable } from '../../helpers/PostcodeHelper';
import PostcodeSearch from '../../containers/marketplace/components/postcodeSearch/PostcodeSearch';
import { BookingActions } from '../../store/actions/BookingActions';

interface AddressCheckFooterProps extends RouteComponentProps {
  selectedBundle: BundlePackage | undefined;
  onNextText: string;
  price?: number;
  selectedPostcode: string | undefined;
  onNext: () => void;
  updatePostcode: (data: string) => void;
}

const AddressCheckFooter: FC<AddressCheckFooterProps> = ({
  history,
  location,
  selectedBundle,
  price,
  onNextText,
  selectedPostcode,
  updatePostcode,
  onNext,
}) => {
  const styles = useStyles();
  const [postcode, setPostcode] = useState<string>(selectedPostcode || '');

  const disabled = useMemo<boolean>(() => {
    if (postcode.length !== 4) {
      return true;
    }
    return !isAvailable(postcode, selectedBundle, location.pathname);
  }, [postcode, selectedBundle]);

  const handleNext = () => {
    if (!disabled) {
      updatePostcode(postcode);
      onNext();
    }
  };

  useEffect(() => {
    if (selectedPostcode) {
      setPostcode(selectedPostcode);
    }
  }, [selectedPostcode]);

  return (
    <>
      {!selectedPostcode && (
        <div className={styles.addressFooter}>
          <div className={styles.addressFooterContent}>
            <div className={styles.addressRow}>
              <img className={styles.addressRowIcon} src={alert} alt="alert-icon" />

              <div className={styles.addressRowTitle}>
                {postcode.length < 4
                  ? LABELS.CHECK_PRODUCT
                  : disabled
                  ? `${LABELS.SORRY} (${postcode})`
                  : LABELS.AVAILABLE}
              </div>
              <PostcodeSearch
                footer
                location={postcode}
                onLocationChange={(postcode: string) => setPostcode(postcode)}
              />
            </div>
          </div>
        </div>
      )}
      <div className={`${styles.footerContainer} ${selectedPostcode && styles.boxShadow}`}>
        <div className={styles.footerContentWrapper}>
          {!!price ? (
            <div className={styles.footerPriceContainer}>
              <div className={styles.priceLabel}>{LABELS.PRICE_FROM}</div>
              <div className={styles.priceWrapper}>
                <div className={styles.footerPrice}>
                  ${price.toFixed(2)}
                  <span className={styles.priceGST}> (exc GST)</span>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.backButton} onClick={() => history.goBack()}>
              {LABELS.BACK}
            </div>
          )}
          <div
            className={disabled ? styles.disabledButton : styles.addressButton}
            onClick={() => handleNext()}
          >
            {onNextText}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedBundle: state.bundlesState.selectedBundle,
  selectedPostcode: state.bookingState.selectedPostcode,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePostcode: (data: string) => dispatch(BookingActions.updatePostcode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressCheckFooter));
