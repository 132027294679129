import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header/Header';
import { useStyles } from './JobDetailsStyles';
import SummaryCard from '../../components/summaryCard/SummaryCard';
import Footer from '../../components/footer/Footer';
import {
  LABELS,
  LOCATION_OPTIONS,
  QUOTE_TYPES,
  solarValidationSchema,
  validationSchema,
} from './JobDetailsConstants';
import { routes } from '../../Routes';
import SelectComponent from '../../components/select/SelectComponent';
import ImageUpload from '../../components/imageUpload/ImageUpload';
import { BookingActions } from '../../store/actions/BookingActions';
import Stepper from '../../components/stepper/Stepper';
import { OfferType } from '../../models/common/OfferType';
import { JobDetails } from '../../models/common/JobDetails';
import { getArrowText, getNextStep } from '../../helpers/StepHelper';
import { Step, StepType } from '../../models/common/Step';
import Button from '../../components/button/Button';
import { useFormik } from 'formik';

interface JobDetailsProps extends RouteComponentProps {
  offerType: OfferType;
  steps: Step[];
  updateJobDetails: (data: JobDetails) => void;
  updatePhotos: (data: File[]) => void;
}

const JobDetailsComponent: FC<JobDetailsProps> = ({
  history,
  steps,
  offerType,
  updateJobDetails,
  updatePhotos,
}) => {
  const formik = useFormik({
    initialValues: {
      location: '',
      attachments: [],
      title: '',
      description: '',
      quoteType: '',
      mostImportant: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      updateJobDetails({
        title: values.title,
        description: values.description,
        location: values.location,
        quoteType: values.quoteType,
        mostImportant: values.mostImportant,
      });
      updatePhotos(values.attachments);
      history.push(getNextStep(steps, StepType.JOB_DETAILS).route);
    },
    validationSchema:
      offerType === OfferType.ELECTRICIAN_FLOW_1 ? validationSchema : solarValidationSchema,
  });
  const styles = useStyles();
  return (
    <div>
      <Header
        expanded
        arrowText={getArrowText(
          steps,
          offerType === OfferType.ELECTRICIAN_FLOW_1
            ? StepType.JOB_DETAILS
            : StepType.QUOTE_DETAILS,
        )}
        expandedTitle={
          offerType === OfferType.ELECTRICIAN_FLOW_1 ? LABELS.HEADER_TITLE : LABELS.HEADER_TITLE2
        }
      />
      <Stepper
        activeStep={
          offerType === OfferType.ELECTRICIAN_FLOW_1 ? StepType.JOB_DETAILS : StepType.QUOTE_DETAILS
        }
      />

      <div className={styles.container}>
        <div className={styles.leftCard}>
          <div className={styles.heading}>
            {offerType === OfferType.SOLAR_FLOW_1 ? LABELS.HEADING_QUOTE : LABELS.HEADING}
          </div>
          {offerType === OfferType.SOLAR_FLOW_1 && (
            <>
              <div className={styles.inputContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleStyle}>{LABELS.QUOTE_TYPE_TITLE}</div>
                  <div className={styles.mandatory}>{'*'}</div>
                </div>
                <SelectComponent
                  value={formik.values.quoteType}
                  setValue={formik.handleChange('quoteType')}
                  options={QUOTE_TYPES}
                  error={formik.errors.quoteType}
                />
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleStyle}>{LABELS.QUOTE_OPTION_TITLE}</div>
                  <div className={styles.mandatory}>{'*'}</div>
                </div>
                <div className={styles.buttonRow}>
                  <Button
                    handlePress={() =>
                      formik.setFieldValue('mostImportant', LABELS.ENERGY_SAVING_TITLE)
                    }
                    secondary={formik.values.mostImportant !== LABELS.ENERGY_SAVING_TITLE}
                  >
                    {LABELS.ENERGY_SAVING_TITLE}
                  </Button>
                  <div className={styles.rightButton}>
                    <Button
                      handlePress={() =>
                        formik.setFieldValue('mostImportant', LABELS.PAYBACK_PERIOD_TITLE)
                      }
                      secondary={formik.values.mostImportant !== LABELS.PAYBACK_PERIOD_TITLE}
                    >
                      {LABELS.PAYBACK_PERIOD_TITLE}
                    </Button>
                  </div>
                </div>
                {formik.touched.mostImportant && formik.errors.mostImportant && (
                  <div className={styles.errorTextStyle}>{formik.errors.mostImportant}</div>
                )}
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleStyle}>{LABELS.PHOTOS}</div>
                  <div className={styles.mandatory}>{'*'}</div>
                </div>
                <ImageUpload
                  title=""
                  files={formik.values.attachments}
                  setFiles={(files: File[]) => formik.setFieldValue('attachments', files)}
                  subtitle={LABELS.PHOTO_BILL_UPLOAD_SUBTITLE}
                  errorMessage={
                    formik.touched.attachments && formik.errors.attachments
                      ? (formik.errors.attachments as string)
                      : ''
                  }
                  key="attachments_solar"
                  acceptType="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  onBlur={() => formik.setFieldTouched('attachments')}
                  touched={!!formik.touched.attachments}
                />
              </div>
            </>
          )}
          {offerType === OfferType.ELECTRICIAN_FLOW_1 && (
            <>
              <div className={styles.inputContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleStyle}>{LABELS.LOCATION}</div>
                  <div className={styles.mandatory}>{'*'}</div>
                </div>
                <SelectComponent
                  value={formik.values.location}
                  setValue={formik.handleChange('location')}
                  options={LOCATION_OPTIONS}
                  error={formik.errors.location}
                />
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleStyle}>{LABELS.TITLE}</div>
                  <div className={styles.mandatory}>{'*'}</div>
                </div>
                <textarea
                  className={`${styles.inputStyle} ${
                    formik.touched.title && formik.errors.title ? styles.inputErrorInput : undefined
                  }`}
                  rows={5}
                  cols={15}
                  placeholder="EG: Leaking tap"
                  value={formik.values.title}
                  onChange={(e) => formik.setFieldValue('title', e.target.value)}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className={styles.errorTextStyle}>{formik.errors.title}</div>
                )}
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleStyle}>{LABELS.DESCRIPTION}</div>
                  <div className={styles.mandatory}>{'*'}</div>
                </div>
                <textarea
                  className={`${styles.inputStyle} ${
                    formik.touched.description && formik.errors.description
                      ? styles.inputErrorInput
                      : undefined
                  }`}
                  rows={5}
                  cols={15}
                  placeholder="Briefly describe the problem, where it is on the premises and any other relevant information"
                  value={formik.values.description}
                  onChange={(e) => formik.setFieldValue('description', e.target.value)}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className={styles.errorTextStyle}>{formik.errors.description}</div>
                )}
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleStyle}>{LABELS.PHOTOS}</div>
                  <div className={styles.mandatory}>{'*'}</div>
                </div>
                <ImageUpload
                  title=""
                  files={formik.values.attachments}
                  setFiles={(files: File[]) => formik.setFieldValue('attachments', files)}
                  subtitle={LABELS.PHOTO_UPLOAD_SUBTITLE}
                  errorMessage={
                    formik.touched.attachments && formik.errors.attachments
                      ? (formik.errors.attachments as string)
                      : ''
                  }
                  key="attachments_electricity"
                  onBlur={() => formik.setFieldTouched('attachments')}
                  touched={!!formik.touched.attachments}
                />
              </div>
            </>
          )}
        </div>
        <SummaryCard />
      </div>

      <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => history.goBack()}
        primaryText={LABELS.NEXT}
        primaryAction={() => {
          formik.handleSubmit();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  steps: state.bookingState.steps,
  offerType: state.bookingState.offerType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateJobDetails: (data: JobDetails) => dispatch(BookingActions.updateJobDetails(data)),
  updatePhotos: (data: File[]) => dispatch(BookingActions.updatePhotos(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobDetailsComponent));
