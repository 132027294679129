import { OfferType } from '../models/common/OfferType';
import {
  jobDetails,
  userDetails,
  propertyDetails,
  tradeDetails,
  confirm,
  Step,
  StepType,
  quoteDetails,
} from '../models/common/Step';
import { store } from '../store/Store';

export const getSteps = (offerType: OfferType) => {
  const { token } = store.getState();
  const { isExternal } = token;
  if (isExternal) {
    switch (offerType) {
      case OfferType.BUNDLE:
        return [userDetails, propertyDetails, tradeDetails, confirm];
      case OfferType.ELECTRICIAN_FLOW_1:
        return [jobDetails, userDetails, propertyDetails, tradeDetails, confirm];
      case OfferType.SOLAR_FLOW_1:
        return [jobDetails, userDetails, propertyDetails, confirm];
      default:
        return [userDetails, propertyDetails, tradeDetails, confirm];
    }
  } else {
    switch (offerType) {
      case OfferType.BUNDLE:
        return [tradeDetails, confirm];
      case OfferType.ELECTRICIAN_FLOW_1:
        return [jobDetails, tradeDetails, confirm];
      case OfferType.SOLAR_FLOW_1:
        return [jobDetails, confirm];
      default:
        return [userDetails, propertyDetails, tradeDetails, confirm];
    }
  }
};

export const getCurrentStep = (steps: Step[], activeStep: StepType): Step => {
  const index = steps.findIndex((s) => s.stepType === activeStep);
  return steps[index];
};

export const getArrowText = (steps: Step[], activeStep: StepType) => {
  const index = steps.findIndex((s) => s.stepType === activeStep);

  return `Step ${index + 1} of ${steps.length}`;
};

export const getNextStep = (steps: Step[], activeStep: StepType): Step => {
  const index = steps.findIndex((s) => s.stepType === activeStep);
  return steps[index + 1];
};
