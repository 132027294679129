import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './FilterBarStyles';
import { FilterItem } from './FilterBarConstants';

interface FilterBarProps {
  filters: FilterItem[];
  // allows selecting multiple options
  togglable?: boolean;
  // allows only one option at a time but also allows deselcting the only option selected
  singleToggleable?: boolean;
  handlePress: (filters: FilterItem[], index: number) => void;
}

const FilterBar: FC<FilterBarProps> = ({ filters, togglable, handlePress, singleToggleable }) => {
  const styles = useStyles();

  const handleFilterPress = (index: number) => {
    const result = filters.map((filter, idx) => {
      if (idx === index) {
        return togglable || singleToggleable
          ? { ...filter, active: !filter.active }
          : { ...filter, active: true };
      } else {
        return togglable ? { ...filter } : { ...filter, active: false };
      }
    });
    handlePress(result, index);
  };

  return (
    <div className={styles.filterBarContainer}>
      {filters.map((filter, index) => (
        <div
          className={`${styles.filterContainer} ${filter.active && styles.activeFilter}`}
          key={index}
          onClick={() => handleFilterPress(index)}
        >
          {filter.value}
        </div>
      ))}
    </div>
  );
};

export default FilterBar;
