import React, { FC } from 'react';
import { useStyles } from '../BundleDetailsStyles';

interface LoadingInclusionsProps {}

const LoadingInclusions: FC<LoadingInclusionsProps> = ({}) => {
  const styles = useStyles();
  return (
    <div className={styles.inclusionsContainer}>
      <div className={styles.loadingSubheading} style={{ width: 200 }} />
      <div className={styles.inclusionsRow}>
        {[...Array(5).fill(null)].map(() => (
          <div className={styles.loadingInclusionContainer}>
            <div className={styles.loadingInclusionText} />
            <div className={styles.loadingInclusionText2} />
            <div className={styles.loadingInclusionButton} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadingInclusions;
