export enum ContentType {
  jpeg,
  png,
  plainText,
  json,
  pdf,
  bmp,
  tiff,
  html,
}

interface ContentRef {
  readonly contentType: ContentType;
  readonly name: string;
}

interface Content {
  readonly contentRef: ContentRef;
  readonly size: number;
  readonly data: Blob | string | null;
  readonly dataBase64?: string | undefined | null; // sometimes we pre-calculate this to optimize rendering (to avoid async render)
}

export class MultipartFormData {
  public readonly name: string;
  public readonly content: Content | string;
}
