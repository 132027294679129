import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './AddAddressStyles';
import Header from '../../components/header/Header';
import { LABELS, validationSchema } from './AddAddressConstants';
import Footer from '../../components/footer/Footer';
import { useFormik } from 'formik';
import AddDetailsInput from '../addDetails/components/AddDetailsInput';
import { Property } from '../../models/common/Property';
import { BookingActions } from '../../store/actions/BookingActions';
import { routes } from '../../Routes';

interface AddressFormProps {
  closePopup: (value: boolean) => void;
  address: Property | null;
  updateSelectedProperty: (data: Property) => void;
  goNext: () => void;
}

const AddressForm: FC<AddressFormProps> = ({
  closePopup,
  address,
  updateSelectedProperty,
  goNext,
}) => {
  const styles = useStyles();
  const initialValues = address
    ? {
        country: address.country || '',
        postcode: address.postcode || '',
        state: address.state || '',
        streetName: address.streetName || '',
        streetNumber: address.streetNumber || '',
        suburb: address.suburb || '',
        unitNumber: address.unitNumber || '',
      }
    : {
        country: 'Australia',
        postcode: '',
        state: '',
        streetName: '',
        streetNumber: '',
        suburb: '',
        unitNumber: '',
      };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      updateSelectedProperty({
        address: '',
        ...values,
      });
      goNext();
    },
    validationSchema: validationSchema,
  });
  return (
    <div>
      <Header
        expanded
        expandedTitle={LABELS.UPDATE_ADDRESS}
        overrideBack={() => closePopup(false)}
      />
      <div className={styles.container}>
        <div className={styles.leftCard}>
          <div className={styles.heading}>{LABELS.YOUR_ADDRESS}</div>
          <div className={styles.addressContainer}>
            <div className={styles.inputCard}>
              <AddDetailsInput
                placeholder={LABELS.UNIT_NUMBER}
                key={LABELS.UNIT_NUMBER}
                value={formik.values.unitNumber}
                setValue={formik.handleChange('unitNumber')}
                onBlur={() => formik.setFieldTouched('unitNumber')}
                touched={formik.touched.unitNumber}
                error={formik.errors.unitNumber}
                title={LABELS.UNIT_NUMBER}
              />
            </div>
            <div className={styles.inputCard}>
              <AddDetailsInput
                placeholder={LABELS.STREET_NUMBER}
                key={LABELS.STREET_NUMBER}
                value={formik.values.streetNumber}
                setValue={formik.handleChange('streetNumber')}
                onBlur={() => formik.setFieldTouched('streetNumber')}
                touched={formik.touched.streetNumber}
                error={formik.errors.streetNumber}
                title={LABELS.STREET_NUMBER}
                mandatory
              />
            </div>
            <div className={styles.inputCard}>
              <AddDetailsInput
                placeholder={LABELS.STREET_NAME}
                key={LABELS.STREET_NAME}
                value={formik.values.streetName}
                setValue={formik.handleChange('streetName')}
                onBlur={() => formik.setFieldTouched('streetName')}
                touched={formik.touched.streetName}
                error={formik.errors.streetName}
                title={LABELS.STREET_NAME}
                mandatory
              />
            </div>
            <div className={styles.inputCard}>
              <AddDetailsInput
                placeholder={LABELS.SUBURB}
                key={LABELS.SUBURB}
                value={formik.values.suburb}
                setValue={formik.handleChange('suburb')}
                onBlur={() => formik.setFieldTouched('suburb')}
                touched={formik.touched.suburb}
                error={formik.errors.suburb}
                title={LABELS.SUBURB}
                mandatory
              />
            </div>
            <div className={styles.inputCard}>
              <AddDetailsInput
                placeholder={LABELS.STATE}
                key={LABELS.STATE}
                value={formik.values.state}
                setValue={formik.handleChange('state')}
                onBlur={() => formik.setFieldTouched('state')}
                touched={formik.touched.state}
                error={formik.errors.state}
                title={LABELS.STATE}
                mandatory
              />
            </div>
            <div className={styles.inputCard}>
              <AddDetailsInput
                placeholder={LABELS.POST_CODE}
                key={LABELS.POST_CODE}
                value={formik.values.postcode}
                setValue={formik.handleChange('postcode')}
                onBlur={() => formik.setFieldTouched('postcode')}
                touched={formik.touched.postcode}
                error={formik.errors.postcode}
                title={LABELS.POST_CODE}
                mandatory
              />
            </div>
            <div className={styles.inputCard}>
              <AddDetailsInput
                placeholder={LABELS.COUNTRY}
                key={LABELS.COUNTRY}
                value={formik.values.country}
                setValue={formik.handleChange('country')}
                onBlur={() => formik.setFieldTouched('country')}
                touched={formik.touched.country}
                error={formik.errors.country}
                title={LABELS.COUNTRY}
                mandatory
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <Footer
        secondaryText={LABELS.BACK}
        secondaryAction={() => closePopup(false)}
        primaryText={LABELS.NEXT}
        primaryAction={() => {
          formik.handleSubmit();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelectedProperty: (data: Property) => dispatch(BookingActions.updateSelectedProperty(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
