import React, { FC } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducer';
import { useStyles } from './SummaryCardStyles';
import { Dispatch } from 'redux';
import { Property } from '../../models/common/Property';
import { BundlePackage } from '../../models/bundles/Bundles';
import { LABELS } from './SummaryCardConstants';
import { TradeResponse } from '../../models/tradeResponse/TradeResponse';
import { OfferType } from '../../models/common/OfferType';
import middysLogo from '../../assets/logos/middys.png';

interface SummaryCardProps {
  offerType: OfferType;
  isConfirmDetails?: boolean;
  selectedProperty: Property | undefined;
  selectedBundle: BundlePackage | undefined;
  preferredTime: string;
}

const SummaryCard: FC<SummaryCardProps> = ({
  offerType,
  isConfirmDetails,
  selectedProperty,
  selectedBundle,
  preferredTime,
}) => {
  const styles = useStyles();
  let address1 = ``;
  let address2 = ``;

  if (selectedProperty) {
    const { unitNumber, streetNumber, streetName, state, suburb, postcode } = selectedProperty;
    address1 = `${unitNumber ? `${unitNumber}/` : ''}${streetNumber || ''} ${streetName}`;
    address2 = `${suburb}, ${state} ${postcode}`;
  }

  const getOfferString = () => {
    switch (offerType) {
      case OfferType.BUNDLE:
        return `${selectedBundle!.standard.title} ${
          selectedBundle!.selectedUpgraded ? '(upgraded)' : ''
        }`;
      case OfferType.SOLAR_FLOW_1:
        return LABELS.SOLAR_QUOTE;
      case OfferType.ELECTRICIAN_FLOW_1:
        return LABELS.ELECTRICIAN;
      default:
        return '';
    }
  };

  return (
    <div className={isConfirmDetails ? styles.confirmDetailsCard : styles.card}>
      <div className={styles.sectionStyle}>
        <div className={styles.sectionRow}>
          <div className={styles.sectionTitle}>
            {offerType !== OfferType.BUNDLE ? LABELS.QUOTE : LABELS.PACKAGE}
          </div>
          <div className={styles.sectionCommonText}>{getOfferString()}</div>
        </div>
        <div className={styles.sectionRow}>
          <div className={styles.sectionTitle}>{LABELS.PROPERTY}</div>

          {selectedProperty ? (
            <div className={styles.sectionCommonText}>
              {address1} {address2}
            </div>
          ) : (
            <div className={styles.sectionCommonText}>{LABELS.NO_DETAILS}</div>
          )}
        </div>
      </div>
      {preferredTime && offerType !== OfferType.SOLAR_FLOW_1 && (
        <div className={styles.sectionStyle}>
          {/* {selectedTrade && (
            <div className={styles.sectionRow}>
              <div className={styles.sectionTitle}>{LABELS.TRADE}</div>
              {selectedTrade.length > 0 ? (
                <>
                  {selectedTrade.map((trade) => (
                    <div className={styles.sectionCommonText}>
                      {trade.trade.name} | {trade.email}
                    </div>
                  ))}
                </>
              ) : (
                <div className={styles.sectionCommonText}>{LABELS.NO_DETAILS}</div>
              )}
            </div>
          )} */}
          <div className={styles.sectionRow}>
            <div className={styles.sectionTitle}>{LABELS.TIME}</div>
            {preferredTime ? (
              <div className={styles.sectionCommonText}>{preferredTime}</div>
            ) : (
              <div className={styles.sectionCommonText}>{LABELS.NO_DETAILS}</div>
            )}
          </div>
        </div>
      )}
      {offerType === OfferType.BUNDLE && (
        <div className={styles.priceSection}>
          <div className={styles.totalText}>{LABELS.TOTAL}</div>
          {selectedBundle && selectedBundle.standard && (
            <div className={styles.priceValue}>{`$${
              selectedBundle.selectedUpgraded
                ? selectedBundle.upgraded!.price
                : selectedBundle.standard.price
            }.00`}</div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  offerType: state.bookingState.offerType,
  selectedProperty: state.bookingState.selectedProperty,
  selectedBundle: state.bundlesState.selectedBundle,
  preferredTime: state.bookingState.preferredTime,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryCard);
