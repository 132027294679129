import React, { FC } from 'react';
import { useStyles } from './StepperStyles';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/RootReducer';
import { Step, StepType } from '../../models/common/Step';

interface StepperProps {
  activeStep: StepType;
}

const Stepper: FC<StepperProps> = ({ activeStep }) => {
  const steps = useSelector<ApplicationState, Step[]>(
    (state) => state.bookingState.steps as Step[],
  );
  const styles = useStyles();
  return (
    <div className={styles.stepperContainer}>
      <div className={styles.stepperWrapper}>
        {steps.map((step, index) => (
          <div className={styles.step} key={index}>
            <div
              className={activeStep === step.stepType ? styles.stepTitleActive : styles.stepTitle}
            >
              {step.stepType}
            </div>
            <div className={activeStep === step.stepType ? styles.stepBarActive : styles.stepBar} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
