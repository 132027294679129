import logo from '../../assets/logos/middys-updated.png';
import logoCircle from '../../assets/logosCircle/middys.png';
import hero from '../../assets/hero/middys2.jpg';

const MIDDYS = [
  {
    key: 'MIDDYS',
    logo,
    logoCircle,
    hero,
    colors: {
      light: '#FFF',
      lightFaded: 'rgba(255,255,255,0.7)',
      grey: '#9b9b9b',
      lightGrey: '#fafafa',
      darkGrey: '#464343',
      black38: 'rgba(0, 0, 0, 0.38)',
      black: '#000',
      label: '#c1bed0',
      black87: 'rgba(0,0,0,0.87)',
      primary: '#0055a5',
      secondary: '#ec008c',
      borderColor: '#eeeeee',
      disabled: '#f6f6f6',
      filterBackground: '#f6f6f6',
      disabledText: '#808080',
      buttonPrimary: '#ec008c',
      black60: 'rgba(0, 0, 0, 0.6)',
      offerOverlay: '#242733',
      inputBackground: '#F4F4F4',
      inputText: '#666666',
      error: '#E03A3E',
      navyBlue: '#24347B',
      textSecondary: '#828282',
    },
    font: {
      family: 'Open Sans',
      family2: 'Futura PT',
      xxs: 10,
      xs: 12,
      s: 14,
      m: 16,
      l: 18,
      xl: 24,
      xxl: 34,
      xxxl: 44,
      buttonCase: 'capitalize',
    },
    units: {
      padding: 4,
      borderRadius: 0,
      margin: 4,
      iconHeight: 24,
      iconWidth: 24,
    },
  },
];

export default MIDDYS;
