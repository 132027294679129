export const LABELS = {
  FOLLOW: 'Follow @middyselectrical on:',
  CC: '© 2022 Middendorp Electric Co PTY LTD. ABN 49 338 705 390',
  INTERESTED_TITLE: 'Interested in joining our network?',
  INTERESTED_TEXT: 'You can view details about our MyHome Services program',
  FACEBOOK_LINK: 'https://www.facebook.com/MiddysElectrical',
  INSTAGRAM_LINK: 'https://www.instagram.com/Middyselectrical',
  LINKEDIN: 'https://www.linkedin.com/company/middyselectrical',
  REGISTER_INTEREST_BANNER_TEXT:
    'Want to know when Middy’s Home Services are available in your area?',
  REGISTER_INTEREST: 'Register interest',
};
