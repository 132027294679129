import React, { FC } from 'react';
import { useStyles } from './TradeCardStyles';
import accountIcon from '../../../../assets/middys-electrician.png';
import addIcon from '../../../../assets/add.png';
import { Rating } from '@material-ui/lab';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { theme } from '../../../../theme/Theme';
import { TradeResponse } from '../../../../models/tradeResponse/TradeResponse';
import Directions from '@material-ui/icons/Directions';

interface TradeCardProps {
  trade: TradeResponse;
  selected: boolean;
}

const TradeCard: FC<TradeCardProps> = ({ trade, selected }) => {
  const styles = useStyles();
  return (
    <div
      className={classNameGenerator([
        styles.cardWrapper,
        selected ? styles.selectedCardWrapper : '',
      ])}
    >
      <div className={styles.detailContainer}>
        <img src={trade.logo || accountIcon} className={styles.iconStyle} alt="icon" />
        <div className={styles.detailsStyle}>
          <div className={styles.nameText}>{trade.trade.name}</div>
          <div className={styles.emailText}>{trade.email}</div>
          <div className={styles.verifiedText}>
            <Directions fontSize="small" />
            {(trade.distanceFromOrigin / 1000).toFixed(1)} kms away
          </div>
        </div>
      </div>
      <div className={styles.ratingSection}>
        <div className={styles.ratingText}> {trade.rating.toFixed(1)}</div>
        <div className={styles.ratingStyle}>
          <Rating
            name="rating"
            value={trade.rating}
            size="small"
            className={styles.ratingColor}
            disabled
          />
        </div>
        {selected ? (
          <CheckCircleIcon htmlColor={theme.colors.secondary} className={styles.tickIcon} />
        ) : (
          <img src={addIcon} className={styles.addIconStyle} alt="plus" />
        )}
      </div>
    </div>
  );
};

export default TradeCard;
