import * as Yup from 'yup';
import { titleCaseLabels } from '../../helpers/helperFunctions';

export const LABELS = titleCaseLabels({
  HEADING: 'Search for your address',
  HEADER_ARROW_TEXT: 'Step 2 of 4',
  HEADER_TITLE: 'Add your address',
  HEADER_SUBTITLE: 'Step 1 of 4',
  BACK: 'Back',
  NEXT: 'Next',
  UPDATE_ADDRESS: 'Update your address',
  YOUR_ADDRESS: 'Your address',
  UNIT_NUMBER: 'Unit number',
  SUBURB: 'Suburb',
  STREET_TYPE: 'Street type',
  STREET_NUMBER: 'Street number',
  STREET_NAME: 'Street name',
  STATE: 'State',
  POST_CODE: 'Postcode',
  COUNTRY: 'Country',
  ADDRESS: 'Address',
});

export const ErrorMessages = {
  REQUIRED: 'Required field',
};

export const validationSchema = Yup.object().shape({
  country: Yup.string().required(ErrorMessages.REQUIRED),
  postcode: Yup.string().required(ErrorMessages.REQUIRED),
  state: Yup.string().required(ErrorMessages.REQUIRED),
  streetName: Yup.string().required(ErrorMessages.REQUIRED),
  streetNumber: Yup.string().required(ErrorMessages.REQUIRED),
  suburb: Yup.string().required(ErrorMessages.REQUIRED),
  unitNumber: Yup.string(),
});
