import { Box, BoxProps } from '@material-ui/core';
import React, { FC } from 'react';
import { theme } from '../../theme/Theme';

interface ListBoxProps extends BoxProps {
  spacing: number;
  direction?: 'row' | 'column';
}

export const ListBox: FC<ListBoxProps> = ({ children, spacing, direction, ...rest }) => {
  return (
    <Box
      display="flex"
      flexDirection={direction || 'column'}
      gridGap={theme.units.margin * spacing}
      {...rest}
    >
      {children}
    </Box>
  );
};
