import React, { FC } from 'react';
import { useStyles } from './CrumbNavStyles';
import { useHistory } from 'react-router';
import { routes } from '../../Routes';
import homeIcon from '../../assets/home.png';
import MiddysLink from '../link/MiddysLink';

export interface CrumbNavStep {
  label: string;
  path?: string;
}

interface CrumbNavProps {
  steps: CrumbNavStep[];
}

const CrumbNav: FC<CrumbNavProps> = ({ steps }) => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <div className={styles.navigationContainer}>
      <img
        src={homeIcon}
        onClick={() => history.push(routes.home)}
        className={styles.homeIcon}
        alt="home icon"
      />
      {steps.map(({ label, path }) => (
        <>
          <div className={styles.navArrow}>{`>`}</div>
          {path ? (
            <MiddysLink to={path}>
              <div className={styles.navTitle}>{label}</div>
            </MiddysLink>
          ) : (
            <div className={styles.navTitle}>{label}</div>
          )}
        </>
      ))}
    </div>
  );
};

export default CrumbNav;
