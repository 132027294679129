import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './PostcodeSearchStyles';
import pinIcon from '../../../../assets/pin.png';

interface PostcodeSearchProps {
  footer?: boolean;
  location: string;
  onLocationChange: (location: string) => void;
}

const PostcodeSearch: FC<PostcodeSearchProps> = ({ footer, location, onLocationChange }) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const styles = useStyles();
  return (
    <div className={`${styles.postcodeContainer} ${isFocused && styles.border}`}>
      <input
        type="number"
        className={styles.location}
        placeholder="Postcode"
        style={footer ? { width: 88 } : {}}
        onChange={(event) => onLocationChange(event.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={location}
      />
      <img src={pinIcon} className={styles.icon} alt="location" />
    </div>
  );
};

export default PostcodeSearch;
