export const LABELS = {
  HERO_TITLE: 'Get a Free Solar and Battery Proposal',
  HERO_TEXT:
    "We'll organize proposals outlining investment, rebates available and payback periods for you from up to 3 EnergyTrust contractors that support your area.",
  GET_QUOTE: 'Get a Free Quote',
  TITLE: 'EnergyTrust, saving you more',
  TEXT: "EnergyTrust ® developed by Middy's is a best in class multi-technology energy efficiency proposal software tool. A unique software program that generates energy project proposals specific to your individual needs and requirements. Start saving and embark on an energy efficiency journey with a trusted Middy's EnergyTrust contractor today.",
  HOW_IT_WORKS: 'Get a Solar Proposal in 4 Easy Steps',
  CHECK_PRODUCT: 'Check that this product is available in your area',
  ADD_ADDRESS2: 'Add address',
  BACK: 'Back',
  MANAGE_EVERYTHING_TITLE: "Why choose Middy's EnergyTrust",
};

export const STEPS = [
  {
    title: 'Give Us Some Additional Details',
    text: 'Simply complete the solar quote form and provide us with copy of your latest electricity bill. We use the personal information we collect about you to provide services to you.',
  },
  {
    title: 'EnergyTrust gets to work',
    text: 'Your data will then be inputted into our software which will provide snapshots of options available and estimated return on investment',
  },
  {
    title: 'Receive Energy Project Proposals',
    text: 'You will then receive a detailed proposal providing all necessary information to make an informed decision from 3 most suitable, accredited solar installers in your area',
  },
  {
    title: 'Get the job done',
    text: 'Select your preferred installer and enjoy reduced energy costs and bills',
  },
];

export const LIST = [
  'Combat rising power prices, repair poor quality power and eliminate additional charges',
  'Tailored solutions specifically designed to meet your requirements and budget',
  'Competitive finance available',
  'Payback, bill reduction, energy savings, and return on investment',
  'No obligation, 100% free service',
];
